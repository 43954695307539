import React, { useEffect, useState } from "react";
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { updateScriptDoc } from "apis/robodialer";
import { Script } from "types/types";


// custom styles
const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,400px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  refresh: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScript: string | null;
  scriptName: string | null;
  setSelectedScript: React.Dispatch<React.SetStateAction<string | null>>;
  closeModal: () => void;

  
  
}

interface FormValues {
  name: string;
}
// components
export default function RenameScriptModal({ modalIsOpen, setModalIsOpen, setSelectedScript, selectedScript, scriptName, closeModal, refresh }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  // const [scriptData, setScriptData] = useState<Script | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    reset(
      {name: scriptName? scriptName:''}
    )
  }, [scriptName]);


  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedScript(null);
  };

  const handleReset = () => {
    // Reset the form
    reset()
    closeModal()
    setModalIsOpen(false)
  };



  const onSubmit = async (data: FormValues) => {
    //setLoading(true)
    const { name } = data;
    let response = await updateScriptDoc(selectedScript, {
      name
    });
    setLoading(false)

    let error: string | null = null;
    if (response.httpCode !== 200) {
      error = "Oops! Something went wrong. If the problem persist please reach out to support@thesuperbill.com."
    }
    if (error) {
      setSubmitError(error)
      return;
    }
    toggleModal()
    refresh()
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Rename script
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full bg-white rounded">
              <div className=' mt-1 w-full'>
                <label htmlFor="name" className="block text-superbill-jacarta font-bold text-sm mb-2">
                  Script Name
                </label>
                <input
                  {...register('name', {
                    required: 'This field is required',
                  })}
                  id="name"
                  type="name"
                  className={`${errors.name
                    ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                    : 'border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo'
                    } py-2 px-3 bg-white border block w-full sm:text-sm rounded placeholder:text-sm text-superbill-jacarta`}
                />
                {errors.name && (
                  <span className="text-red-500 text-sm font-semibold mt-2 block">
                    {errors.name.message}
                  </span>
                )}
              </div>
              {submitError && (
                <span className="text-red-500 text-sm font-semibold mt-2 block">
                  {submitError}
                </span>
              )}
            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { handleReset() }}
            >
              Close
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || !isValid }
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              {isLoading ? <Spinner /> : <>Rename</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
