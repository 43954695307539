import { DateTime } from 'luxon';
import React, { Fragment, useState} from 'react'
import { Tooltip } from '@material-tailwind/react';
//import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import { ReactComponent as SparklesIcon } from 'assets/sparkles2.svg';
import { ReactComponent as OpenIcon } from 'assets/open.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as RunIcon } from 'assets/run.svg';
import { ReactComponent as RenameIcon } from 'assets/rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ViewInputsIcon } from 'assets/view-inputs.svg';
import { ReactComponent as DownloadInputsIcon } from 'assets/download.svg';
import { ReactComponent as DefineInputsIcon } from 'assets/define-inputs.svg';



type ScriptCardProps = {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onViewInputs: (id: string) => void;
  onDefineInputs: null | ( (id: string) => void);
  onDownloadInputs: (id: string) => void;
  onRename: (id: string) => void;
  onRun: (id: string) => void;
  onDuplicate: (id: string) => void;
  name: string;
  createdAt: string;
  pmsCard: boolean;
  id: string;
  questionsCount?: number;
  status: boolean;
};

const onCopy = (id) => {
  navigator.clipboard.writeText(id);
  toast.success('Copied to clipboard', {
    iconTheme: {
      primary: '#4a43cd',
      secondary: '#ffffff',
    },
  }
  );
}

const ScriptCard = ({
  name,
  createdAt,
  id,
  questionsCount,
  onEdit,
  onDuplicate,
  onRun,
  onViewInputs,
  onDefineInputs,
  onDownloadInputs,
  pmsCard = false,
  onDelete,
  onRename,
  status
}: ScriptCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='flex'>
        <Button
          className='w-full !p-0 !normal-case'
          id={`basic-button-${new Date().getTime()}`}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <span className="sr-only">Open options</span>
          <div
            key={id}
            className="bg-white mt-4 rounded !border border-superbill-soap px-5 py-3 justify-center items-center hover:border-superbill-ultramarine w-full min-w-[325px] lg:w-[325px] grow ease-in-out duration-150"
          >
            <div className='flex justify-between items-center'>
              {name?.length >= 32 ?
                <Tooltip content={name} className='bg-black py-1 px-2'>
                  <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{name}</div>
                </Tooltip>
                :
                <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{name}</div>}
              {pmsCard ? 
              <div className='flex px-2 py-1 items-center bg-superbill-lavender rounded-full'>
                <SparklesIcon/>
                <div className='ml-1 text-superbill-indigo text-xs font-jakarta text-nowrap'>PMS Integration</div>
              </div>
              :null}
            </div>
            <div className='text-sm text-start text-superpay-light-text-gray'>ID: {id}</div>
            <div className='w-full flex justify-between items-center my-1'>
              <div className='text-superbill-jacarta text-sm'>
                <strong>{questionsCount?.toString()}</strong>{' '}
                Question{questionsCount === 1 ? '' : 's'}
              </div>
              <div className='text-superpay-light-text-gray text-sm font-sans'>{
                DateTime.fromJSDate(new Date(createdAt)).toFormat(
                  'MMM d'
                )}</div>
            </div>
          </div>
        </Button>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          <div className="py-1 border border-superbill-soap border-1 rounded">
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { 
                  onEdit(id);
                  handleClose();
                 }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>View/Edit Script</div>
              </div>
            </MenuItem>
            {0 < (questionsCount ?? 0) ?
              <MenuItem>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                  onClick={() => {
                    onRun(id);
                    handleClose();
                  }}
                >
                  <RunIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Run Script</div>
                  {status ? null :  <div className='text-xs text-superbill-indigo bg-superbill-lavender rounded px-2 py-1'>Live Customers</div>}
                </div>
              </MenuItem>
            :null}
            <div className='border-b border-superbill-soap'></div>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onViewInputs(id);
                  handleClose();
                 }}
              >
                <ViewInputsIcon className="w-[20px]" />
                <div className='mx-2 text-base'>View Inputs</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onDownloadInputs(id);
                  handleClose();
                 }}
              >
                <DownloadInputsIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Download Inputs Template</div>
              </div>
            </MenuItem> 
            {onDefineInputs ? 
              <MenuItem>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                  onClick={() => { onDefineInputs(id);
                    handleClose();
                   }}
                >
                  <DefineInputsIcon className="w-[20px]" />
                  <div className='mx-2 text-base'>Define Script-Level Inputs</div>
                </div>
              </MenuItem>
            :null}
            <div className='border-b border-superbill-soap'></div>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onCopy(id);
                  handleClose();
                 }}
              >
                <CopyIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Copy ID</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onRename(id);
                  handleClose();
                 }}
              >
                <RenameIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Rename Script</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => {
                  onDuplicate(id);
                  handleClose();
                }}
              >
                <CopyIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Duplicate Script</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onDelete(id);
                  handleClose();
                 }}
              >
                <DeleteIcon className="w-[20px]"/>
                <div className='mx-2 text-base'>Delete Script</div>
              </div>
            </MenuItem>
          </div>
      </Menu>
    </>
  );
};

export default ScriptCard;
