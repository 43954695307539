//import React, { lazy, Suspense, useEffect } from 'react';
import { useEffect } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import './css/shared.scss';

import MainLoading from './MainLoading';
import { useAuth } from './routes/authProvider';
import SuperDialRoutes from './routes/super-dial-routes';
import PublicRoutes from './routes/public-routes';
import AgentsRoutes from './routes/agents-routes';
import { SocketProvider } from 'routes/socketProvider';

//const MainLoading = lazy(() => import("./MainLoading"));
//const Claims = lazy(() => import("./sign-up/superpay/dashboard/pages/claims"));
//const Insurance = lazy(() => import('./sign-up/superpay/dashboard/pages/insurance'));


const AppRoutes = ({ state }) => {
  const { data, setData } = useAuth();


  useEffect(() => {
    if (state) {
      setData(state)
    }
  }, [state, setData]);
  
  useEffect(() => {
    if (state) {
      //console.log('effect setState 22');
      setData(state)
    }
  }, []);

    const routes = {
      'RobodialersTenant-hvzmy': <SuperDialRoutes />,
      'AgentsTenant-wf9gw': <SocketProvider><AgentsRoutes /></SocketProvider>,
    }
  return (
    <>
      {data?.authStatusDetermined ? (
        <>
          {/* <CommonRoutes /> */}
          {data?.user ? 
            <>
              {routes[data?.user?.tenantId] ? routes[data?.user?.tenantId] : <>Error</>}
            </>
          : 
            <PublicRoutes />
          }
        </>        
      ) :
        <Routes>
          <Route path='/*' element={<MainLoading />} />
        </Routes>
      }
    </>
  );
};

export default AppRoutes;