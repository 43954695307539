import React from "react";
import Modal from "react-modal";
import { ReactComponent as Spinner} from 'assets/button-loading.svg';

// custom styles
const customStyles = {
  content: {
    height: "fit-content",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface RetryCallsModalProps {
  isLoading: boolean;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  numCalls: number;
  retryCalls: () => void;
}

export default function RetryCallsModal({ isLoading, isModalOpen, setIsModalOpen, numCalls, retryCalls }: RetryCallsModalProps) {

  const message = `Re-try ${numCalls} call${numCalls > 1 ? 's' : ''}`

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            {message}
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <p>When you re-try calls, we will fetch the latest insurance information from your PMS to place the call.</p>
            
            <p>Calls that are currently in progress will not be retried.</p>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setIsModalOpen(false) }}
            >
              Close
            </button>
            <button
              onClick={retryCalls}
              disabled={isLoading}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
                {message}
                {isLoading && <Spinner />}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
