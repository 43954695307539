import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Loader from 'components/Loader';
import { useOutletContext } from 'react-router-dom';
import { WebhookDataType } from 'types/types';
import { updateRobodialer } from 'apis/robodialer';
import { toast } from 'react-hot-toast';

interface FormData {
  webhook_url: string;
}

interface ContextProps {
  webhookData: WebhookDataType
}


const WebhooksPage: React.FC = () => {
  const [props] = useOutletContext<Array<ContextProps>>();
    // const { apiKey, hasApiSecret, developerSandboxKey } = webhookData;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      webhook_url: props.webhookData?.callsWebhookUrl ? props.webhookData?.callsWebhookUrl : '',
    },
  });



  const onSubmit = async (data: FormData) => {
    if (data.webhook_url) {
      try {
        setIsLoading(true)
        await updateRobodialer({
          'calls-webhook-url': data.webhook_url
        });
        setIsLoading(false)
        toast.success('URL updated!', {
          iconTheme: {
            primary: '#4a43cd',
            secondary: '#ffffff',
          },
        })
      } catch (error) {
        setIsLoading(false)
      }
    }
  };


  return (
    <>
      <div className='text-superbill-jacarta font-jakarta font-semibold text-xl'>
            Webhooks
          </div>
      <div className='py-4 px-6 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex items-center">
            <label htmlFor="webhook_url" className="mr-2 text-superbill-jacarta text-base font-bold w-[160px]">
              Webhook URL
            </label>
            <Controller
              name="webhook_url"
              control={control}
              rules={{ required: 'Requiered' }}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base mr-4"
                  placeholder={'https://mywebhook.com'}
                />
              )}
            />
            <button
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
            >
              {isLoading ? <Loader size={28} /> : <>Update</>}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default WebhooksPage;