import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import Loader from 'components/Loader';
import { duplicateScript } from "../../apis/robodialer";
import { Script } from "types/types";

interface FormInputs {
  name: string;
}

interface DuplicateScriptModalProps {
  scriptData: Array<Script> | null;
  isOpen: boolean;
  onClose: () => void;
  selectedScriptId: string;
  onSuccess?: () => void;
}

const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,400px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export default function DuplicateScriptModal({
  isOpen,
  onClose,
  selectedScriptId,
  onSuccess,
  scriptData
}: DuplicateScriptModalProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  
  const defaultScriptName = React.useMemo(() => {
    const selectedScript = scriptData?.find((script) => script.id === selectedScriptId);
    return selectedScript ? `Copy of ${selectedScript.name}` : '';
  }, [scriptData, selectedScriptId]);

  const formKey = React.useMemo(() => `form-${selectedScriptId}`, [selectedScriptId]);
  
  const { control, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues: {
      name: defaultScriptName
    }
  });

  React.useEffect(() => {
    if (isOpen) {
      reset({ name: defaultScriptName });
    }
  }, [isOpen, defaultScriptName, reset]);
  
  const onSubmit = async (data: FormInputs) => {
    if (!data.name.trim()) return;
    
    setIsLoading(true);
    try {
      const response = await duplicateScript(selectedScriptId, data);
      
      if (response.httpCode === 200) {
        toast.success('Script successfully duplicated.', {
          iconTheme: {
            primary: '#4a43cd',
            secondary: '#ffffff',
          },
        });
        onSuccess?.();
        handleClose();
      } else {
        throw new Error('Duplication failed');
      }
    } catch (error) {
      toast.error('Unable to duplicate script', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = React.useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  if (!scriptData) return null;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Duplicate Script Modal"
      onRequestClose={handleClose}
    >
      <div className="flex flex-col h-full divide-y" key={formKey}>
        <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
          Duplicate a Script
        </nav>
        
        <div className="flex-grow overflow-y-auto p-9">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue={defaultScriptName}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full"
                  placeholder="Enter a name"
                />
              )}
            />
          </form>
        </div>

        <div className="flex flex-row justify-between px-4 py-3">
          <button
            type="button"
            className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="bg-superbill-ultramarine disabled:bg-blue-200 font-jakarta hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {isLoading ? <Loader size={28} /> : 'Duplicate'}
          </button>
        </div>
      </div>
    </Modal>
  );
}