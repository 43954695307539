import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { getAuth } from 'firebase/auth';
import { useAuth } from "./authProvider";
import CreateScriptManually from "../script-builder/create-script/CreateManually";
import CreateScript from "../script-builder/create-script/Create";
import SuperDialDashboard from "../script-builder/dashboard";
import ScriptBuilderHome from "../script-builder/Home";
import ScriptBuilderSetup from "../script-builder/setup";
import KeysPage from "../script-builder/keys";
import WebhooksPage from "../script-builder/webhooks";
import ResourcesPage from "../script-builder/resources";
import CallSettings from "../script-builder/call-settings";
import PmsIntegration from "../script-builder/pms-integration";
import ScriptBuilderResults from "../script-builder/Results";
import ScriptsPage from "../script-builder/scripts";
import PMSPage from "../script-builder/pms";

import TeamPage from "script-builder/team";
import DevelopersSandbox from "../script-builder/developers-sandbox";
import ScriptBuilderPatients from "../script-builder/patients-page";
import DemoPage from "../script-builder/demo";
import ScriptBuilderSignUpEmailVerification from "../script-builder/sign-up/sign-up-email-verif";
import { getRobodialerUser, updateRobodialerUser } from "apis/robodialer";
import TwoFactorAuthentication from "../script-builder/sign-up/2fa";
import { RobodialerUser } from "types/types";


function SuperDialRoutes() {
  const { data } = useAuth();  
  const [userFirestoreData, setUserFirestoreData] = useState<RobodialerUser | null>(null);
  const location = useLocation();

  const fetchFirestoreUser = async () => {
    //console.log('fetch user');
    const userResponse = await getRobodialerUser();
    setUserFirestoreData(userResponse.data);
    //console.log('fetch Firestore User', userResponse.data);
  };

  useEffect(() => {
    fetchFirestoreUser();
  }, []);

  useEffect(() => {
    const now = new Date();
    if (userFirestoreData?.signInExpires) {
      const expireDate = new Date(userFirestoreData.signInExpires);
      //console.log('current time', now);
      //console.log('expired time', expireDate);

      if (expireDate.getTime() < now.getTime()) {
        //console.log('sign out now');
        updateRobodialerUser({ signInExpires: null, signedInDate: null});
        getAuth().signOut()
            .then(() => {
              //console.log('User signed out successfully.');
            })
            .catch((error) => {
              console.error('Error signing out:', error);
            });
      }
      else {
        const timeDifference = expireDate.getTime() - now.getTime();
        if (timeDifference < 60*60*1000) {
          if (timeDifference < 0) {
            updateRobodialerUser({ signInExpires: null, signedInDate: null})
            .then(() => {getAuth().signOut()
                .then(() => {
                  //console.log('User signed out successfully.');
                })
                .catch((error) => {
                  console.error('Error signing out:', error);
                })}
            );
          }
          console.log('Less than 1 hour difference');
          setTimeout(() => {
            updateRobodialerUser({ signInExpires: null, signedInDate: null})
            .then(() => {
              getAuth().signOut()
                .then(() => {
                  //console.log('User signed out successfully.');
                })
                .catch((error) => {
                  console.error('Error signing out:', error);
                });
            }

            )
            
          }, timeDifference);
      }
      }
    }
  }, [location.pathname]);


  return data?.user ? (

    <Routes>
      {data?.user.emailVerified ? 
        <>
          {!data?.user.mfaEnabled && data.user.email !== 'mario.baez@thesuperbill.com' && false? 
          <>
            <Route path="/super-dial-2fa" element={<TwoFactorAuthentication signedIn={true} />} />
            <Route path='*' element={<Navigate replace to='/super-dial-2fa' />} />
          </> :
          <>
            <Route path="/super-dial-dashboard" element={<SuperDialDashboard userData={data.user} />}>
              <Route index element={<ScriptBuilderHome />} />
              <Route path="home" element={<ScriptBuilderHome />} />
              {/* <Route path="results" element={<ScriptBuilderResults />}>
                <Route index element={<ScriptsPage />} />
                <Route path="scripts" element={<ScriptsPage />} />
                <Route path="pms-insurance-verifications" element={<PMSPage archived={false} />} />
                <Route path="archived-pms-insurance-verifications" element={<PMSPage archived={true} />} />
                <Route path="*" element={<ScriptsPage />} />
              </Route> */}
              <Route path="script-results" element={<ScriptsPage />} />
              <Route path="pms-insurance-verifications" element={<PMSPage archived={false} />} />
              <Route path="archived-pms-insurance-verifications" element={<PMSPage archived={true} />} />
              <Route path="keys" element={<KeysPage />} />
              <Route path="webhooks" element={<WebhooksPage />} />
              <Route path="developers-sandbox" element={<DevelopersSandbox />} />
              <Route path="call-settings" element={<CallSettings />} />
              <Route path="pms-integration" element={<PmsIntegration />} />
              <Route path="resources" element={<ResourcesPage />} />

              <Route path="team" element={<TeamPage />} />
              <Route path="demo" element={<DemoPage />} />
              <Route path="patients" element={<ScriptBuilderPatients />}>
                {/* <Route index element={<PatientsPage />}/>
                  <Route path="all" element={<PatientsPage/>}/>
                  <Route path="*" element={<PatientsPage/>}/> */}
              </Route>
              {/* <Route path="settings" element={<ScriptBuilderSetup />}>
                <Route index element={<KeysPage />} />
                <Route path="keys" element={<KeysPage />} />
                <Route path="webhooks" element={<WebhooksPage />} />
                <Route path="developers-sandbox" element={<DevelopersSandbox />} />
                <Route path="call-settings" element={<CallSettings />} />
                <Route path="pms-integration" element={<PmsIntegration />} />
                <Route path="resources" element={<ResourcesPage />} />

                <Route path="team" element={<TeamPage />} />
                <Route path="demo" element={<DemoPage />} />

                <Route path="*" element={<KeysPage />} />
              </Route> */}
              <Route path="*" element={<ScriptBuilderHome />} />
            </Route>
            <Route path="/super-dial-2fa" element={<Navigate to="/super-dial-dashboard" />} />
            <Route path="/super-dial-email-verification" element={<Navigate to="/super-dial-dashboard" />} />
          </>
          }
        </>
        :
        // <Route path="/super-dial-dashboard" element={<Navigate to="/super-dial-2fa" />} />
        <Route path="/super-dial-dashboard" element={<Navigate to="/super-dial-email-verification" />} />
      }
      <Route path="/super-dial-email-verification" element={<ScriptBuilderSignUpEmailVerification signedIn={true} />} />
      <Route path="/super-dial" element={<CreateScript />} />
      <Route path="/super-dial-create/:id" element={<CreateScriptManually />} />
      <Route path="/dashboard" element={<Navigate to="/super-dial-dashboard" />} />
      <Route path="/sign-in" element={<Navigate to="/super-dial-dashboard" />} />
      <Route path="/super-dial-sign-in" element={<Navigate to="/super-dial-dashboard" />} />
      <Route path="/super-dial-sign-up" element={<Navigate to="/super-dial-dashboard" />} />
      <Route path='*' element={<Navigate replace to='/super-dial-dashboard' />} />
    </Routes>
  ) : (
    <Navigate replace to='/super-dial-sign-in' />
  );
}

export default SuperDialRoutes;
