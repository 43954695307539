import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';

const Stopwatch = forwardRef((props, ref) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [running, setRunning] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useImperativeHandle(ref, () => ({
    start: startStopwatch,
    stop: stopStopwatch,
    reset: resetStopwatch
  }));

  const startStopwatch = () => {
    if (!running) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
      setRunning(true);
    }
  };

  const stopStopwatch = () => {
    if (running && intervalRef.current) {
      clearInterval(intervalRef.current);
      setRunning(false);
    }
  };

  const resetStopwatch = () => {
    stopStopwatch();
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  };

  React.useEffect(() => {
    if (seconds === 60) {
      setSeconds(0);
      setMinutes((prevMinutes) => prevMinutes + 1);
    }
    if (minutes === 60) {
      setMinutes(0);
      setHours((prevHours) => prevHours + 1);
    }
  }, [seconds, minutes, hours]);

  return (
    <>
      <div className='flex items-center justify-center gap-x-1'>
          <span>{hours < 10 ? '0' : '' }{hours}:</span>
          <span>{minutes < 10 ? '0' : '' }{minutes}:</span>
          <span>{seconds < 10 ? '0' : '' }{seconds}</span>
      </div>
    </>
  );
});

export default Stopwatch;

  