import React, { useEffect, useState } from 'react'
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { addBusinessDays, addHours } from 'date-fns';
import { DateTime } from 'luxon';
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { downloadPdfResults, getRobodialerUsers} from '../apis/robodialer';
import { toast } from 'react-hot-toast';
import { PMSPatientData } from 'types/types';






type PMSDetailedResultsTableProps = {
  patient: PMSPatientData;
  selectedPatient: string | null;
  setSelectedPatient: React.Dispatch<React.SetStateAction<string | null>>;
  clearQueryResult: () => void;
}

function PMSDetailedResultsTable({ patient, setSelectedPatient, clearQueryResult }: PMSDetailedResultsTableProps) {

  const [loading, setLoading] = useState<boolean>(false);
  const [initiatedName, setInitiatedName] = useState<string | null>(null);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await getRobodialerUsers();
          const userEntries = Object.entries(userResponse.data);
          const thisUserEntry = userEntries.find(([email]) => email === patient.call_initiated_by?.email);
        if (thisUserEntry) {
          // Extract the user information from the entry
          const [, thisUser] = thisUserEntry;
          setInitiatedName(thisUser.name);
        } else {
          setInitiatedName(patient.call_initiated_by?.email);
        }
      } catch (error) {
        setInitiatedName(patient.call_initiated_by?.email);
      }
    };
  
    fetchUser();
  }, [patient.call_initiated_by?.email]);
  
  const renderQuestionsAndAnswers = (obj = patient.attempt_results) => {
    if (typeof obj !== 'object' || obj === null) {
      return null;
    }
    delete obj['notes']

    return (
      <div>
        {Object.keys(obj).map((key) => {
          let questionObj = patient.attempt_results[key]
          let ans = (patient.attempt_results[key] || {}).answer
          let q = (patient.attempt_results[key] || {}).question
          if (typeof ans === 'boolean') {
            ans = ans ? 'Yes' : 'No'
          }
          const level = (key.split('.').length - 1);
          return (
            <div className='hover:bg-superbill-lavender-dark/50 ease-in-out duration-150'>
              <div className={`pl-${level * 20} border-b border-superbill-soap`} style={{ paddingLeft: `${level * 20}px` }} key={key}>
                <div
                  key={`header-${key}`}
                  className={`p-1 flex items-center justify-between text-superbill-jacarta  font-sans`}
                >
                  <span className="text-sm capitalize ">{key}: {q}</span>
                  <span className="font-bold text-sm capitalize text-end">{ans}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  const downloadPdf = async (ehr_id, location_id, patient_id, call_id: string | undefined) => {
    setLoading(true)
    const response = await downloadPdfResults(ehr_id, location_id, patient_id, call_id);
    setLoading(false)
    if (response.httpCode !== 200) {
      toast.error('Unable to Download, File not found', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    const fileName = 'file.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  let formattedNewDate;
  let newDateResult = new Date(DateTime.fromJSDate(new Date(patient?.created_at ?? patient?.date_created)).toFormat('MMM d, yyyy'))
  if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('hours')) {
    const match = patient?.selectedTurnAroundTime.match(/\d+/);
    let hours = 2;
    if (match) {
      hours = parseInt(match[0], 10);
    }
    let date = new Date(DateTime.fromJSDate(new Date(patient?.created_at ?? patient?.date_created)).toFormat('MMM d, yyyy'))
    newDateResult = addHours(date, hours)
    formattedNewDate = newDateResult.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else if (patient?.selectedTurnAroundTime && patient?.selectedTurnAroundTime.includes('BD')) {
    const match = patient?.selectedTurnAroundTime.match(/\d+/);
    let days = 2
    if (match) {
      days = parseInt(match[0], 10);
    }
    const formattedInitialDate = new Date(DateTime.fromJSDate(new Date(patient?.created_at ?? patient?.date_created)).toFormat('MMM d, yyyy'))
    newDateResult = addBusinessDays(formattedInitialDate, days);
    formattedNewDate = newDateResult.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }) + ' 17:00';
  }
  return (
    <>
      <div className='mb-2 flex items-center text-superbill-slate-grey text-sm font-jakarta cursor-pointer' onClick={() => { setSelectedPatient(null); clearQueryResult(); }}><BackIcon className='fill-superbill-slate-grey mr-2' />Back to all Results</div>
      <div className='mb-3 flex justify-between items-center'>
        <span>
          <span className='text-superbill-jacarta text-2xl font-jakarta font-bold'>{patient.first_name} {patient.last_name}</span> <span className='text-superbill-slate-grey text-2xl font-jakarta font-bold'> • {['Success','Failure'].includes(patient?.supportTeamOutcome) ? <>{DateTime.fromJSDate(new Date(patient.completedAt)).toFormat('MMMM d, yyyy')}</> : <em>Pending</em>}</span>
        </span>
        <button onClick={() => { downloadPdf(patient.ehr_id, patient.ehr_location_id, patient.ehr_patient_id, patient.callId) }} className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300
        '>{loading ? <Spinner /> : <>Download PDF</>}</button>
      </div>
      <div className='w-full flex gap-x-4'>
        <div className='w-1/3'>
        {
          patient.call_initiated_by?.email ?
          <div className='mb-3 bg-superbill-anti-flash-white border border-superbill-soap rounded p-2 overflow-auto font-sans text-sm flex justify-between text-superbill-jacarta'>
          <div>Call Initiated by</div>
          <div className='font-bold'>{initiatedName}</div>
        </div>
        :
        <></>
        }
        <div className=' bg-superbill-anti-flash-white border border-superbill-soap rounded p-2 divide-y divide-superbill-soap overflow-auto font-sans'>
          {Object.keys(patient.inputs).map((key) => (
            <div
              key={`header-${key}`}
              className={`p-1 flex items-center justify-between text-superbill-jacarta hover:bg-superbill-lavender-dark/50 ease-in-out duration-150`}
            >
              <span className="text-sm capitalize">{key}</span>
              <span className="font-bold text-sm capitalize text-end">{patient.inputs[key]}</span>
            </div>
          ))}
        </div>
        </div>
        <div className='w-2/3 bg-white border border-superbill-soap rounded p-2 overflow-auto h-[550px]'>
          {patient?.supportTeamOutcome !== 'Failure' ?
            <>
              {renderQuestionsAndAnswers()}
            </> : <div className='flex items-center justify-center flex-col w-full h-full'>
              <ExclamationIcon className="w-8 h-8 child:fill-[#F46F83] min-w-[32px] max-w-[32px] ml-2" />
              <div className='text-lg text-superbill-jacarta font-sans font-bold my-2'>Call failed</div>
              <div className='text-lg text-superbill-jacarta font-sans'>{patient?.call?.results['notes'] ? patient.attempt_results['notes'] : 'Please verify that the required inputs are correct before placing the call again'}</div>
            </div>}
        </div>
      </div>
    </>
  );

}
export default PMSDetailedResultsTable