import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';

import { AgentStatsResponse, } from 'types/types';
import { getAgentsStats } from 'apis/agent';


export default function AgentsStatsPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [statsData, setStatsData] = useState<null | AgentStatsResponse>(null);


  useEffect(() => {
    const fetch = async () => {
      const { data } = await getAgentsStats();
      setStatsData(data)
      setLoading(false)
    }
    fetch()
  }, []);


  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className='mx-4 mt-5'>
          <div className='flex justify-between items-center'>
            <div className='text-superbill-jacarta font-bold text-base font-jakarta'>Summary: <span className='text-xs text-superbill-gray'>(Date greater than: {statsData?.date})</span></div>
          </div>
          <div className='bg-superbill-anti-flash-white border border-superbill-soap rounded p-4  mb-3 mt-1'>
            <div className="grid grid-cols-6 font-jakarta">
              <div className='col-span-2 flex items-center gap-x-2 justify-center'>
                <div className='font-bold text-2xl text-superbill-jacarta'>{statsData?.agentsOnline?.total ?? 0}</div>
                <span className='text-sm text-superbill-slate-grey'>Agents Online</span>
              </div>

              <div className='col-span-2 flex items-center gap-x-2 justify-center'>
                <div className='font-bold text-2xl text-superbill-jacarta'>{statsData?.agentsOffline?.total ?? 0}</div>
                <span className='text-sm text-superbill-slate-grey'>Agents Offline</span>
              </div>

              <div className='col-span-2 flex items-center gap-x-2 justify-center'>
                <div className='font-bold text-2xl text-superbill-jacarta'>{statsData?.total_issues ?? 0}</div>
                <span className='text-sm text-superbill-slate-grey'>Reported Issues</span>
              </div>

            </div>
          </div>
          <div className='grid grid-cols-10 gap-4 mt-4 border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
            <div className='col-span-6'>
              <div className='text-sm text-superbill-jacarta'>Agents online</div>
              <div className="grid grid-cols-8 gap-4 mt-4">
                <div className='col-span-3'><span className='text-xs text-superbill-jacarta'>Email</span></div>
                <div className='col-span-1'><span className='text-xs text-superbill-jacarta'>Issues</span></div>
                <div className='col-span-3'><span className='text-xs text-superbill-jacarta'>Last Login At</span></div>
                <div className='col-span-1'><span className='text-xs text-superbill-jacarta'>Submits OK</span></div>
              </div>
              <div className="grid grid-cols-8 gap-4 border border-superpay-soap rounded p-4">
                {(statsData?.agentsOnline?.agents ?? [])?.map(agent => (
                  <>
                    <div className='col-span-3'><span className='text-xs text-superbill-jacarta'> {agent.email}</span></div>
                    <div className='col-span-1'><span className='text-xs text-superbill-jacarta'> {agent.issues}</span></div>
                    <div className='col-span-3'><span className='text-xs text-superbill-jacarta'> {agent.lastLoginAt}</span></div>
                    <div className='col-span-1'><span className='text-xs text-superbill-jacarta'> {agent.submitsOk}</span></div>
                  </>
                ))}
              </div>
            </div>
            <div className='col-span-4'>
              <div className='text-sm text-superbill-jacarta'>Agents offline</div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className='col-span-1'><span className='text-xs text-superbill-jacarta'>Email</span></div>
                <div className='col-span-1'><span className='text-xs text-superbill-jacarta'>Last Login At</span></div>
              </div>
              {statsData?.agentsOffline?.agents && 0 < statsData?.agentsOffline?.agents.length ?
                <div className="grid grid-cols-2 gap-4 border border-superpay-soap rounded p-4">
                  {(statsData?.agentsOffline?.agents ?? [])?.map(agent => (
                    <>
                      <div className='col-span-1'><span className='text-xs text-superbill-jacarta'> {agent.email}</span></div>
                      <div className='col-span-1'><span className='text-xs text-superbill-jacarta'> {agent.lastLoginAt}</span></div>
                    </>
                  ))}
                </div>
                : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
