import { Fragment, useState } from 'react';
import Loader from 'components/Loader';
import { Dialog, Transition } from '@headlessui/react';
import React from 'react';

type DeletePolicyModalProps = {
  scriptId: string | null;
  scriptName: string | null;
  closeModal: () => void;
  onDeleteScript: () => void;
  modalIsOpen: boolean;
};

export default function DeletePolicyModal({
  scriptId,
  closeModal,
  onDeleteScript,
  modalIsOpen,
  scriptName,
}: DeletePolicyModalProps) {
  const [isLoading, setIsloading] = useState<boolean>(false)
  
  const modalClose = () => {
    setIsloading(false)
    closeModal()
  }

  return (
    <Transition.Root show={modalIsOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={modalClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='relative bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full '>
                <div className='sm:flex sm:items-start'>
                  {/* <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationIcon
                      className='h-6 w-6 text-red-600'
                      aria-hidden='true'
                    />
                  </div> */}
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left p-2'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-bold text-superbill-jacarta font-jakarta'>
                      {`Delete "${(scriptName ? scriptName : scriptId)}"`}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-superbill-slate-grey font-sans'>{'This action cannot be undone'}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between px-4 py-3 mt-4 border-t border-1 border-superbill-soap">
                <button
                    className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                    onClick={modalClose}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isLoading}
                    className='bg-superbill-ultra-red disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                    onClick={async () => {
                      setIsloading(true)
                      await onDeleteScript()
                      setIsloading(false)
                    }
                    }
                  >
                    {isLoading ? <Loader size={28} /> : <>Delete</>
                    }
                  </button>
                </div>
                {/* <div className='mt-5 w-full flex justify-end'>
                  <button
                    type='button'
                    className='ml-2 flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='mr-2 items-center flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm'
                    onClick={submitDelete}>
                    {'Delete'}
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
