import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { RobodialerUser } from 'types/types';
import { ReactComponent as LinkedIcon } from 'sign-up/superpay//assets/tpa-status-linked.svg';
type ScriptBuilderPmsIntegrationProps = {
  userData: RobodialerUser | null,
  setUserData: (userDate: RobodialerUser | null) => void
}

const PmsIntegration: React.FC = () => {
  const props = useOutletContext<ScriptBuilderPmsIntegrationProps>();
  const { userData, setUserData } = props
  
  console.log();
  
  return (
    <div className='mt-5'>
      {userData?.pmsActive ?
      <div className='bg-white mt-4 rounded border border-superbill-soap px-5 py-3 justify-center items-center w-full min-w-[300px] lg:w-[300px] grow ease-in-out duration-150'>
      <div className='flex justify-between items-center'>
        <div>
          <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-bold'>Successfully integrated</div>
          <div className='text-sm text-start text-superpay-light-text-gray'>{userData?.pmsData?.linkedAt ?? ''}</div>
        </div>
        <div className='ml-1 flex items-center px-2 py-1 rounded'>
          <LinkedIcon/>
          <div className='ml-1'>{userData?.pmsData?.name ?? ''}</div>
        </div>
      </div>
      
    </div>
      :
      <div className="flex flex-col px-5 py-8 mt-10 bg-gradient-to-r to-[#432792] from-[#4A43CD] rounded">
        <div className="flex justify-between mt-2 items-center">
          <div className='text-white font-bold text-base font-sans'>Superpower your practice by integrating your PMS</div>
          <div>
            <a rel='noopener noreferrer' target='_blank' href='https://cuda.io/superbill' className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 ml-2' >
              Talk to Us
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mt-4 px-5">
          <div className='flex flex-col justify-between p-2 bg-white border border-superbill-soap w-full rounded mt-4 font-sans min-h-[175px]'>
            <div className="flex justify-between w-full px-4 py-3 h-full flex-col">
              <div className="mr-2 text-superbill-indigo text-xs font-bold text-center">
                IT'S EASY
              </div>
              <div className="text-center my-2 text-superbill-jacarta text-lg font-bold font-jakarta">
                Direct integration with your PMS
              </div>
              <div className='text-superbill-jacarta text-sm flex-1 '>
                No need to overhaul your existing processes. We’ll integrate directly with your PMS and write results directly back to it.
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between p-2 bg-white border border-superbill-soap w-full rounded mt-4 font-sans min-h-[175px]'>
            <div className="flex justify-between w-full px-4 py-3 h-full flex-col">
              <div className="mr-2 text-superbill-indigo text-xs font-bold text-center">
                IT'S AUTOMATED
              </div>
              <div className="text-center my-2 text-superbill-jacarta text-lg font-bold font-jakarta">
                Phone calls without the hassle
              </div>
              <div className='text-superbill-jacarta text-sm flex-1 '>
                We guarantee that calls will be completed within the selected time period in business days (BD)
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between p-2 bg-white border border-superbill-soap w-full rounded mt-4 font-sans min-h-[175px]'>
            <div className="flex justify-between w-full px-4 py-3 h-full flex-col">
              <div className="mr-2 text-superbill-indigo text-xs font-bold text-center">
                IT'S FLEXIBLE
              </div>
              <div className="text-center my-2 text-superbill-jacarta text-lg font-bold font-jakarta">
                All the details you need
              </div>
              <div className='text-superbill-jacarta text-sm flex-1 '>
                We can verify all the data elements and fields you need — all you have to do is specify your custom insurance verification script.
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default PmsIntegration;