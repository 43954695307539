// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';


type UserType = {
  email: string;
  emailVerified: boolean;
  tenantId: string;
  planDetermined: boolean;
  uid: string;
  userType: string;
  firstName: string;
  lastName: string;
  superPayPracticeProvider: boolean; 
  mfaEnabled: boolean;
  lastLoginAt: string;
  // mfaInfo: [{displayName: string; enrolledAt: string; mfaEnrollmentId: string; phoneInfo: string}];
};

type UserDataType = {
  user: UserType;
  defaultSuperPayPaymentMethod: any; //just 4 testing
  superPay: any; //just 4 testing
  superPayPractice: any; //just 4 testing
  superPayPracticeEhrConfigured: any; //just 4 testing
  superPayPracticeEhrPracticeName: any; //just 4 testing
  hasOnboarded: any; //just 4 testing
  planDetermined: any; //just 4 testing
  viewedTutorial: any; //just 4 testing
  providersWithoutData: any; //just 4 testing
  authStatusDetermined: any; //just 4 testing
  selfPayInvited: any; //just 4 testing
  selfPayTotal: any; //just 4 testing
  metInviteThresholdAt: any; //just 4 testing
  mfaEnabled: any;
}

type ContextType = {
  data: UserDataType | null,
  setData: (data: UserDataType) => void;
  authStatusDetermined: boolean,
}
const AuthContext = createContext<ContextType>({
  data: null,
  setData: (data: UserDataType) => {},
  authStatusDetermined: false,
});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState<UserDataType | null>(null);
  const [authStatusDetermined, setAuthStatusDetermined] = useState(false);
  
  return (
    <AuthContext.Provider value={{ data, setData, authStatusDetermined }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};