import React, { useEffect, useState } from "react";
import Select from "react-select";
import Loader from "components/Loader";
import { OpenSlot, PhDataType, ReactSelectOptions } from "types/types";


type Props = {
  options: Array<ReactSelectOptions>;
  formData: PhDataType | null;
  setFormData: React.Dispatch<React.SetStateAction<PhDataType | null>>;
};

const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


const EditPhComponent: React.FC<Props> = ({ formData, setFormData, options }) => {
  console.log(options);
  
  const handleInstructionChange = (key: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      instructions: {
        ...prevData?.instructions,
        [key]: value,
      },
    }) as PhDataType);
  };

  const handleOpenSlotChange = (day: string, key: keyof OpenSlot, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      officeHours: {
        ...prevData?.officeHours,
        openSlots: {
          ...prevData?.officeHours.openSlots,
          [day]: {
            ...prevData?.officeHours.openSlots[day],
            [key]: value,
          },
        },
      },
    }) as PhDataType);
  };

  return (
    <form>
      <h2 className="text-superbill-jacarta">IVR Instructions</h2>
      {options.map(({value: key}) => (
        <div key={key}>
          <span className="text-xs text-superbill-jacarta">{key}:</span>
          <label>
            <textarea
              className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full"
              value={formData?.instructions[key]}
              onChange={(e) => handleInstructionChange(key, e.target.value)}
            />
          </label>
        </div>
      ))}
      <h2 className="text-superbill-jacarta mt-3">Office Hours:</h2>
      <div className="text-xs text-superbill-slate-grey mb-3">
          <div>Format: hh:mm:ss <strong>(Open1: 08:00:00 Closed1: 17:00:00)</strong></div>
          <div>It is possible for an office to be closed for lunch, in which case you would use open2 and closed2 for the open hours after lunch. <strong>(Open1: 08:00:00 Closed1: 12:00:00 Open2: 13:00:00 Closed2: 17:00:00)</strong></div>
          <div>If an office mentions what time they open, but does not mention what time they close, then assume the closing time is 5pm</div>
      </div>
      {dayOrder.map((day) => (
        <div key={day}>
          <h3 className="text-sm text-superbill-jacarta">{day}</h3>
          <div className="grid grid-cols-2">
            {['open1', 'closed1', 'open2', 'closed2'].map((slot) => (
              <div key={slot} className="my-1 flex-col flex">
                <span className="text-xs text-superbill-jacarta">{slot}:</span>
                <label>
                  <input
                    type="text"
                    className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-3/4"
                    value={(formData?.officeHours.openSlots[day] as OpenSlot)?.[slot as keyof OpenSlot] || ''}
                    onChange={(e) => handleOpenSlotChange(day, slot as keyof OpenSlot, e.target.value)}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      
    </form>
  );
};

export default EditPhComponent;