import React from "react";
import { ReactComponent as HipaaLock } from "assets/hipaa-lock.svg";

export default function HIPAFooter(): JSX.Element {
  return (
    <div className="px-6 pt-2 flex items-center text-xs text-superpay-light-text-gray bg-white font-sans">
      <span className="min-w-[2em]">
          <HipaaLock />
      </span>
      <div className="ml-3">
        <abbr>
          SuperBill is fully HIPAA-compliant. To learn more about how we
          securely store and process your data, please review our{" "}
        </abbr>
        <a
          target="_parent"
          className="hover-underline-animation text-superbill-ultramarine"
          href="https://www.thesuperbill.com/privacy"
        >
          <abbr title='Visit "Privacy Notice" page'>Privacy Notice.</abbr>
        </a>
      </div>
    </div>
  );
}
