import ResultsImg from "assets/results.svg";
type  ResultsInfoProps = {
  onClose: () => void;
  onNext: () => void;
}

const ResultsInfo = ({onClose, onNext}:ResultsInfoProps) => {

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Welcome to SuperDial
      </nav>
      <div className="flex-grow overflow-y-auto p-9 font-sans">
          <div className='text-base text-superpay-light-text-gray'><strong>3</strong> OF 4</div>
          <div className='text-lg font-jakarta font-bold text-superbill-jacarta mt-2'>Viewing Results</div>
          <div className='text-sm text-superbill-jacarta mt-2'>After SuperDial places your calls, you can view call results and download a CSV file of your completed calls in the <strong>Results</strong> tab.
          </div>
          <div className="flex items-cneter justify-center">
          <img
              src={ResultsImg}
              alt="setup healthie account"
              className="w-full md:w-3/4 mt-2 max-w-[639px]"
            />
          </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3">
        <button
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
          onClick={() => {
            if (onNext) {
              onNext()
            }else{
              onClose()
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ResultsInfo;
