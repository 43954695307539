import React, { useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Modal from "react-modal";
import { inviteMember } from "apis/robodialer";
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";

// custom styles
const customStyles = {
  content: {
    height: "max(50%,400px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  onConfirm: () => void;
  onCancel: () => void;
}


export default function InviteMembersModal({ isOpen, setIsOpen, onConfirm, onCancel }: Props) {
  
  const [isLoading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({mode: 'onChange'});

  const onSubmit = async (data) => {

    setLoading(true)
    const response = await inviteMember(data.email, data.userType)
    setLoading(false)
    if (response.httpCode !== 200) {
      toast.error(`${response.httpCode}: ${response.data?.error ?? 'Unable to invite user'}`,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    } else {
      toast.success('User added!', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      onConfirm()
      setIsOpen(false)
    }
  };
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => { setIsOpen(false) }}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Invite User
          </nav>
          <div className="flex-grow overflow-y-auto px-9 py-4">
            <div className="w-full bg-white rounded mb-4">
              <span className="text-sm text-superpay-light-text-gray">The invitee will be able to sign up for SuperDial using the email you provide</span>
            </div>
            <form>
              <div className="mb-4">
                <label htmlFor="email" className="block text-superbill-jacarta text-sm font-bold">Email:</label>

                <input
                type="email"
                className={`w-full mb-2 rounded font-sans ${
                  errors.email
                    ? "border-red-500"
                    : "border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                }`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <div className="mb-1 text-red-500 text-xs">
                  {errors && errors?.email?.message && typeof errors?.email?.message === 'string' ? <span>{errors?.email?.message}</span> : <span></span> } 
                </div>
              </div>

              <div className="mb-4">
                <div className="flex flex-col my-2 gap-y-3">
                  <label className="items-center">
                    <input type="radio" value="basic" {...register('userType', { required: 'This field is required' })} className="form-radio" />
                    <span className="ml-2 text-superbill-jacarta text-sm">Basic User</span>
                    <div className="text-superbill-slate-grey text-sm">May edit scripts, download results, place calls, and invite users.</div>
                  </label>
                  <label className="items-center">
                    <input type="radio" value="admin" {...register('userType', { required: 'This field is required' })} className="form-radio" />
                    <span className="ml-2 text-superbill-jacarta text-sm">Admin</span>
                    <div className="text-superbill-slate-grey text-sm">Basic user privileges + see results statistics and make account changes if applicable</div>
                  </label>
                </div>
              </div>

            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={onCancel}
            >
              Close
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || !isValid}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              {isLoading ? <Spinner /> : <>Invite</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
