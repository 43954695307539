import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
type SandboxCallsResultsDataProps = {
  results: any
  callId: string
  onBack: () => void
}

function SandboxCallsResultsData({ results, callId, onBack }: SandboxCallsResultsDataProps) {




  return (
    <>
      <div className='flex justify-between items-center'>
        <div className='flex justify-start items-center cursor-pointer' onClick={onBack}>
          <BackIcon />
          <div className='text-superbill-jacarta text-lg font-bold ml-2'>{results.name}</div> 
        </div>
        <div className='text-superbill-jacarta text-sm'>{results.createdAt}</div> 
      </div>
      <div className="flex items-center justify-between gap-4 w-full mt-4 p-5 bg-white rounded">
        <div className='h-[500px] overflow-y-auto p-2 w-full'>
          <div className='text-superbill-jacarta my-2 font-bold text-sm'>
            Call Id
          </div>
          <div className='text-superbill-jacarta text-sm'>{callId}</div>
          <div className='text-superbill-jacarta my-2 font-bold text-sm'>
            Script Id
          </div>
          <div className='text-superbill-jacarta text-sm'>{results.scriptId}</div>
          <div className='text-superbill-jacarta my-2 font-bold text-sm'>
            Response Code
          </div>
          <div>{results.success ? 200 : 500}</div>
          <div className='text-superbill-jacarta my-2 font-bold text-sm'>
            Inputs:
          </div>
          <ReactJson
            src={results.inputs}
            name={null}
            iconStyle="triangle"
            displayDataTypes={false}
          />
        </div>
        <div className='text-sm text-superpay-light-text-gray w-full h-full bg-superbill-lavender-light rounded'>
          <div className=' h-[500px] overflow-y-auto p-2'>
            <div className='text-superbill-jacarta my-2 font-bold text-sm'>
              Result Schema
            </div>
            {results.results && 0 < results.results.length ?
              <ReactJson
                src={results.results}
                name={null}
                iconStyle="triangle"
                displayDataTypes={false}
              />
              :
              <div className='text-center text-superbill-jacarta text-base'>No Results</div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SandboxCallsResultsData