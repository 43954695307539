import React, { useEffect, useState } from 'react';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Loader from 'components/Loader';
import { useForm } from 'react-hook-form';
import { RobodialerUser, Script } from 'types/types';
import { getRobodialerUsers, getScripts, placeDemoCall } from 'apis/robodialer';
import InviteMembersModal from './dashboard/invite-members-modal';
import SelectionPage from './place-demo-calls/selection-page';

const DemoPage: React.FC = () => {
  const { register, handleSubmit, control, watch, formState: { isValid } } = useForm({ mode: 'onChange' });
  const [scriptData, setScriptData] = React.useState<Array<Script> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<RobodialerUser> | null>([])

  useEffect(() => {
    const fetch = async () => {
      const allowlistResponse = await getRobodialerUsers();
      const usersList = Object.entries(allowlistResponse.data).map(
        ([id, email]) => {
          return {
            id,
            ...email,
          };
        }
      );
      setUsers(usersList);

    }
    fetch()
  }, []);

  useEffect(() => {
    if (users) {
      setIsLoading(false)
    }
  }, [users])

  useEffect(() => {

    const fetchScripts = async () => {
      const { data, httpCode } = await getScripts();
      if (httpCode !== 200) {
        return;
      }
      setScriptData(data)

    }
    fetchScripts()
  }, []);

  return (
    <>
      {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
        < Loader />
      </div>) : null}
      {!isLoading ? (
        <div className='divide-y divide-superbill-soap mb-2'>
          <div className='w-full flex justify-between my-2 items-center'>
            <div className='text-2xl font-bold mr-1'>Demo</div>
            <button
                disabled={!isValid || loading || success}
                className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                onClick={handleSubmit(async (e) => {
                  console.log(e);
                  setLoading(true)
                  const {httpCode} = await placeDemoCall({
                    scriptId: e.script.value,
                    to: e.phone
                  })
                  if(httpCode === 200){
                    setSuccess(true)
                  }
                  setLoading(false)
                })}
              >
                {loading ? <Spinner /> : <>Call me</>}
              </button>
          </div>
          <SelectionPage scriptData={scriptData} register={register} control={control} loading={loading} setLoading={setLoading} success={success} watch={watch}/>
        </div>
      ) : null}
      <InviteMembersModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} onCancel={() => {
        setIsModalOpen(false)
      }} onConfirm={async () => {
        setIsModalOpen(false)
        setIsLoading(true)
        const allowlistResponse = await getRobodialerUsers();
        setIsLoading(false)
        const usersList = Object.entries(allowlistResponse.data).map(
          ([id, email]) => {
            return {
              id,
              ...email,
            };
          }
        );
        setUsers(usersList);
      }} />
    </>
  );
};

export default DemoPage;