type  SuccessPageProps = {
  onClose: () => void;
  onSuccess: () => void;
}

const SuccessPage = ({onClose, onSuccess}:SuccessPageProps) => {

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9 flex items-center justify-center">
        <div className='flex-col flex justify-center items-center'>
          <div className='text-base font-bold text-superbill-jacarta'>Hooray, we're placing your calls!</div>
          <div className='text-sm text-superbill-jacarta'>You can view your call details in the <strong>Results</strong> table once they are ready</div>
        </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3">
        <button
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
          onClick={() => {
            if (onSuccess) {
              onSuccess()
            }else{
              onClose()
            }
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
