import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { RunScriptErrosType } from 'types/types';

type ErrorsPageProps = {
  errors: RunScriptErrosType;
  onClose: () => void
  onBack: () => void
}

const ErrorsPage = ({ errors, onClose, onBack }: ErrorsPageProps) => {

  const errorMappings = {
    unexpected_headers: "Extraneous header",
    missing_headers: "Required header",
    duplicate_headers: "Duplicate header",
    missing_inputs: "Missing required input",
    invalid_inputs: "Incorrectly formatted input"
  };

  const errorTypes = Object.keys(errors);

  const totalErrors = errorTypes.reduce((count, type) => count + errors[type].length, 0);

  
  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        <div className='flex justify-start font-sans items-center w-full h-fit bg-superbill-banner-red p-2 rounded mt-1 mb-2'>
          <div className='flex justify-start'>
            <InfoIcon className='child:fill-superbill-ultra-red w-[18px]' />
          </div>
          <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
            We’re unable to place calls using the CSV provided. Please resolve the following <strong>{totalErrors} error{totalErrors ===1? '':'s'}</strong> and re-upload your file
          </div>
        </div>
        <div className=" mt-2 p-4 border border-superbill-soap rounded">
          {errorTypes.map((type) => (
            <>
              {errors[type].length > 0 && (
                errors[type].map((error, index) => (
                  <div className='flex justify-between items-center border-b border-superbill-soap my-2 pb-2' key={index}>
                    <div className="text-superbill-jacarta flex items-center font-bold">{errorMappings[type]}</div>
                    <div className="flex items-center">
                      <div className="text-superbill-jacarta ml-2">{error}</div>
                    </div>
                  </div>
                ))
              )}
            </>
          ))}
        </div>


      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-between px-4 py-3">
      <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={onBack}
        >
          Back
        </button>
        <button
          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
          onClick={onClose}
        >
          Close
        </button>
        
      </div>
    </div>
  );
};

export default ErrorsPage;