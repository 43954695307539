import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Modal from "react-modal";
import FilterForm, { FilterFormValues } from "./components/filter-form";
import { set } from "react-hook-form";

const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  onClose: () => void;
  onConfirm: (e:FilterFormValues) => void;
  defaultValues: FilterFormValues | null;
}

// 
export default function QMToolsFilterModal({ modalIsOpen, onClose, defaultValues, onConfirm }: Props) {
    const ref = useRef<HTMLFormElement>(null);
    const handleClick = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setIsLoading(true);
        await ref?.current?.onSubmit()
        setIsLoading(false);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    return (
        <div>
        <Modal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Modal"
        >
            <div className="flex flex-col h-full divide-y font-sans">
                <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
                    Filters
                </nav>
                <div className="flex-grow overflow-y-auto p-9">
                    <FilterForm showSearchButton={false} search={(e:FilterFormValues) => {
                        if(e.hasOwnProperty('robodialer') && typeof e.robodialer === 'object' ){
                            e.robodialerId = e.robodialer?.value
                        }
                        onConfirm(e)
                    }} defaultValues={{
                        batchId: defaultValues?.batchId ?? '',
                        callId: defaultValues?.callId ?? '',
                        batchStatus: defaultValues?.batchStatus ?? '',
                        filterBy: defaultValues?.filterBy ?? '',
                        dateOption: defaultValues?.dateOption ?? '',
                        paused: defaultValues?.paused ?? '',
                        cancelled: defaultValues?.cancelled ?? '',
                        hidden: defaultValues?.hidden ?? '',
                        dateFrom: defaultValues?.dateFrom ?? '',
                        dateTo: defaultValues?.dateTo ?? '',
                        robodialerId: defaultValues?.robodialerId ?? '',
                    }} ref={ref}/>
                </div>
                <div className="flex flex-row justify-between px-4 py-3">
                <button
                className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                onClick={() => { onClose() }}
                >
                Close
                </button>
                <button
                onClick={handleClick}
                disabled={isLoading}
                className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
                {isLoading ? <Spinner /> : <>Apply</>}
                </button>
            </div>
            </div>
        </Modal>
        </div>
    );
};
