import React, { useState } from "react";

type navType = {
  webkitSpeechRecognition?: any;
  SpeechRecognition?: any;
};

type SpeechRecognitionComponentProps = {
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  success: boolean;
};
export default function SpeechRecognitionComponent({
  setSuccess, success
}: SpeechRecognitionComponentProps) {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");

  const startListening = () => {
    setIsListening(true);
    setTranscript("");
    setSuccess(false);
    const speechRec =
      (window as navType).SpeechRecognition ||
      (window as navType).webkitSpeechRecognition ||
      false;
    const recognition = new speechRec();
    console.log(
      "recognition",
      recognition,
      (window as navType).SpeechRecognition,
      (window as navType).webkitSpeechRecognition
    );

    if (recognition) {
      console.log(recognition?.start);

      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        const currentTranscript = event.results[0][0].transcript;
        setTranscript(currentTranscript);
        if (currentTranscript.toLowerCase() === "hello") {
          setSuccess(true);
          stopListening();
        }
      };

      recognition.onend = () => {
        stopListening()
      };

      recognition.start();
    } else {
      console.error("SpeechRecognition not available");
    }
  };

  const stopListening = () => {
    setTimeout(() => {
      setIsListening(false);
    }, 2000);
  };

  return (
    <div>
      {!isListening ? (
        <div className="flex items-center justify-center p-2 w-full">
          {/* animate__slower animate__infinite animate__heartBeat animate__delay-4s animate__animated */}
          <button
            onClick={startListening}
            disabled={isListening}
            className="rounded-full border-2 border-superbill-indigo p-5 shadow-md transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              className="w-8 h-8 fill-superbill-indigo"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
              />
            </svg>
          </button>
        </div>
      ) : null}
      {isListening ? (
        <>
          <div className="flex items-center justify-center p-2 w-full">
            <button
              onClick={stopListening}
              disabled={!isListening}
              className="rounded-full border-2 border-superbill-indigo p-5  shadow-md transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="w-8 h-8 fill-superbill-indigo"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z"
                />
              </svg>
            </button>
          </div>
          <p className="text-sm text-superbill-jacarta">Say "Hello"</p>
          <p className="text-sm text-superbill-jacarta">Transcription: {transcript}</p>
        </>
      ) : null}
    </div>
  );
}
