import React, { useEffect } from 'react';
import { Progress } from 'antd';

const MicAudioComponent = ({ stream }) => {
  const [audioLevel, setAudioLevel] = React.useState<number>(0);

  useEffect(() => {
    const audioContext = new AudioContext();
    const mediaStreamAudioSourceNode =
      audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Float32Array(analyserNode.fftSize);
    const onFrame = () => {
      analyserNode.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (const amplitude of pcmData) {
        sumSquares += amplitude * amplitude;
      }
      setAudioLevel(Math.sqrt(sumSquares / pcmData.length)*100);
      window.requestAnimationFrame(onFrame);
    };
    const frameRequest = window.requestAnimationFrame(onFrame);
    return () => {
      mediaStreamAudioSourceNode.disconnect();
      analyserNode.disconnect();
      window.cancelAnimationFrame(frameRequest);
    };
  }, [stream]);
  return (
    <div  className='w-full flex justify-center items-center'>
      <Progress className='w-1/2' percent={audioLevel} />
    </div>
);
};

export default MicAudioComponent;