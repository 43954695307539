import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./authProvider";
import AgentDashboard from "agents/AgentDashboard";
import SidekickContent from "agents/sidekick/sidekickContent";
import { useSocket } from "./socketProvider";
import AgentsStatsPage from "../agents/sidekick/agents-stats";
import AgentsAuditPage from "../agents/sidekick/agents-audit";
import QMPage from "../agents/sidekick/qm-page";
import QMCallsPage from "../agents/sidekick/qm-calls-page";
import QMgadgetsPage from "../agents/sidekick/qm-tools-page";
import AdminsPage from "../agents/sidekick/admin-page";
import QMPhonebookPage from "../agents/sidekick/phonebook-page";
import QMScriptsPage from "../agents/sidekick/qm-scripts-page";

import QMToolsWrapperPage from "../agents/sidekick/qm-tools-wrapper";
import QMToolsAssignCallsPage from "../agents/sidekick/qm-tools-assign-calls";

function AgentsRoutes() {
  const { data } = useAuth();
  const { setSocketInstance, socketInstance } = useSocket();
  
  return data?.user ? (
    <Routes>
      <Route path="/agents-dashboard" element={<AgentDashboard userData={data.user} />}>
        <Route index element={<SidekickContent />} />
        <Route path="sidekick" element={<SidekickContent />} />
        <Route path="audit" element={<AgentsAuditPage/>} />
        <Route path="tools" element={<QMToolsWrapperPage userData={data.user}/>}>
          <Route index element={<QMPage />} />
          <Route path="stats" element={<AgentsStatsPage/>} />
          <Route path="qm" element={<QMPage/>} />
          <Route path="scripts" element={<QMScriptsPage/>} />
          <Route path="calls" element={<QMCallsPage/>} />
          <Route path="phonebook" element={<QMPhonebookPage/>} />
          <Route path="gadgets" element={<QMgadgetsPage/>} />
          <Route path="assign-calls" element={<QMToolsAssignCallsPage/>} />
          <Route path="admin" element={<AdminsPage/>} />
          <Route path="*" element={<QMPage />} />
        </Route>
        <Route path="*" element={<SidekickContent />} />
      </Route>
      <Route path="/dashboard" element={<Navigate to="/agents-dashboard" />} />
      <Route path="/sign-in" element={<Navigate to="/agents-dashboard" />} />
      <Route path="/super-dial-dashboard" element={<Navigate to="/agents-dashboard" />} />
      <Route path="/super-dial-sign-in" element={<Navigate to="/agents-dashboard" />} />
      <Route path="/super-dial-sign-up" element={<Navigate to="/agents-dashboard" />} />
      <Route path='*' element={<Navigate replace to='/agents-dashboard' />} />
    </Routes>
  ) : (
    <Navigate replace to='/agents-sign-in' />
  );
}

export default AgentsRoutes;