import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { RobodialerUser } from 'types/types';
import { getRobodialerUsers} from 'apis/robodialer';
import { DateTime } from 'luxon';
import InviteMembersModal from './dashboard/invite-members-modal';
import { useOutletContext } from 'react-router-dom';




interface ContextProps {
  userData: RobodialerUser | null,
}


const TeamPage: React.FC = () => {
  const { userData } = useOutletContext<ContextProps>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const options = ['2BD', '1BD', '4BD hours']
  const [users, setUsers] = useState<Array<RobodialerUser> |  null>([]);

  
  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const allowlistResponse = await getRobodialerUsers();
      const usersList = Object.entries(allowlistResponse.data).map(
        ([id, email]) => {
          return {
            id,
            ...email,
          };
        }
      );
      setUsers(usersList);
      setIsLoading(false);
      
    }
    fetch()
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (users) {
      setIsLoading(false)
    }
  }, [users])


  return (
    <>
      {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
        < Loader />
      </div>) : null}
      {!isLoading ? (
        <div className='mb-2'>
          <div className='text-superbill-jacarta font-jakarta font-semibold text-xl'>
            Team
          </div>
          {/* {userData?.role?.toLocaleLowerCase() === 'admin' ? */}
            <div className='w-full flex justify-end mb-2'>
              <button onClick={() => {
                setIsModalOpen(true)
              }} className={`bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300`}><span className='text-superbill-jacarta font-semibold text-sm font-jacarta'>Invite team member</span></button>
            </div>
          {/* :null} */}
          <div className="bg-white rounded border border-superbill-soap mt-4 overflow-hidden">
          <div className="grid grid-cols-8 gap-4 w-full p-2 bg-superbill-anti-flash-white border-b border-superbill-soap">
            <div className='col-span-2 text-sm text-superbill-jacarta font-semibold'>Name</div>
            <div className='col-span-3 text-sm text-superbill-jacarta font-semibold '>Email</div>
            <div className='col-span-1 text-sm text-superbill-jacarta font-semibold'>Type</div>
            <div className='col-span-2 text-sm text-superbill-jacarta font-semibold text-right'>Account Created On</div>
          </div>
          <div className='w-full bg-white py-1 divide-y divide-superbill-soap font-sans'>
            {users?.length === 0 ? <div className='w-full align-center flex justify-center'>
              <Loader/>
            </div> : null}
            {(users)?.map((user) => {
              return (
                <div className='grid grid-cols-8 gap-4 p-3 content-center items-center' key={user?.email} >
                  <div className='col-span-2 text-superbill-jacarta text-base font-light'>{user?.name ?? user?.firstName ?? '-'}</div>
                  <div className='col-span-3 text-superbill-jacarta text-base text-ellipsis overflow-hidden'>{user?.email}</div>
                  <div className='col-span-1 text-superbill-jacarta text-base font-light'>{user?.role?.toLocaleLowerCase() === 'admin' ? 'Admin' : 'Basic User'}</div>
                  <div className={`col-span-2 text-base font-light text-right ${user?.signupAt? 'text-superbill-jacarta' : 'text-superpay-light-gray' }`}>{user?.signupAt? DateTime.fromJSDate(new Date(user?.signupAt)).toFormat(
                      'MMMM d, yyyy'
                    ) : 'Invite Pending'}</div>

                </div>
              )
            })}
          </div>
          </div>
        </div>
      ) : null}
      <InviteMembersModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} onCancel={() => {
        setIsModalOpen(false)
      }} onConfirm={async() => {
        setIsModalOpen(false)
        setIsLoading(true)
        const allowlistResponse = await getRobodialerUsers();
        setIsLoading(false)
        const usersList = Object.entries(allowlistResponse.data).map(
          ([id, email]) => {
            return {
              id,
              ...email,
            };
          }
        );
        setUsers(usersList);
      }}/>
    </>
  );
};

export default TeamPage;