import { useState } from 'react';
import SignUpForm from './sign-up/sign-up-form';
import { SignUpPageStates } from 'enums/enums';
import { useNavigate } from 'react-router-dom';




interface SignUpProps {
  initialPageState?: SignUpPageStates;
  agent?: boolean;
}

export default function ScriptBuilderSignUp({agent = false, initialPageState = SignUpPageStates.SignupForm}: SignUpProps) {
 
  const [pageState, setPageState] = useState<SignUpPageStates>(initialPageState)
  const navigate = useNavigate();
  return (
    <div className="bg-superdial-background-full bg-cover h-full text-superbill-jacarta">
      {pageState === SignUpPageStates.SignupForm ?
        <div className="w-full flex justify-start items-center h-screen overflow-y-auto flex-col">
          <div className="w-full text-white text-center font-bold mb-4 font-jakarta mt-2">
            <a href="https://www.thesuperbill.com">
              SuperBill
            </a>
          </div>
          <div className="w-7/8 justify-center px-10 py-8 rounded bg-white">
            <div className="font-jakarta text-2xl font-bold text-superbill-jacarta">
              Welcome to SuperDial
            </div>
            <div className="font-jakarta text-superbill-slate-grey text-sm">
              Automated outbound healthcare phone calls
            </div>
            <SignUpForm setPageState={setPageState} agent={agent}/>
          </div>
          <div className="w-full justify-center text-center text-white font-jakarta">
            <div className="text-superbill-soap mb-4 mt-6 text-sm ">
              Want to get started with SuperDial?
            </div>
            <div>
              <a href='https://calendly.com/sam-schwager/outbound-call-automation' className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/30 text-white font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300">Schedule Consultation</a>
            </div>
          </div>
        </div>
        : null}
      {/* {pageState === SignUpPageStates.Verification ?
        <div className="w-full flex justify-start items-center h-screen overflow-y-auto flex-col">
          <div className="w-full text-white text-center font-bold mb-4 font-jakarta mt-2">
            <a href="https://www.thesuperbill.com">
              SuperBill
            </a>
          </div>
          <div className="w-3/4 justify-center px-10 py-8 rounded bg-white">
            <div className="font-jakarta text-base text-center font-semibold text-superbill-jacarta mb-4">
              We’ve sent a verification link to your email.
            </div>
            <div className="font-jakarta text-base text-center text-superbill-jacarta mb-5">
              Once your account is verified, you’ll be able to log in to your account.
            </div>
            <div className="w-full flex justify-center items-center overflow-y-auto">
              <button
                className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                onClick={() => {
                  navigate('./super-dial-sign-in')
                }}
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
        : null} */}
    </div>
  )
}
