import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Script } from 'types/types';
import { getSandboxCalls, getSandboxCallsResults, getScripts } from 'apis/robodialer';
import SimulateCallModal from './simulate-call/simulate-call-modal';
import SandboxCallsResultsTable from './sandbox-calls-results-table';
import SandboxCallsResultsData from './sandbox-calls-results-data';



const DevelopersSandbox: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [simulateCallOpenState, setSimulateCallOpenState] = useState<boolean>(false);
  const [scriptData, setScriptData] = useState<Array<Script> | null>(null);
  const [callsResults, setCallsResults] = useState<any | null>(null); //TBD
  const [resulttData, setResulttData] = useState<any | null>(null); //TBD
  const [selectedCallId, setSelectedCallId] = useState<string | null>(null);


  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      const { data, } = await getSandboxCallsResults(selectedCallId);
      setResulttData(data);
      setIsLoading(false)
    }
    if (selectedCallId) {
      fetch()
    }
    //getSandboxCalls
  }, [selectedCallId]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      const { data, } = await getScripts();
      setScriptData(data?.sort((item) => item.pms ? -1 : 1));
      setIsLoading(false)
      const response = await getSandboxCalls();
      if(response.httpCode === 200){
        setCallsResults(response?.data)
      }
    }
    if (!scriptData) {
      fetch()
    }
  }, []);

  const onBack = () => {
    setSelectedCallId(null);
    setResulttData(null);
  }
  return (
    <>
      {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
        < Loader />
      </div>) : null}
      {!isLoading ? (
        <>
          { selectedCallId ? 
            <div className='mt-5'>
              <SandboxCallsResultsData results={resulttData ?? {}} onBack={onBack} callId={selectedCallId}/>
            </div>
            :
            <>
              <div className='text-right text-superbill-jacarta text-sm mt-2 font-sans mb-5'>
              <span onClick={() => {setSimulateCallOpenState(true)}}
                className='cursor-pointer bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 ml-2'
              >
                Simulate a call
              </span>
            </div>
              <SandboxCallsResultsTable calls={callsResults ?? {}} setSelectedCallId={setSelectedCallId}/>
            </>
          }
        </>
      ) : null}
      <SimulateCallModal isOpen={simulateCallOpenState}  onClose={() => {
        setSimulateCallOpenState(false)
      }} scriptData={scriptData} 
      onSuccess={async () => {
        setIsLoading(true)
        setSimulateCallOpenState(false)
        const response = await getSandboxCalls();
        setIsLoading(false)
        if(response.httpCode === 200){
          setCallsResults(response?.data)
        }
      }} />
    </>
  );
};

export default DevelopersSandbox;