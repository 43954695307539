import React, { useState } from "react";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import Loader from "components/Loader";
import { DateTime } from "luxon";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyValueGrid from "./key-value-grid";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { paginationHelper, PaginationStates } from "utils/pagination";
import { QMBatchDataType } from 'types/types';
import { setBatchCancelled, setBatchHidden, setBatchPaused, downloadResults, downloadZipResults, downloadCallInputs, regeneratePdfs, regenerateCsvs } from 'apis/agent';

type BatchesTableProps = {
  dataLoading: boolean;
  batches: QMBatchDataType[] | null;
  batchData?: any;
  onExpand: (robodialerId: string, batchId: string, force?: boolean) => void;
};
export default function BatchesTable({
  dataLoading,
  batches,
  batchData,
  onExpand = () => {},
}: BatchesTableProps) {

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedKey, setSelectedKey] = useState<string | number | null>(null);

  const onCopy = (content: string | number) => {
      navigator.clipboard.writeText(content.toString());
      toast.success('Copied to clipboard', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
    }

  const cancelOrUncancelBatch = async (robodialerId: string, batchId: string, isCancelled: boolean) => {
    const data = {
        robodialerId: robodialerId,
        batchId: batchId,
        cancel: !isCancelled,
        };
    const response = await setBatchCancelled(data);
    if (response.httpCode === 200) {
        toast.success("Batch " + batchId + " " + (isCancelled ? "uncancelled" : "cancelled"), {
            iconTheme: {
              primary: '#4a43cd',
              secondary: '#ffffff',
            },
          }
        );
    } else {
        console.log(response);
        toast.error(response.httpCode + ' ' + response.data.error ?? "An error occurred cancelling/uncancelling batch " + batchId, {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
    }
  };

const pauseOrUnpauseBatch = async (robodialerId: string, batchId: string, isPaused: boolean) => {
    const data = {
        robodialerId: robodialerId,
        batchId: batchId,
        pause: !isPaused,
        };
    const response = await setBatchPaused(data);

    if (response.httpCode === 200) {
        toast.success("Batch " + batchId + " " + (isPaused ? "unpaused" : "paused"), {
            iconTheme: {
              primary: '#4a43cd',
              secondary: '#ffffff',
            },
          }
        );
    } else {
        console.log(response);
        toast.error(response.httpCode + ' ' + (response.data.error ?? "An error occurred pausing/unpausing batch " + batchId), {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
    }
    
  };

  const hideOrUnhideBatch = async (robodialerId: string, batchId: string, isHidden: boolean) => {
    const data = {
        robodialerId: robodialerId,
        batchId: batchId,
        hide: !isHidden,
        };
    const response = await setBatchHidden(data);
    if (response.httpCode === 200) {
        toast.success("Batch " + batchId + " " + (isHidden ? "unhidden" : "hidden"), {
            iconTheme: {
              primary: '#4a43cd',
              secondary: '#ffffff',
            },
          }
        );
    } else {
        console.log(response);
        toast.error(response.httpCode + ' ' + response.data.error ?? "An error occurred hiding/unhiding batch " + batchId, {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
    }
  };

  const regenerateBatchCSVs = async (robodialerId, batchId) => {
    console.log("regenerating csvs");
    let response = await regenerateCsvs(robodialerId, batchId);
    if( response.httpCode !== 200) {
      toast.error('Unable to regenerate', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }else{
      toast.success('Regenerated', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
    }
  }

  const regenerateBatchPDFs = async (robodialerId, batchId) => {
    const response = await regeneratePdfs(robodialerId, batchId);
    console.log(response);
    
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const blob = await response.data.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${batchId}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadResult = async (robodialerId, scriptId, batchId, type) => {
    let name = type ? 'success' : 'failed'
    if (!scriptId || !batchId) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const response = await downloadResults(robodialerId, scriptId, batchId, type);
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    const fileName = `${batchId}_${name}.csv`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadInputs = async (robodialerId, scriptId, batchId) => {
    const response = await downloadCallInputs(robodialerId, scriptId, batchId);
    if (response.httpCode !== 200) {
      toast.error('Unable to Download', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    };
    const data = response.data as { file?: any };
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement('a');
    const fileName = batchId + '_inputs.csv';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);
  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: batches ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 25,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });
  return (
    <>
      {(currentActiveResults ?? []).map((batch, index) => (
        <Accordion
          key={`${batch?.id ?? batch?.batchId}-${index}`}
          expanded={expanded === `panel-${index}`}
          className={`m-0 bg-white border-b border-superpay-soap`}
        >
          <AccordionSummary
          className="!p-0"
            expandIcon={
                <ExpandMoreIcon onClick={() => {
                  let panel = `panel-${index}`;
                  let robodialerId = batch?.robodialerId;
                  let batchId = batch?.batchId;
                  console.log('ExpandMoreIcon');
                  console.log(panel, robodialerId, batchId);
                  setExpanded(expanded !== panel ? panel : false)
                  onExpand(robodialerId, batchId);
                  /* handleChange(
                    `panel-${index}`,
                    batch?.robodialerId,
                    batch?.batchId
                  ) */
              }}/>
          }
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div className="w-full grid grid-cols-24">
              <div
                className={`flex items-center justify-center col-span-1 py-1 bg-white`}
              >
                {batch?.cancelled || batch?.hide || batch?.paused ? <>
                  <>
                    <Tooltip
                      content={
                        <div className="gap-2">
                          <div style={{ whiteSpace: "pre-line" }}>
                            Cancelled:{batch?.cancelled ? "Yes" : "No"}
                            {batch?.cancelled ? <div className="text-xs">
                              <div>cancelledBy {batch?.cancelledBy}</div>
                              <div>cancelledAt {batch?.cancelledAt?.et}</div>
                            </div>:null}
                          </div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            Paused:{batch?.paused ? "Yes" : "No"}
                            {batch?.paused ? <div className="text-xs">
                              <div>pausedBy {batch?.pausedBy}</div>
                              <div>pausedAt {batch?.pausedAt?.et}</div>
                            </div>:null}
                          </div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            Hidden:{batch?.hide ? "Yes" : "No"}
                            {batch?.hide ? <div className="text-xs">
                              <div>hiddenBy {batch?.hiddenBy}</div>
                              <div>hiddenAt {batch?.hiddenAt?.et}</div>
                            </div>:null}
                          </div>
                        </div>
                      }
                      className="max-w-[200px] bg-black py-1 px-2"
                    >
                        <div className="flex items-center gap-x-1">
                          <div className={`cursor-pointer px-2`}>
                            <InfoIcon className='min-w-[22px] child:fill-superbill-jacarta' />
                          </div>
                        </div>
                    </Tooltip>
                  </>
                </> : null}
              </div>
              <div
                className={`flex items-center justify-start col-span-2 py-1 bg-white`}
              >
                {
                  <div className="p-2 flex items-center justify-center text-center font-bold text-sm text-superbill-ultra-red bg-superbill-banner-red rounded-full">
                    {DateTime.fromJSDate(new Date(batch?.dueDate?.et)).toFormat(
                      "MMMM dd HH:mm"
                    )}
                  </div>
                }
              </div>
              <div
                className={`${
                  batch?.status === "completed" ? "font-bold" : "italic"
                } flex items-center justify-start col-span-2 py-1 bg-white px-2`}
              >
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  {batch?.status}
                </span>
              </div>
              <div className="flex items-center justify-start col-span-3 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                <Tooltip
                  content={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {batch?.robodialerId}
                    </span>
                  }
                  className="max-w-[200px] bg-black py-1 px-2"
                >
                  <div className="flex items-center gap-x-1">
                    <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.robodialerId)}>
                      <CopyIcon/>
                    </div>
                    {batch?.robodialerName ?? batch?.robodialerId}
                  </div>
                </Tooltip>
                </span>
              </div>
              <div className="flex items-center justify-start col-span-4 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.batchId)}>
                    <CopyIcon/>
                  </div>
                  {batch?.batchId}
                </span>
              </div>
              <div className="flex items-center justify-start col-span-4 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.scriptId)}>
                    <CopyIcon/>
                  </div>
                  {batch?.scriptId}
                </span>
              </div>
              <div
                className={`col-span-2 flex items-center justify-end py-1 bg-white px-2`}
              >
                {
                  <div className="p-2 h-fit flex items-center justify-end text-right font-bold text-sm text-superbill-indigo bg-superbill-lavender rounded-full">
                    {(typeof batch?.numSuccessful === "string"
                      ? parseInt(batch?.numSuccessful)
                      : batch?.numSuccessful) || 0}
                  </div>
                }
              </div>
              <div
                className={`col-span-2 flex items-center justify-end py-1 bg-white px-2`}
              >
                {
                  <div className="p-2 h-fit flex items-center justify-end text-right font-bold text-sm text-superbill-ultra-red bg-superbill-banner-red rounded-full">
                    {(typeof batch?.numFailed === "string"
                      ? parseInt(batch?.numFailed)
                      : batch?.numFailed) || 0}
                  </div>
                }
              </div>
              <div className="col-span-2 flex items-center justify-end py-1 bg-white px-2">
                <div className="text-sm flex items-center justify-end text-superbill-jacarta text-right font-jakarta">
                  {batch?.numCalls -
                    (((typeof batch?.numSuccessful === "string"
                      ? parseInt(batch?.numSuccessful)
                      : batch?.numSuccessful) || 0) +
                      ((typeof batch?.numFailed === "string"
                        ? parseInt(batch?.numFailed)
                        : batch?.numFailed) || 0))}
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-end py-1 bg-white px-2">
                <div className="text-sm flex items-center justify-end text-superbill-jacarta text-right font-jakarta">
                  {batch?.numCalls}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex items-center p-2 bg-superbill-lavender-medium rounded mb-2 justify-between">
              <div className="text-left text-superbill-mangosteen text-sm">
                <strong>PATH:</strong> Robodialers/{batch?.robodialerId}/batches/
                {batch?.batchId}
              </div>
              <div className="cursor-pointer hover-underline-animation text-sm w-fit" onClick={() => {
                //navigate() to /calls passing parameters as query
                navigate(`/agents-dashboard/tools/calls?robodialerId=${batch?.robodialerId}&batchId=${batch?.batchId}`)
              }}>All calls</div>
            </div>
            {dataLoading ? (
              <>
                <div className="w-full h-full flex justify-center items-center">
                  {" "}
                  <Loader />{" "}
                </div>
              </>
            ) : null}
            {!dataLoading ? (  
              <>
                <div className="w-full gap-x-4 grid grid-cols-10 justify-start items-start mb-5">
                  <div className=" col-span-3 border border-superpay-soap divide-y divide-superpay-soap rounded">
                    <div className="grid grid-cols-10 gap-4 bg-superbill-anti-flash-white-darken">
                      <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                        Status:
                      </span>
                      <span className="col-span-7 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                        Total
                      </span>
                    </div>
                    <KeyValueGrid
                      aspect="3-7"
                      icon={<span className="cursor-pointer">{">"}</span>}
                      action={(e, a) => {
                        setSelectedKey(a || null);
                      }}
                      data={{
                          ...Object.keys(batchData?.[batch?.batchId] || {}).reduce((acc, key) => {
                            const currentData = batchData[batch?.batchId][key];
                            
                            // Log the key and its value (optional)
                            console.log(`Processing key: ${key}, value:`, currentData);
                            
                            // Assign length or a default value if length is undefined
                            acc[key] = currentData?.length || 0;
                            
                            return acc;
                          }, {}),
                      }}
                    />
                  </div>
                  <div className="col-span-7 w-full">
                    {selectedKey &&
                    batchData[batch?.batchId] &&
                    batchData[batch?.batchId][selectedKey] ? (
                      <div className="max-h-[250px]  overflow-y-auto">
                        <div className="w-full border border-superpay-soap divide-y divide-superpay-soap rounded">
                          <div className="grid grid-cols-10 gap-4 bg-superbill-anti-flash-white-darken">
                            <span className="col-span-2 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                              CallId
                            </span>
                            <span className="col-span-4 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                              Detail
                            </span>
                            <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                            </span>
                          </div>
                          {batchData[batch?.batchId][selectedKey].map((obj, index) => {
                              // Determine if the item is a tuple or a string
                              const isTuple = Array.isArray(obj) && obj.length === 2;
                              const callId = isTuple ? obj[0] : obj;  // If tuple, use the first element, else the string itself
                              const detail = isTuple ? obj[1] : '-';  // If tuple, use the second element, else default to '-'

                              return (
                                  <div key={`${selectedKey}-${index}`} className="grid grid-cols-10 gap-4 bg-white">
                                  {/* First column: Call ID */}
                                  <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                                      {callId}
                                  </span>

                                  {/* Second column: Detail (if available) */}
                                  <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                                      {detail}
                                  </span>

                                  <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right" onClick={() => {
                                  //navigate(`/agents-dashboard/tools/calls?robodialerId=${batch?.robodialerId}&batchId=${batch?.batchId}&callId=${callId}`)
                                  window.open(`/agents-dashboard/audit?robodialerId=${batch?.robodialerId}&callId=${callId}`, '_blank');
                                  //navigate(`/agents-dashboard/audit?robodialerId=${batch?.robodialerId}&callId=${callId}`)
                                }}>
                                  <div className="flex items-center justify-end"><EyeIcon className="h-[14px] cursor-pointer"/></div>
                                </span>
                                  </div>
                              );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div><span className="text-superbill-jacarta text-sm mt-5">Actions</span></div>
                <div className=" border border-b border-superpay-soap my-2"/>
                <div className="flex items-center gap-x-4 mb-4">
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {cancelOrUncancelBatch(batch?.robodialerId, batch?.batchId, batch?.cancelled ?? false)}}
                        >{batch?.cancelled ? "Uncancel Batch" : "Cancel Batch"}
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {hideOrUnhideBatch(batch?.robodialerId, batch?.batchId, batch?.hide ?? false)}}
                        >{batch?.hide ? "Unhide Batch" : "Hide Batch"}
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {pauseOrUnpauseBatch(batch?.robodialerId, batch?.batchId, batch?.paused ?? false)}}
                        >{batch?.paused ? "Resume Batch" : "Pause Batch"}
                        </button>
                    </div>
                </div>
                <div><span className="text-superbill-jacarta text-sm mt-5">Files</span></div>
                <div className=" border border-b border-superpay-soap my-2"/>
                <div className="flex items-center gap-x-4 mb-4">
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {regenerateBatchCSVs(batch?.robodialerId, batch?.batchId)}}>
                          <span>Regenerate CSVs</span>
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {regenerateBatchPDFs(batch?.robodialerId, batch?.batchId)}}>
                          <span>Regenerate PDFs</span>
                        </button>
                    </div>
                </div>

                <div><span className="text-superbill-jacarta text-sm mt-5">Download</span></div>
                <div className=" border border-b border-superpay-soap my-2"/>
                <div className="flex items-center gap-x-4 mb-4">
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {downloadResult(batch?.robodialerId, batch?.scriptId, batch?.batchId, true)}}
                        >
                          <span>Download successful calls</span>
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {downloadResult(batch?.robodialerId, batch?.scriptId, batch?.batchId, false)}}>
                          <span>Download incorrect / insufficient data calls</span>
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                        type="button"
                        className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-8 rounded-full ease-in-out duration-300"
                        onClick={() => {downloadInputs(batch?.robodialerId, batch?.scriptId, batch?.batchId)}}>
                          <span>Download Inputs</span>
                        </button>
                    </div>
                </div>
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="flex items-center justify-end flex-row py-3 px-2 font-sans">
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.backward)}
        >
          {currentLowerIndex === 0 ? <></> : <BackIcon />}
        </div>
        <div className="mx-2">
          <strong>
            {currentLowerIndex + 1} to {currentUpperIndex + 1}
          </strong>{" "}
          of {batches?.length}
        </div>
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.forward)}
        >
          {currentUpperIndex + 1 === batches?.length ? <></> : <ForwardIcon />}
        </div>
      </div>
    </>
  );
}
