import ForgotPasswordForm from './forgot-password-in-form';
import { ReactComponent as Back } from 'assets/back-arrow-indigo.svg';


export default function ScriptBuilderForgotPassword({ agent = false }: { agent?: boolean }){



    return (
      <div className="bg-superdial-background-full bg-cover h-full text-superbill-jacarta">
      <div className="w-full flex justify-center items-center h-screen" >
      <div className=''>
        <div className="w-full text-white text-center font-bold mb-4 font-jakarta">
        <a href="https://www.thesuperbill.com">
          SuperBill
        </a>
        </div>

        <div className="w-full justify-center p-16 rounded bg-white relative">
          <div className="absolute top-2 left-2 font-jakarta text-superbill-indigo text-sm hover-underline-animation flex items-center">
            <Back className="mr-1"/>
            <a href={'/super-dial-sign-in'} >
              Back to Sign In
            </a>
            </div>
          <div className="font-jakarta text-2xl font-bold text-superbill-jacarta">
            Reset Password
          </div>
          <div className="font-jakarta text-superbill-slate-grey text-sm">
            Enter your email and we'll send you a password reset link
          </div>
          <ForgotPasswordForm agent={agent}/>
        </div>
        <div className="w-full justify-center text-center text-white font-jakarta">
          <div className="text-superbill-soap mb-4 mt-6 text-sm ">
            Want to get started with SuperDial?
          </div>
          <div>
          <a href='https://calendly.com/sam-schwager/outbound-call-automation' className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/30 text-white font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300">Schedule Consultation</a>
          </div>
        </div>
        </div>
      </div>
    </div>
    )
  }
