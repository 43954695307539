import { PopupModal } from 'react-calendly';

type CalendlyModalProps = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const CalendlyModal = ({ isOpen, setIsOpen }: CalendlyModalProps) => {
  return (
    <PopupModal
      url='https://calendly.com/sam-lj4/superdial-payer-phone-call-automation'
      onModalClose={() => setIsOpen(false)}
      open={isOpen}
      rootElement={document.getElementById('root')!}
    />
  );
};

export default CalendlyModal;