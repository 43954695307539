import React from "react";
import { Oval } from "react-loader-spinner";

const DEFAULT_LOADER_PROPS = {
  size: 110,
};

export interface LoaderProps {
  size?: number;
}

export default function Loader(props: LoaderProps = DEFAULT_LOADER_PROPS) {
  return (
    <Oval
      ariaLabel='oval-loading'
      color="#4a43cd"
      secondaryColor="#4A7DFF"
      height={props.size}
      width={props.size}
    />
  );
}
