import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select, { components, OptionProps, SingleValue } from "react-select";
import { Script } from 'types/types';
import { ReactComponent as CSVIcon } from 'assets/csv.svg';
import { ReactComponent as GridIcon } from 'assets/grid.svg';
import { ReactComponent as Unchecked } from 'assets/checkbox.svg';
import { ReactComponent as Checked } from 'assets/checkbox-checked.svg';

interface FormData {
  script: Script | null;
}

type SelectionPageProps = {
  onClose: () => void;
  onNext: () => void;
  scriptData: Array<Script> | null;
  setSelectedScript?: React.Dispatch<React.SetStateAction<string | null>>;
  selectedScript?: string | null;
  setSelectedScriptInput: React.Dispatch<React.SetStateAction<string | null>>;
  selectedScriptInput: string | null;
}
const SelectionPage = ({ onClose, onNext, scriptData, setSelectedScript, selectedScript, setSelectedScriptInput, selectedScriptInput }: SelectionPageProps) => {

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormData>();


  const handleSelectChange = (selectedOption: { value: string, label: string } | null) => {
    //setSelectedScript(selectedOption?.value!)
  };

  const onSubmit = (formData: FormData) => {
    console.log(formData);
  };
  const selectOptions = scriptData
    ? scriptData?.map((script) => ({ value: script.id, label: script.name }))
    : [];


  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        {/* <div className='text-superbill-jacarta text-sm font-bold'>Select the script you’d like to run</div> */}
        {/* <form onSubmit={handleSubmit(onSubmit)} >
          <label className='text-sm text-superbill-jacarta font-bold' htmlFor="script">Select the script you’d like to run</label>
          <Select
            className='bg-white border-1 border-superbill-soap block w-full focus:ring-superbill-indigo focus:border-superbill-indigo sm:text-sm rounded placeholder:text-sm text-superbill-jacarta mt-2'
            id="script"
            {...register('script')}
            placeholder="Select a script"
            options={selectOptions}
            onChange={handleSelectChange}
            isSearchable={false}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': {borderColor: '#D0D3F2'},
                boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
                borderColor: state.isFocused ? '#4a43cd' : '#D0D3F2',
                zIndex: 11
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                color: state.isFocused ? '#4a43cd' : '#38395B',
                ':active': {
                  ...baseStyles[':active'],
                  backgroundColor: '#D0D3F2',
                },
              })
            }}
          />
          {errors.script && <span className="text-red-500">Select a script</span>}

        </form> */}
        <div className='mt-4 font-bold text-superbill-jacarta text-sm'>How would you like to provide the script inputs?</div>
        <div className="flex items-center mt-2 ">

          <div
            className={`h-48 mx-2 border-superbill-soap border w-full flex flex-col  items-center my-2 rounded cursor-pointer transition duration-500 bg-white ${selectedScriptInput === 'csv'
              ? 'border-superbill-indigo'
              : 'hover:drop-shadow-md'
              }`}
              onClick={() => {setSelectedScriptInput('csv')}}
          >
            <div className="flex flex-col justify-between w-full h-full py-2">
              <div className='flex flex-col items-center justify-start px-4'>
                <div className='mb-3 mt-4 flex items-center justify-center'><CSVIcon/></div>
                <div className='text-center text-sm text-superbill-jacarta '>
                  Upload a CSV file
                </div>
                <div className='text-center text-sm text-superpay-light-text-gray mb-3'>
                  Upload a file with required column headers
                </div>
                {selectedScriptInput === 'csv'
              ? <Checked />
              : <Unchecked />
            }
              </div>
            </div>
          </div>
        <div
          className={`h-48 mx-2 border-superbill-soap border w-full flex flex-col  items-center my-2 rounded cursor-pointer transition duration-500 bg-white ${selectedScriptInput === 'table'
            ? 'border-superbill-indigo'
            : 'hover:drop-shadow-md'
            }`}
            onClick={() => {setSelectedScriptInput('table')}}
        >
          <div className="flex flex-col justify-between w-full h-full py-2">
            <div className='flex flex-col items-center justify-start px-4'>
              <div className='mb-3 mt-4 flex items-center justify-center'><GridIcon/></div>
              <div className='text-center text-sm text-superbill-jacarta '>
                Manually Input Information
              </div>
              <div className='text-center text-sm text-superpay-light-text-gray mb-3'>
                Fill out required information in our online table
              </div>
              {selectedScriptInput === 'table'
              ? <Checked />
              : <Unchecked />
            }
            </div>
        </div>
      </div>
    </div>

      </div >
  <div className="flex flex-row justify-between px-4 py-3">
    <button
      className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
      onClick={onClose}
    >
      Close
    </button>
    <button
      disabled={/* !selectedScript || */ !selectedScriptInput}
      className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
      onClick={onNext}
    >
      Next
    </button>
  </div>
    </div >
  );
};

export default SelectionPage;
