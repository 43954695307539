import { DateTime } from 'luxon';
import { logCallEvents } from 'apis/agent';

const logTelnyxEvent = (eventName, e:any = {}, email) => {
  if (e?.type === "telnyx_rtc.ping" || e?.method == 'telnyx_rtc.ping') return;
  
  let call= !!e.call
  let callState= e?.call?.state ?? e?.callState ?? ''
  let sessionid = ''
  let telnyxCallControlId = ''
  let telnyxLegId = ''
  let telnyxSessionId = ''
  if (e?.call){
    sessionid = e?.call?.session?.sessionid
    telnyxCallControlId = e?.call?.options?.telnyxCallControlId
    telnyxLegId = e?.call?.options?.telnyxLegId
    telnyxSessionId = e?.call?.options?.telnyxSessionId
    delete e.call
  }
  if (e?.params){
    e.params.dialogParams = JSON.stringify(e.params.dialogParams ?? {})
    delete e.params.variables
  }
  let eventData = {}
  try {
    eventData = JSON.stringify(e)
  } catch (error) {
    console.error('Error parsing event data', error);
  }
  logCallEvents({
    ev_type: eventName || '',
    type: e?.type || '',
    method: e?.method || '',
    email: email || '',
    agentDate: DateTime.now().toISO(),
    call,
    sessionid,
    telnyxCallControlId,
    telnyxLegId,
    telnyxSessionId,
    callState,
    eventData,
  });
};

export const handleTelnyxEvent = (name, e, email) => {
  //console.trace(name, e, e.call, typeof e.call);
  if (typeof e.custom === 'undefined') {
    e.custom = false
  }
  logTelnyxEvent(name, e, email);
}