import React, { useEffect, useRef } from 'react';

interface StyledQuestionInputProps {
  defaultValue?: string;
  allowedString: string[];

  register: any;
  index: number | string;
  iKey: number | string;
  error?: string | undefined;
  onChange?: (e) => void;
  disabled?:boolean;

  className?:string;

}

const StyledQuestionInput: React.FC<StyledQuestionInputProps> = ({allowedString, defaultValue, iKey, register, error, index, onChange, disabled=false, className='w-full' }) => {
  const divRef = useRef<HTMLDivElement>(null);

  const parse = (text: string): string => {
    const regex = /{([^}]*)}/g;
    return text.replace(regex, (match, content) => {
      const isMatch = allowedString.some(word => content.includes(word));
      const colorClass = isMatch ? 'bg-superbill-lavender rounded text-superbill-indigo' : 'bg-superbill-banner-red rounded text-superbill-ultra-red';
      return `<span class="${colorClass}">${match}</span>`;
    });
  };

  const getCaretCharacterOffsetWithin = (element: HTMLElement): number => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return 0;

    let offset = 0;
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    offset = preCaretRange.toString().length;
    return offset;
  };

  const setCaretPosition = (chars: number) => {
    const range = document.createRange();
    const selection = window.getSelection();
    let charCount = 0;

    if (!divRef.current || !selection) return;

    const setPosition = (node: Node) => {
      if (charCount >= chars) return true;
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = (node as Text).length;
        if (charCount + textLength >= chars) {
          range.setStart(node, chars - charCount);
          range.collapse(true);
          return true;
        }
        charCount += textLength;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (setPosition(node.childNodes[i])) return true;
        }
      }
      return false;
    };

    setPosition(divRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleInput = () => {
    if (divRef.current) {
      const caretPosition = getCaretCharacterOffsetWithin(divRef.current);

      // Parse and replace HTML content with styled spans
      const parsedHTML = parse(divRef.current.innerText);
      divRef.current.innerHTML = parsedHTML;
      // Restore cursor position after setting new HTML
      setCaretPosition(caretPosition);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = parse(defaultValue ?? '');
    }
  }, [defaultValue]);


  return (
    <div
      ref={divRef}
      contentEditable
      suppressContentEditableWarning
      key={iKey}
      onInput={handleInput}
      onBlur={() => {
        if (onChange && divRef?.current) {
          onChange({target: {name:`${index}.question`, value:parse(divRef.current.innerText)}})
        }
      }}
      className={`${className} styled-question-input rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none text-base border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]`}
    />
  );
};

export default StyledQuestionInput;