import React, { useEffect, useState } from "react";
import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { RobodialerComponentsResponseType } from "types/responses";
import { AuditIssuesType } from "types/audit";
import { fetchRobodialerComponents, submitAuditIssues } from "apis/audit";
import AndyIssuesForm from "./andy-issues-form";
import { toast } from "react-hot-toast";

type AndyIssuesFormWrapperProps = {
  issues: AuditIssuesType[];
  allIssues: AuditIssuesType[];
  setAllIssues: React.Dispatch<React.SetStateAction<AuditIssuesType[]>>;
  robodialer_id?: string;
  call_id?: string;
  attempt_id?: string;
};

const AndyIssuesFormWrapper: React.FC<AndyIssuesFormWrapperProps> = ({
  issues,
  allIssues,
  setAllIssues,
  robodialer_id = null,
  call_id = null,
  attempt_id = null,
}) => {
  const [components, setComponents] =
    useState<RobodialerComponentsResponseType | null>(null);
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (issues && issues.length > 0) {
      const deepCopiedIssues = issues?.map((issue) => ({
        ...issue,
      }));
      setAllIssues(deepCopiedIssues);
    }
  }, [issues]);

  useEffect(() => {
    if (!components) {
      const fetch = async () => {
        const response = await fetchRobodialerComponents();
        if (response.httpCode !== 200) {
          console.error("Error fetching components");
        } else {
          setComponents(response.data);
        }
      };
      fetch();
    }
  }, []);

  const handleAddIssue = () => {
    const newIssue: AuditIssuesType = {
      componentId: "",
      issueCategoryId: "",
      issueAndCorrectBehaviorDescription: "",
    };
    setAllIssues((prevIssues) => [...prevIssues, newIssue]);
  };

  const handleRemoveIssue = (index: number) => {
    let values = allIssues.map((issue) => ({ ...issue }));
    values.splice(index, 1);
    setAllIssues(values);
  };

  const handleChange = (
    issueId: string,
    field: keyof AuditIssuesType,
    value: any
  ) => {
    // deep copy all values
    const values = allIssues.map((issue) => ({ ...issue }));
    values[Number(issueId)][field] = value;
    if (field === "componentId") {
      values[Number(issueId)].issueCategoryId = "";
    }
    setAllIssues(values);
  };

  return (
    <>
      {components && allIssues ? (
        <>
          <div>
            <div className='w-full flex items-center justify-end'>
              <button
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={handleAddIssue}
              >
                Add New Issue
              </button>
            </div>
            {allIssues.map((issue, index) => (
              <div
                className="my-5"
                key={`container-issue-form-${issue.componentId}-${index}`}
              >
                <AndyIssuesForm
                  key={`issue-form-${issue.componentId}-${index}`}
                  components={components}
                  issueId={String(index)}
                  values={issue}
                  handleChange={handleChange}
                  onRemove={handleRemoveIssue}
                />
              </div>
            ))}
          </div>

          {robodialer_id && call_id && attempt_id ?
            <div className="w-full flex items-center justify-center">
              <button
                type="button"
                onClick={async () => {
                  setSubmitIsLoading(true);
                  let {httpCode, data} = await submitAuditIssues({
                    robodialer_id,
                    call_id,
                    attempt_id,
                    issues:allIssues
                  });
                  setSubmitIsLoading(false);

                  if (httpCode === 200) {
                    toast.success('Issues submitted successfully',{
                      iconTheme: {
                        primary: '#4a43cd',
                        secondary: '#ffffff',
                      },
                    });
                  }else{
                    toast.error(httpCode + ': Unable to submit issues: ' + data?.error ?? '', {
                      iconTheme: {
                        primary: '#F36F82',
                        secondary: '#ffffff',
                        },
                    });
                  }

                }}
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
              >
                {submitIsLoading ? <Spinner /> : "Submit issues"}
              </button>
            </div>
          : null}
        </>
      ) : null}
    </>
  );
};

export default AndyIssuesFormWrapper;
