import React, { useEffect, useState } from "react";
import { downloadInputsCsv } from 'utils/download';
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { createScript, getScriptInputs, getScriptsTypes } from "../../apis/robodialer";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { ReactSelectOptions } from "types/types";

// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
  name: string;
  scriptType: string;
  oScriptType: string;
}


// components
export default function NewScriptModal({ modalIsOpen, setModalIsOpen }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [fetchingInputs, setFetchingInputs] = useState<boolean>(false);
  const [inputs, setInputs] = useState({});
  const [options, setOptions] = useState<Array<ReactSelectOptions>>([]);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [selectedScriptType, setSelectedScriptType] = React.useState<string | null>(null);
  const [selectedScriptTypeTemp, setSelectedScriptTypeTemp] = React.useState<string | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const handleTypeChange = (selectedOption: { value: string, label: string } | null) => {
    //let type = selectedOption?.value !== 'Other' ? selectedOption?.value! : (watch('oScriptType') || null)
    let type = selectedOption?.value!
    setSelectedScriptType(type);
    setValue('oScriptType', '');
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedScriptType(null);
    setSelectedScriptTypeTemp(null);
  };
  const onSubmit = async (data: FormValues) => {
    //setLoading(true)
    const { name } = data;
    let response = await createScript(name, [], selectedScriptType?.replaceAll(' ', '-').toLocaleLowerCase()!)
    setLoading(false)

    let error: string | null = null;
    if (response.httpCode !== 200) {
      error = "Oops! Something went wrong. If the problem persist please reach out to support@thesuperbill.com."
    }
    if (error) {
      setSubmitError(error)
      return;
    }
    window.location.assign('/super-dial?id=' + response.data.id)

  };


  useEffect(() => {
    console.log(selectedScriptType);
    
    const fetch = async () => {
      setFetchingInputs(true)
      const { httpCode, data } = await getScriptInputs(selectedScriptType?.replaceAll(' ', '-').toLocaleLowerCase())
      if (httpCode === 200) {
        setFetchingInputs(false)
        console.log(data);
        setInputs(data)
      }
    }
    if(selectedScriptType){
      fetch();
    }
    /* if (["HIPAA Right of Access Request", "Authorization Approval Notification", "Claim Status", "Enrollment Application Status", "VOB", "Other", "Provider Data", "Prior Auth Status", "Network Status", "Prescription Status", "Prior Auth Request", "Enrollment Application Submission"].includes(selectedScriptType!)) {
    } else {
      setInputs({})
    } */

  }, [selectedScriptType])

  useEffect(() => {
    
    const fetch = async () => {
      setFetchingInputs(true)
      const { httpCode, data } = await getScriptsTypes()
      if (httpCode === 200) {
        setFetchingInputs(false)
        console.log(data.scripts.map( e => {
          return {'label': e.name, 'value': e.id}
        }));
        
        setOptions(data.scripts.map( e => {
          return {'label': e.name, 'value': e.id}
        }))
      }
    }
    if(modalIsOpen){
      fetch();
    }
  }, [modalIsOpen])

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            New script
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full bg-white rounded">
              <div className=' mt-1 w-full'>
                <label htmlFor="name" className="block text-superbill-jacarta font-bold text-sm mb-2">
                  Script Name
                </label>
                <input
                  {...register('name', {
                    required: 'This field is required',
                  })}
                  id="name"
                  type="name"
                  className={`${errors.name
                    ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                    : 'border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo'
                    } w-full py-2 px-3 bg-white border block w-full sm:text-sm rounded placeholder:text-sm text-superbill-jacarta`}
                />
                {errors.name && (
                  <span className="text-red-500 text-sm font-semibold mt-2 block">
                    {errors.name.message}
                  </span>
                )}
              </div>
              <div className='gap-2 grid grid-cols-1 md:grid-cols-2 mt-3'>
                <div>
                  <label className='text-sm text-superbill-jacarta font-bold' htmlFor="scriptType">Script Type</label>
                  <Select
                    className='bg-white border-1 border-superbill-soap block w-full  focus:ring focus:ring-superbill-indigo focus:ring-indigo-200 focus:ring-opacity-50  focus:border-superbill-indigo sm:text-sm rounded placeholder:text-sm text-superbill-jacarta hover:border-superbill-indigo mt-1'
                    id="scriptType"
                    {...register('scriptType')}

                    placeholder="Select a script type"
                    options={options}
                    onChange={handleTypeChange}
                    isSearchable={false}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#4a43cd' : '#D0D3F2',
                        zIndex: 11
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                        color: state.isFocused ? '#4a43cd' : '#38395B',
                        ':active': {
                          ...baseStyles[':active'],
                          backgroundColor: '#D0D3F2',
                        },
                      })
                    }}
                  />
                </div>

                {/* {!(["Claim Status",
                  "Enrollment Application Status",
                  "VOB"].includes(selectedScriptTypeTemp ?? '')) ?
                  <div>
                    <div className='font-bold mb-1 text-superbill-jacarta font-sans text-sm '>
                      Type
                    </div>
                    <input
                      className='mt-1 py-2 px-3 bg-white border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo w-full sm:text-sm rounded placeholder:text-sm text-superbill-jacarta'
                      type='text'
                      {...register('oScriptType')}
                      onChange={(e) => {
                        setValue('oScriptType', e.target.value);
                        setSelectedScriptType(e.target.value);
                        setSelectedScriptTypeTemp(e.target.value);
                      }}
                    />
                  </div> : null
                } */}
              </div>
              {selectedScriptType ? <div className='flex justify-between items-center w-full h-fit rounded bg-superbill-ghost-white p-2 mt-3 '>
                <div className="flex justify-start items-center">
                  <div className='flex justify-start pl-2 items-center'>
                    <InfoIcon className='min-w-[22px]' />
                  </div>
                  <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
                    Need an example CSV file?
                    <button type='button' onClick={(e)=>{
                      e.preventDefault()
                      const requiredInputs = Object.fromEntries(Object.entries(inputs as Record<string, any>).filter(([key, value]) => value.required));
                      downloadInputsCsv(requiredInputs)
                      }} className="ml-2 text-superbill-ultramarine text-sm font-sans font-bold hover-underline-animation cursor-pointer" >
                      Download our template
                    </button>
                  </div>
                </div>
              </div> : null}
              {Object.keys(inputs).length || fetchingInputs ?
                <div className='w-full h-fit rounded bg-superbill-ghost-white p-4 mt-3'>
                  {!fetchingInputs ?
                    <>
                      <div className="text-superbill-jacarta text-sm">When you place calls, you must provide us with the following information as <strong>column headers</strong></div>
                      <div className="px-5 flex justify-between mt-3">
                        <div className="flex-col flex justify-start items-start">
                          <div className="text-superbill-jacarta text-sm font-bold mb-2">Required</div>
                          <ul className="list-disc">
                            {Object.keys(inputs).map((key) => {
                              if (inputs[key] && inputs[key].required && inputs[key].required === true && key) {
                                return (
                                  <li className="text-superbill-jacarta text-sm">{key}</li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                        <div className="flex-col flex justify-start items-start">
                          <div className="text-superbill-jacarta text-sm font-bold mb-2">Optional</div>
                          <ul className="list-disc">
                            {Object.keys(inputs).map((key) => {
                              if (key && inputs[key] && !inputs[key].required) {
                                return (
                                  <li className="text-superbill-jacarta text-sm">{key}</li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    </>
                    : <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>}

                </div>
                : null}

              {submitError && (
                <span className="text-red-500 text-sm font-semibold mt-2 block">
                  {submitError}
                </span>
              )}
            </form>
            {/* delete this form ^ and your content goes here */}
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setModalIsOpen(false) }}
            >
              Close
            </button>
            
            <button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || !isValid || !selectedScriptType || fetchingInputs}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              {isLoading ? <Spinner /> : <>Create</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
