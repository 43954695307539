import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./authProvider";
import ScriptBuilderSignUp from "../script-builder/Sign-up";
import ScriptBuilderSignin from "../script-builder/Signin";
import ScriptBuilderForgotPassword from "../script-builder/ForgotPassword";
import ForgotPassword from "../sign-in/passwordForgot";
import SigninProvider from "../sign-in/SigninProvider";
import Signin from "../sign-in/Signin";
//import BillUploadPage from "../bill-review/bill-upload";

function PublicRoutes() {
  const { data } = useAuth();

  useEffect(() => {
    console.log('PublicRoutes', data);
  }, [])

  return !data?.user ? (
    <Routes>
      {/* <Route path="/bill-review" element={<BillUploadPage />} /> */}
      <Route path="/super-dial-sign-in" element={<ScriptBuilderSignin />} />
      <Route path="/super-dial-sign-up" element={<ScriptBuilderSignUp />} />
      <Route path="/super-dial-forgot-password" element={<ScriptBuilderForgotPassword />} />
      <Route path="/agents-forgot-password" element={<ScriptBuilderForgotPassword agent={true} />} />
      <Route path="/agents-sign-in" element={<ScriptBuilderSignin agent={true} />} />
      <Route path="/agents-sign-up" element={<ScriptBuilderSignUp agent={true}/>} />
      {/* <Route path="/sign-in" element={<Signin />} />
      <Route path="/forgot-password" element={<ForgotPassword userType={'user'} />} />
      <Route path="/provider-sign-in" element={<SigninProvider />} />
      <Route path="/provider-forgot-password" element={<ForgotPassword userType={'provider'} />} /> */}
      <Route path='*' element={<Navigate replace to='/super-dial-sign-in' />} />
    </Routes>
  ) : (
    <>
      <Navigate replace to='/dashboard' />
    </>

  );
}
export default PublicRoutes;
