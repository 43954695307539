import React, { useState, useEffect, useRef, useMemo } from "react";
import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { DateTime } from "luxon";
import Loader from "components/Loader";
import { toast, Toaster } from "react-hot-toast";
import { getBatchCalls, getBatchData, getBatches } from "../../apis/agent";
import BatchesTable from "./components/batches-table";
import { useLocation } from "react-router-dom";
import { CallsSettingsType, CallsType } from "../../types/types";
import CallsTable from "./components/calls-table";
import DraggableModal from "./components/draggable-modal";
export type Batch = {
  dueDate: {
    et: Date;
    timestamp: number;
  };
  createdAt: Date;
  status: string;
  source: string;
  completed: boolean;
  robodialerId: string;
  batchId: string;
  scriptId: string;
  numSuccessful: number;
  numFailed: number;
  numCalls: number;
};

const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};
export default function QMCallsPage() {
  const query = useQuery();
  /* const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null); */
  const robodialerIdRef = useRef<HTMLInputElement>(null);
  const batchIdRef = useRef<HTMLInputElement>(null);
  const callIdRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (robodialerIdRef.current)
      robodialerIdRef.current.value = query.get("robodialerId") || "";
    if (batchIdRef.current)
      batchIdRef.current.value = query.get("batchId") || "";
    if (callIdRef.current) callIdRef.current.value = query.get("callId") || "";
    if (statusRef.current) statusRef.current.value = query.get("status") || "";

    if(query.get("robodialerId") && query.get("batchId")){
      fetchBatchCalls(query.get("robodialerId"), query.get("batchId"));
    }
  }, [query]);

  const handleSearch = () => {
    const robodialerId = robodialerIdRef.current?.value;
    const batchId = batchIdRef.current?.value;
    const callId = callIdRef.current?.value;
    const status = statusRef.current?.value;
    if(robodialerId && batchId){
      fetchBatchCalls(robodialerId, batchId);
    }
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [callsData, setCallsData] = useState<CallsType[] | null>(null);
  
  const [modals, setModals] = useState<{ id: number; isOpen: boolean; title: string, content: React.ReactNode }[]>([]);

  const openModal = (title, content) => {
    setModals((prev) => [
      ...prev,
      { id: Date.now(), isOpen: true, title, content }
    ]);
  };

  const closeModal = (id: number) => {
    setModals((prev) => prev.map((modal) => modal.id === id ? { ...modal, isOpen: false } : modal));
  };

  const fetchBatchCalls = async (robodialerId, batchId) => {
    setIsSearching(true);
    const response = await getBatchCalls(robodialerId, batchId);
    setIsSearching(false);
    console.log("response fetchBatchCalls", response.data);
    if (response.httpCode === 200) {
      setCallsData(response.data.data)
    } else {
      toast.error(
        response.httpCode +
          " Unable to load transcript! Try again:" +
          response.msg,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    }
  };

  useEffect(() => {
    //fetchUpcomingBatches();
    //fetchBatch("3rRiN5wuqjFGcJbERjbu", "20240528-3SN2dxrBsoDwcpJHqBDf");
    //fetchBatchCalls("3rRiN5wuqjFGcJbERjbu", "20240528-3SN2dxrBsoDwcpJHqBDf");
  }, []);


  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className="mx-4 mt-5 mb-20">
          <div className="flex items-center justify-start gap-x-2">
            <div className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
              Batches
            </div>
            {
              <div className="font-jakarta text-superbill-slate-grey text-xl mb-4">
                <>{DateTime.fromJSDate(new Date()).toFormat("MMMM dd")}</>
              </div>
            }
          </div>
          <div className="flex items-end justify-start gap-x-2 m-4 w-full">
            {/* <div className="w-2/12">
              <label className="block text-gray-700">From:</label>
              <input
                type="date"
                ref={startDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div>
            <div className="w-2/12">
              <label className="block text-gray-700">To:</label>
              <input
                type="date"
                ref={endDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div> */}
            <div className="w-3/12">
              <label className="block text-gray-700">Robodialer ID:</label>
              <input
                type="text"
                ref={robodialerIdRef}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="w-3/12">
              <label className="block text-gray-700">Batch ID:</label>
              <input
                type="text"
                ref={batchIdRef}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            {/* <div className="w-3/12">
              <label className="block text-gray-700">Call ID:</label>
              <input
                type="text"
                ref={callIdRef}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div> */}
            {/* <div className="w-2/12">
              <label className="block text-gray-700">Status:</label>
              <select
                ref={statusRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              >
                <option value="">All</option>
                <option value="processing">Processing</option>
                <option value="completed">Completed</option>
              </select>
            </div> */}
            <div className=" w-1/12 flex items-center justify-center">
              <button
                className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={handleSearch}
                disabled={isSearching}
              >
                {isSearching ? <Spinner /> : <>Search</>}
              </button>
            </div>
          </div>

          <div className="border border-superpay-soap rounded">
            <CallsTable createModal={openModal} calls={callsData} />
          </div>
          
          <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            {modals.map((modal) => (
              <DraggableModal
                key={modal.id}
                isOpen={modal.isOpen}
                onClose={() => closeModal(modal.id)}
                title={modal.title}
              >
                <div className="p-4 overflow-y-auto">
                  {modal.content}
                </div>
              </DraggableModal>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}
