import React, { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import Loader from "components/Loader";
import { getAgentsResponseType, getRobodialersResponseType } from "types/types";
import { createAgent, createRobodialer, getRobodialers, getAgents } from "apis/agent";
import RobodialersAdminTable from "./components/robodialers-table";
import AddRobodialerModal from "./components/add-robodialer-modal";
import AddAgentModal from "./components/invite-agents-modal";
import AgentsAdminTable from "./components/agents-table";

export default function AdminPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState<boolean>(false);
  const [robodialersData, setRobodialersData] =
  useState<null | getRobodialersResponseType>(null);
  const [agentsData, setAgentsData] =
  useState<null | getAgentsResponseType>(null);

  const fetchRobodialers = async () => {
    setLoading(true)
    const { data, httpCode } = await getRobodialers();
    setLoading(false)
    if(httpCode !== 200){
      toast.error(httpCode + ' Unable to get Robodialers: ' + data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
          },
      });
    }else{
      setRobodialersData(data);
    }

  };
  const fetchAgents = async () => {
    setLoading(true)
    const { data, httpCode } = await getAgents();
    setLoading(false)
    if(httpCode !== 200){
      toast.error(httpCode + ' Unable to get Agents: ' + data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
          },
      });
    }else{
      setAgentsData(data);
    }

  };
  
  useEffect(() => {
    fetchRobodialers();
    fetchAgents();
  }, []);

  const addRobodialer = async (robo_data) => {
    const {httpCode, data} = await createRobodialer(robo_data);
    if(httpCode === 200){
      toast.success('Robodialer created successfully: '+ data?.new_id ?? '' + ' your email was added to allow-list',{
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
      setIsModalOpen(false)
      fetchRobodialers();
    }else{
      toast.error(httpCode + ' Unable to create robodialer: ' + data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      })
    }
  }
  
  const addAgent = async (robo_data) => {
    robo_data['agents_emails'] = [robo_data.email]
    const {httpCode, data} = await createAgent(robo_data);
    if(httpCode === 200){
      toast.success('Robodialer created successfully: '+ data?.new_id ?? '' + ' your email was added to allow-list',{
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
      setIsAgentModalOpen(false)
      fetchAgents();
    }else{
      toast.error(httpCode + ' Unable to create robodialer: ' + data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      })
    }
  }

  return (
    <>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading && robodialersData ? (
        <div className="mx-4 mt-5 mb-20">
          
          <div className="flex items-center justify-between gap-x-2">
            <div>
              <span className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
                Robodialers
              </span>
            </div>
            <button
               className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4' onClick={() => {
                setIsModalOpen(true)
               }}>
                Create Robodialer
              </button>
          </div>
          <RobodialersAdminTable data={robodialersData} refresh={fetchRobodialers}/>
          <div className="flex items-center justify-between gap-x-2 mt-5">
            <div>
              <span className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
                Agents
              </span>
            </div>
            <button
               className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4' onClick={() => {
                setIsAgentModalOpen(true)
               }}>
                Add Agent
              </button>
          </div>
          <AgentsAdminTable data={agentsData} refresh={fetchAgents}/>
          
        </div>
      ) : null}
      <AddRobodialerModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}} onSuccess={addRobodialer}/>
      <AddAgentModal isOpen={isAgentModalOpen} onClose={() => {setIsAgentModalOpen(false)}} onSuccess={addAgent}/>
    </>
  );
}
