import { ReactComponent as SuperDialIcon } from 'assets/superdial-logo-icon-blue.svg';

import Loader from 'components/Loader';
import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getAuth } from 'firebase/auth';
import { agentSignIn, getAgentUserCredentials } from 'apis/agent';
import { Toaster } from 'react-hot-toast';

type PathType = {
  name: string;
  href: string;
  current: boolean;
}
type AgentsType = {
  debug?: boolean, user: string, password: string, featureFlags?:{ displayStatsTab: boolean, displayAuditTab: boolean, displayQMTab: boolean, displayAdminTab: boolean}
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
export default function AgentDashboard({ userData }) {

  const [agentUser, setAgentUser] = useState<null | AgentsType>(null)

  const fetchFirestoreUser = async () => {
    //console.log('fetch user');
    agentSignIn()
    const userResponse = await getAgentUserCredentials();
    setAgentUser({
      password: userResponse.data.pwd,
      user: userResponse.data.user,
      featureFlags: userResponse.data.featureFlags ?? {}
    })
  };

  useEffect(() => {
    fetchFirestoreUser()
  }, [])

  const location = useLocation();
  const navigate = useNavigate();

  const initialNavigation: PathType[] = [
    { name: 'Sidekick', href: '/agents-dashboard/sidekick', current: false },
    
  ];
  useEffect(() => {
    if (agentUser && agentUser?.featureFlags && agentUser?.featureFlags?.displayAuditTab) {
      setNavigation((prevNavigation) => [
        ...prevNavigation,
        { name: 'Audit', href: '/agents-dashboard/audit', current: false },
      ]);
      setPath()
    }
    if (agentUser && agentUser?.featureFlags && (agentUser?.featureFlags?.displayQMTab || agentUser?.featureFlags?.displayStatsTab || agentUser?.featureFlags?.displayAdminTab)) {
      setNavigation((prevNavigation) => [
        ...prevNavigation,
        //{ name: 'Batches', href: '/agents-dashboard/qm', current: false },
        //{ name: 'Calls', href: '/agents-dashboard/calls', current: false },
        { name: 'Tools', href: '/agents-dashboard/tools', current: false }
      ]);
      setPath()
    }
    if (agentUser && agentUser?.featureFlags && agentUser?.featureFlags?.displayStatsTab) {
      setNavigation((prevNavigation) => [
        ...prevNavigation,
        //{ name: 'Agents Stats', href: '/agents-dashboard/stats', current: false },
      ]);
      setPath()
    }
    if (agentUser && agentUser?.featureFlags && agentUser?.featureFlags?.displayAdminTab) {
      setNavigation((prevNavigation) => [
        ...prevNavigation,
        //{ name: 'Admin', href: '/agents-dashboard/admin', current: false },
      ]);
      setPath()
    }
    
  }, [agentUser]);

  const [navigation, setNavigation] = useState<PathType[]>(initialNavigation);
  const setPath = () => {
    setNavigation((prevNavigation) => {
      const updatedNavigation = prevNavigation.map((item) => ({
        ...item,
        //current: item.href === location.pathname,
        current: item.href === location.pathname.split('/').slice(0, 3).join('/'),
      }));
      const hasCurrent = updatedNavigation.some((item) => item.current);
      if (!hasCurrent) {
        updatedNavigation[0].current = true; // set current:true
      }
      return updatedNavigation;
    });
  }

  useEffect(() => {
    setPath()
  }, [location.pathname]);
  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      <div className='flex flex-col h-screen'>
        <div className="flex justify-between p-3 border-b bg-white border-superbill-soap">
          <div className="flex justify-between">
            <div className="text-superbill-indigo text-xl	font-jakarta flex items-end">
              <SuperDialIcon className="w-[30px] mr-2" />
              SUPER<strong>DIAL</strong>
            </div>
            <nav className='flex items-center justify-end ml-5'>
              <ul className="flex items-center">
                {navigation.map((item) => (
                  <li key={item.name} className='mx-3'>
                    <Link to={item.href}>
                      <div
                        onClick={() => {
                          navigate(item.href)
                        }}
                        className={classNames(
                          item.current
                            ? 'text-superbill-indigo border-b-[2px] border-superbill-indigo'
                            : ' text-superpay-light-text-gray hover:text-indigo-600 border-b-[2px] border-white',
                          'text-base font-bold cursor-pointer font-jakarta mt-1'
                        )}
                      >
                        {item.name}
                      </div>
                    </Link>

                  </li>
                ))}
              </ul>
            </nav>

          </div>
          <Menu
            as='div'
            className="items-center rounded-full p-2  relative inline-block text-left z-10 bg-gradient-to-r from-superbill-indigo to-superbill-indigo-dark"
          >
            <div className='flex items-center justify-center'>
              <Menu.Button>
                <span className="text-white text-sm flex items-center justify-center h-4 w-4 font-semibold capitalize">
                  {" "}
                  {/* <UserIcon className="h-[14px] fill-white"/> */}
                  {userData?.email[0]}
                  {" "}
                </span>
              </Menu.Button>
            </div>


            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'>
              <Menu.Items className='z-100 absolute right-0 mt-2 w-fit whitespace-nowrap origin-top-right border border-superbill-soap rounded bg-white shadow-lg focus:outline-none overflow-hidden min-w-[200px]'>
                <Menu.Item >
                  <div
                    className={'text-superbill-jacarta w-full px-4 py-3 text-left font-sans'}
                  >
                    <div className="text-base font-jakarta" >{userData?.email}</div>
                    <div className="text-sm text-superpay-light-text-gray capitalize" >{userData?.userType}</div>
                  </div>
                </Menu.Item>
                <div className='border-b border-1 border-superbill-soap'></div>

                <div className='border-b border-1 border-superbill-soap'></div>
                <Menu.Item >
                  <div
                    onClick={() => {
                      getAuth().signOut();
                      window.location.assign('/agents-sign-in')
                    }}
                    className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                  >
                    <div className="text-base" >Sign Out</div>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>

          </Menu>

        </div>
        <main className="mx-auto flex-1 w-full">
          {agentUser ? (
            <>
              {agentUser.user && agentUser.password ?
                  <Outlet context={{
                    featureFlags: agentUser?.featureFlags,
                    agentEmail: userData?.email,
                    agentSip: agentUser?.user!,
                    login: agentUser?.user!,
                    password: agentUser?.password!,
                  }} />
                : <div className='w-full h-full flex justify-center items-center'>
                  {' '} Missing auth credentials, contact support{' '}
                </div>}
            </>
          )
            :
            <div className='w-full h-full flex justify-center items-center'>
              {' '} <Loader />{' '}
            </div>
          }
        </main>
      </div>
    </>

  )
}