import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react'

type ScriptBuilderHomeHeaderProps = {
  openDemoCallModal: () => void;
  callsRemaining: number;
  status: string;
  unlimitedCalls: boolean;
  newInsurances?: number;
  totalInsurances?: number;
  newPatients?: number;
  pmsActive?: boolean;
}
export default function ScriptBuilderHomeHeader({ callsRemaining, unlimitedCalls, openDemoCallModal, status, newInsurances = 0, totalInsurances = 0, newPatients = 0, pmsActive }: ScriptBuilderHomeHeaderProps) {
  const hideThisBox = true;

  return (
    <div className='flex justify-center font-sans items-center w-full'>
      <div className='flex items-center justify-center w-full p-2  bg-superdial-background bg-cover h-fit border-b border-superbill-soap !min-h-[85px] h-[110px] rounded'>
        <div className='flex justify-between w-full lg:pl-5'>
          <div className='flex flex-row w-full justify-start items-center'>
            <div className='ml-10 text-xl font-jakarta text-white'>
              Welcome back to <strong>SuperDial</strong>
            </div>
          </div>
          <div className='flex flex-row w-full justify-end items-center p-4'>
            {/* <button
              onClick={openModal}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              Run a Script
            </button> */}
            {pmsActive ?
              <>
                {/* <div className='flex flex-col py-1 px-3 justify-between items-center rounded bg-white/20 ml-3 w-36'>
                <div className='text-white font-bold text-2xl font-jakarta text-center w-full'>
                {totalInsurances}
                </div>
                <div className='text-white text-center text-sm'>
                  Total insurances verified
                </div>
              </div>
            <div className='flex flex-col py-1 px-3 justify-between items-center rounded bg-white/20 ml-3 w-36'>
                <div className='text-white font-bold text-2xl font-jakarta text-center w-full'>
                {newPatients}
                </div>
                <div className='text-white text-center text-sm'>
                  Patients missing verification
                </div>
              </div> */}
              </> :
              <>
                {!unlimitedCalls && !hideThisBox ?
                  <div className='flex flex-col py-1 px-3 justify-between items-center rounded bg-white/20 ml-3 w-36'>
                    <div className='text-white font-bold text-2xl font-jakarta text-center w-full'>
                      {callsRemaining}
                    </div>
                    <div className='text-white text-center text-xs'>
                      Calls Remaining
                    </div>
                  </div>
                  : null}
              </>}
            {/* {status !== 'live' ?
              <button
                className='text-white text-center text-xs font-bold hover-underline-animation border border-superbill-soap rounded-full px-4 py-2'
                onClick={openDemoCallModal}>
                Place a Demo Call
              </button>
              : null} */}


          </div>
        </div>
      </div>
    </div>
  );
}
