import React, { useState }  from 'react';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import { Script } from 'types/types';
import { createSandboxCall, enableAutoVerification } from 'apis/robodialer';


type SelectionPageProps = {
  onClose: () => void;
  onSuccess: () => void;
}
const SelectionPage = ({ onClose, onSuccess,  }: SelectionPageProps) => {

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);



  const onSubmit = async () => {
    setLoading(true)
    const { httpCode } = await enableAutoVerification()
    setLoading(false)
    onSuccess()
    
  };



  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Enable Auto-Verification
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        <div className='flex flex-col justify-center'>
          <div className='text-sm text-superbill-jacarta'>
            With auto-verification, SuperDial will automatically place an insurance verification call for each new patient we detect in your PMS. 
          </div>
          <div className='text-sm text-superbill-jacarta mt-5'>You can update your preferences anytime in the <strong>Settings</strong> page.</div>
        </div>
      </div >
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={onClose}
        >
          Close
        </button>
        <button
          disabled={!isValid}
          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
          onClick={onSubmit}
        >
          {isLoading ? <Spinner /> : <>Enable</>}
          
        </button>
      </div>
    </div >
  );
};

export default SelectionPage;
