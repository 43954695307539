import React from "react";
import Loader from 'components/Loader';
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import { GenerateSecretModalStates } from "enums/enums";
import { refreshSecretHash, refreshTypeSecretHash } from "../../../../apis/robodialer";
// custom styles
// components
interface SecretFormData {
  apiSecret: string;

}

type GenerateSecretPageProps = {
  onClose: () => void;
  onSuccess: () => void;
  apiType?;
}

export default function GenerateSecretPage({onClose, onSuccess, apiType} : GenerateSecretPageProps) {

  const [apiSecret, setApiSecret] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isCopied, setIsCopied] = React.useState(false);




  const secretForm = useForm<SecretFormData>({
    defaultValues: {
      apiSecret: apiSecret ? apiSecret : '',
    },
  });
  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    });
  }

  
  React.useEffect(() => {
    const fetch = async () => {
      if (apiType) {
        const { data } = await refreshTypeSecretHash(apiType);
        setApiSecret(data.apiSecret)
      } else {
        const { data } = await refreshSecretHash();
        setApiSecret(data.apiSecret)
      }
      toast.success('Sandbox Secret updated!', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      })
    }
    fetch()

  }, []);

  React.useEffect(() => {
    if (apiSecret) {
      setIsLoading(false)
      secretForm?.setValue('apiSecret', apiSecret);
    }

  }, [apiSecret]);


  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Generate Secret
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
          < Loader />
          <div className="text-xs text-superbill-jacarta mt-2">
            Generating secret... Do not close this window...
          </div>
          </div>) : null}
        {!isLoading ?
          <>
            <div className="my-1">
              <span className=" font-sans text-sm text-superbill-jacarta">The secret cannot be accessed again after you close this dialog. To be able to use the key secret, you must <strong>copy it to the clipboard</strong>. After copying it to the clipboard, use a secure means for storing the key secret.</span>
            </div>
            <div className="flex items-center mt-5">
              <Controller
                name="apiSecret"
                control={secretForm.control}
                rules={{ required: 'Requiered' }}
                render={({ field }) => (
                  <input
                    {...field}
                    type={'text'}
                    readOnly={true}
                    className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                  />
                )}
              />
              <div className='flex items-center mx-4'>
                <button
                  type="button"
                  className="bg-superbill-ultramarine disabled:bg-blue-200 font-jakarta hover:bg-blue-700 text-white font-bold py-2 px-5 rounded-full text-sm text-nowrap"
                  onClick={() => { onCopy(secretForm.watch('apiSecret')) }}
                >
                  Copy to Clipboard
                </button>
                {/* <button
                              type="button"
                              onClick={() => { onCopy(secretForm.watch('secret')) }}
                            >
                              <CopyIcon className="h-6 w-6 child:fill-superbill-jacarta mr-4" />
                            </button> */}
              </div>
            </div>
          </>
          : null}
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3">
        <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={() => {
            onClose();
            onSuccess();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};
