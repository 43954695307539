
import React from 'react';
import ReactJson from 'react-json-view';

interface Question {
  answerOptions?: string[];
  questionId: string;
  answerType: string;
  question: string;
  questions?: Question[];
  parentAnswer?: boolean;
}

interface Props {
  questions: Question[];
  callInputs: Record<string, string>;
}




const ScriptOverview: React.FC<Props> = ({ questions, callInputs }) => {
  const formatString =(template: string): string => {
    return template.replace(/\{([^}]+)\}/g, (match, key) => {
      const value = callInputs[key.trim()];
      return typeof value !== 'undefined' ? value : match;
    });
  }
  const transformQuestions = (questions: Question[]) => {
    const result: Record<string, any> = {};

    questions?.forEach((questionObj) => {
      const { questionId, question, questions: followUps } = questionObj;
    
      result[questionId] = formatString(question);
  
      if (followUps && followUps.length > 0) {
        followUps.forEach((followUp) => {
          const { questionId: followUpId, question: followUpQuestion, parentAnswer, questions: subQuestions, answerOptions } = followUp;
          const parentKey = parentAnswer ? 'Yes' : 'No';
          result[parentKey] = result[parentKey] || {};
          result[parentKey][followUpId] = formatString(followUpQuestion) + (answerOptions ? ` Options: [${answerOptions?.toString()}]`  : "");
          
  
          if (subQuestions && subQuestions.length > 0) {
            const noFollowUps = subQuestions?.filter((followUp) => followUp.parentAnswer === false && (followUp.question && followUp.question !== ''));
            const yesFollowUps = subQuestions?.filter((followUp) => followUp.parentAnswer === true && (followUp.question && followUp.question !== ''));
            noFollowUps && noFollowUps.length > 0 && (
              result[parentKey]["No"] = transformQuestions(noFollowUps)
            )
            yesFollowUps && yesFollowUps.length > 0 && (
              result[parentKey]["Yes"] = transformQuestions(yesFollowUps)
            )

            /* subQuestions.forEach((subQuestion) => {
              const { questionId: subQuestionId, question: subQuestionText, parentAnswer: subParentAnswer } = subQuestion;
              const subParentKey = subParentAnswer ? 'Yes' : 'No';
              result[parentKey][subParentKey] = result[parentKey][subParentKey] || {};
              result[parentKey][subParentKey][subQuestionId] = subQuestionText;
            }); */
          }
        });
      }
    });
  
    return result;
  };
  const transformedQuestions = transformQuestions(questions || []);
  return (
    <ReactJson
      style = {{
        zIndex: '0'
      }}
      src={transformedQuestions}
      theme="rjv-default"
      displayDataTypes={false}
      displayObjectSize={false}
      name={null}
    />
  );
  
};

export default ScriptOverview;

