import React, { useState, useRef } from 'react';

const AudioRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const timerInterval = useRef<NodeJS.Timeout | null>(null);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        mediaRecorder.current = recorder;
        const audioChunks: Blob[] = [];
        recorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });
        recorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          const url = URL.createObjectURL(audioBlob);
          setAudioURL(url);
        });
        recorder.start();
        setIsRecording(true);
        timerInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      clearInterval(timerInterval.current as NodeJS.Timeout);
      setRecordingTime(0);
    }
  };

  return (
    <div className='border border-superpay-soap p-4 flex items-center justify-center'>
      <button className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4' type="button" onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop audio test' : 'Start audio test'}
      </button>
      {isRecording ? <p>Recording time: {recordingTime}</p> : null}
      {!isRecording && audioURL && <audio controls src={audioURL} />}
    </div>
  );
};

export default AudioRecorder;
