import React, { useEffect, useState } from "react";
import { ReactComponent as SuperDialIcon } from "assets/superdial-logo-icon-blue.svg";
import Loader from "components/Loader";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as ResultsUnselectedIcon } from "assets/results-unselected.svg";
import { ReactComponent as ResultsSelectedIcon } from "assets/results-selected.svg";
import { ReactComponent as CallSettingsUnselectedIcon } from "assets/callSettings-unselected.svg";
import { ReactComponent as CallSettingsSelectedIcon } from "assets/callSettings-selected.svg";
import { ReactComponent as TeamUnselectedIcon } from "assets/team-unselected.svg";
import { ReactComponent as GadgetsUnselectedIcon } from 'assets/webshooks-unselected.svg';
import { ReactComponent as GadgetsSelectedIcon } from 'assets/webhooks-selected.svg';
import { ReactComponent as TeamSelectedIcon } from "assets/team-selected.svg";
import { ReactComponent as ResourcesUnselectedIcon } from "assets/resources-unselected.svg";
import { ReactComponent as ResourcesSelectedIcon } from "assets/resources-selected.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-slate.svg";
import { ReactComponent as MultipleIcon } from "assets/multiple.svg";
import { ReactComponent as NextCallIcon } from 'assets/phone-next-call.svg'
import { ReactComponent as ScriptsUnselectedIcon } from 'assets/scripts-unselected.svg';
import { ReactComponent as ScriptsSelectedIcon } from 'assets/scripts-selected.svg';

type PathType = {
  name: string;
  href: string;
  current: boolean;
  UnselectedIcon: React.SVGProps<SVGSVGElement>;
  SelectedIcon: React.SVGProps<SVGSVGElement>;
};
type OutletPropsType = {
  featureFlags: {
    displayAuditTab: boolean;
    displayQMTab: boolean;
    displayStatsTab: boolean;
    displayAdminTab: boolean;
  };
  agentEmail: string;
  agentSip: string;
  login: string;
  password: string;
};
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
export default function QMToolsWrapperPage({ userData }) {
  // get outlet props
  const { featureFlags } = useOutletContext<OutletPropsType>();
  console.log(featureFlags);

  const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();

  const [navigation, setNavigation] = useState<PathType[] | null>(null);
  const setPath = () => {
    setNavigation((prevNavigation) => {
      const updatedNavigation = (prevNavigation ?? []).map((item) => ({
        ...item,
        current: item.href === location.pathname.split("/").slice(0, 4).join("/"),
      }));
      const hasCurrent = updatedNavigation.some((item) => item.current);
      if (!hasCurrent && 0 < updatedNavigation.length) {
        updatedNavigation[0].current = true; // set current:true
      }
      return updatedNavigation;
    });
  };

  useEffect(() => {
    //setPath();
    let navObj: PathType[] = []
    if (featureFlags && featureFlags?.displayQMTab) {
      navObj = [
        {
          name: "Batches",
          href: "/agents-dashboard/tools/batches",
          current: false,
          UnselectedIcon: <ResultsUnselectedIcon className="w-[16px] h-[16px]" /> ,
          SelectedIcon: <ResultsSelectedIcon className="w-[16px] h-[16px]" />,
        },
        {
          name: "Calls",
          href: "/agents-dashboard/tools/calls",
          current: false,
          UnselectedIcon: <NextCallIcon className="child:fill-superbill-slate-grey w-[16px] h-[16px]" />,
          SelectedIcon: <NextCallIcon className=" child:fill-superbill-indigo w-[16px] h-[16px]" />,
        },
        {
          name: "Scripts",
          href: "/agents-dashboard/tools/scripts",
          current: false,
          UnselectedIcon: <ScriptsUnselectedIcon className="child:fill-superbill-slate-grey w-[16px] h-[16px]" />,
          SelectedIcon: <ScriptsSelectedIcon className=" child:fill-superbill-indigo w-[16px] h-[16px]" />,
        },
        {
          name: "Phonebook",
          href: "/agents-dashboard/tools/phonebook",
          current: false,
          UnselectedIcon: <ResourcesUnselectedIcon className="w-[16px] h-[16px]" />,
          SelectedIcon: <ResourcesSelectedIcon className="w-[16px] h-[16px]" />,
        },
        {
          name: "Priority Queue",
          href: "/agents-dashboard/tools/gadgets",
          current: false,
          UnselectedIcon: <GadgetsUnselectedIcon className="w-[16px] h-[16px]" />,
          SelectedIcon: <GadgetsSelectedIcon className="w-[16px] h-[16px]" />,
        },
        {
          name: "Assign Calls",
          href: "/agents-dashboard/tools/assign-calls",
          current: false,
          UnselectedIcon: <MultipleIcon className="child:fill-superbill-slate-grey w-[16px] h-[16px]"/>,
          SelectedIcon: <MultipleIcon className=" child:fill-superbill-indigo w-[16px] h-[16px]" />,
        },
      ]
    }
    if (featureFlags && featureFlags?.displayStatsTab) {
      navObj = [
        ...navObj,
        ...[{
          name: "Agents Stats",
          href: "/agents-dashboard/tools/stats",
          current: false,
          UnselectedIcon: <TeamUnselectedIcon className="w-[16px] h-[16px]" />,
          SelectedIcon: <TeamSelectedIcon className="w-[16px] h-[16px]" />,
        }],
      ]
    }
    if (featureFlags && featureFlags?.displayAdminTab) {
      navObj = [
        ...navObj,
        ...[{
          name: "Admin",
          href: "/agents-dashboard/tools/admin",
          current: false,
          UnselectedIcon: <CallSettingsUnselectedIcon className="w-[16px] h-[16px]" />,
          SelectedIcon: <CallSettingsSelectedIcon className="w-[16px] h-[16px]" />,
        },],
      ]
    }
    setNavigation((prevNavigation) => [
      ...(prevNavigation ?? []),
      ...navObj,
    ]);
    setPath();
    setLoading(false)
  }, [featureFlags]);
  
  useEffect(() => {
    setPath();
  }, [location.pathname]);


  return (
    <>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading ? (
        <>
          <div className="flex">
            <div
              className={`relative border border-r bg-white border-superbill-soap ${
                sideNavOpen ? "min-w-[240px]" : "min-w-[60px]"
              } `}
            >
              <div className="p-2">
                <nav className="justify-end mt-10">
                  <ul className="items-center">
                    {navigation?.map((item) => (
                      <li key={item.name} className="">
                        <Link to={item.href}>
                          <div
                            className={classNames(
                              item.current
                                ? "text-superbill-indigo font-bold bg-superbill-background-base rounded p-2"
                                : " text-superbill-slate-grey hover:text-indigo-600 font-normal bg-white rounded p-2",
                              "text-base cursor-pointer font-jakarta mt-1 flex items-center",
                              sideNavOpen ? "" : "justify-center"
                            )}
                          >
                            {item.current ? (
                              <>{item.SelectedIcon}</>
                            ) : (
                              <>{item.UnselectedIcon}</>
                            )}
                            {sideNavOpen ? (
                              <div className="ml-2">{item.name}</div>
                            ) : null}
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              {sideNavOpen ? (
                <div className="fixed bottom-10 p-2">
                  <a
                    href="https://superdial.readme.io/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:fill-superbill-indigo"
                  >
                    <div className="text-superbill-slate-grey hover:text-indigo-600 font-normal bg-white rounded p-2 flex items-center">
                      Documentation
                      <ExternalLinkIcon className="ml-2 fill-superbill-slate-grey" />
                    </div>
                  </a>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col h-screen w-full overflow-y-auto p-4">
              <main className="mx-auto flex-1 w-full">
                <Outlet context={{}} />
              </main>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
