import React, { useState } from "react";
import AudioRecorder from "./audio-recorder";
import SpeechRecognitionComponent from "./speeach-recognition";
import OwnMicComponent from "./own-mic-component";

type navType = {
  webkitSpeechRecognition?: any;
  SpeechRecognition?: any;
};
type AudioTestComponentProps = {
  onSuccess: () => void;
};
export default function AudioTestComponent({
  onSuccess,
}: AudioTestComponentProps) {
  const [isListening, setIsListening] = useState(false);
  const [speechAllowed, setSpeechAllowed] = useState(true);
  const [transcript, setTranscript] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [success, setSuccess] = useState<boolean>(false);
  const startListening = () => {
    setIsListening(true);
    setTranscript("");
    setSuccessMessage("");
    const speechRec =
      (window as navType).SpeechRecognition ||
      (window as navType).webkitSpeechRecognition ||
      false;
    const recognition = new speechRec();
    console.log(
      "recognition",
      recognition,
      (window as navType).SpeechRecognition,
      (window as navType).webkitSpeechRecognition
    );

    if (recognition) {
      console.log(recognition?.start);

      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        const currentTranscript = event.results[0][0].transcript;
        setTranscript(currentTranscript);
        if (currentTranscript.toLowerCase() === "hello") {
          stopListening();
        }
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.start();
    } else {
      setSpeechAllowed(false);
    }
  };

  const stopListening = () => {
    setIsListening(false);
  };

  return (
    <div className="px-10 py-6 border border-superpay-soap rounded bg-white">
      <div className="text-sm text-superbill-jacarta text-center mb-5">
        To proceed, please complete the audio test
      </div>
      {speechAllowed ? (
        <SpeechRecognitionComponent success={success} setSuccess={setSuccess} />
      ) : (
        <AudioRecorder />
      )}
      <OwnMicComponent />
      <div
        className={`${
          success ? "text-green-600" : "text-superbill-gray"
        } ${
          !success && speechAllowed ? "line-through" : ""
        } text-sm text-center`}
      >
        {speechAllowed ? "Audio test successful! You're now ready to start." : "Press the 'Start' button, say a few words, then press 'Stop' to listen to what you said."}
      </div>
      <div className="w-full items-center flex flex-col justify-center my-2">
        <button
          disabled={!success && speechAllowed}
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4"
          onClick={onSuccess}
        >
          Go to Dashboard
        </button>
      </div>
      {!success ? 
        <div className="w-full flex items-center justify-center p-2">
          {/* <span
            onClick={() => setSpeechAllowed((prev) => !prev)}
            className="cursor-pointer hover-underline-animation text-xs"
          >
            Try another method
          </span> */}
          {<div
          className="mt-20 text-xs text-superbill-jacarta italic"
          >
            <strong>The graph is moving, Mic Status is "Ready (No errors found),"</strong> but <span onClick={() => setSpeechAllowed((prev) => !prev)} className="cursor-pointer hover-underline-animation text-xs" >I can't pass the test.</span>.
          </div>}
        </div>
        : null}
    </div>
  );
}
