
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import { ReactComponent as XIcon } from 'assets/x.svg';
import { ReactComponent as CheckIcon } from "assets/check.svg";
import {
  createAgent,
  createRobodialer,
  getRobodialers,
  getPhonebook,
} from "apis/agent";

import AddRobodialerModal from "./components/add-robodialer-modal";
import AddAgentModal from "./components/invite-agents-modal";

import FirestoreTable from "./components/firestore-table";
import { XMarkIcon } from '@heroicons/react/24/outline';
import AddToPhonebookModal from "./components/add-to-phonebook-modal";
import { AddToPhonebookModalStates } from "../../enums/enums";

interface FirestoreData {
  [key: string]: any;
}
export default function PhonebookPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [editPath, setEditPath] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = React.useState<AddToPhonebookModalStates>(AddToPhonebookModalStates.IVR);
  const [path, setPath] = useState<string>("Phonebook");
  const [isAgentModalOpen, setIsAgentModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<FirestoreData | null>(null);
  const [tempText, setTempText] = useState<string>("");

  const fetchPhonebook = async (newPath) => {
    setLoading(true);
    const { data, httpCode } = await getPhonebook(newPath);
    setLoading(false);
    if (httpCode !== 200) {
      toast.error(httpCode + " Unable to get Phonebook: " + data?.error ?? "", {
        iconTheme: {
          primary: "#F36F82",
          secondary: "#ffffff",
        },
      });
      return {};
    }
    return data;
  };

  useEffect(() => {
    const fetch = async () => {
      setData(await fetchPhonebook(path));
    };
    fetch();
  }, []);

  const setValueAtPath = (obj, path, value) => {
    //console.log('value', value);

    const parts = path.split("/").filter((part) => part);
    //console.log('parts', parts);

    let current = obj;
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      if (!(part in (current ?? {}))) {
        current[part] = {};
      }
      current = current[part];
      //console.log('part', part,'current', current);
      if ((i + 1) % 2 === 0) {
        if (!("_subcollections" in current)) {
          current["_subcollections"] = [];
        }
        current = current["_subcollections"];
      }
    }

    const lastPart = parts[parts.length - 1];
    //console.log('lastPart', lastPart);
    current[lastPart] = value[lastPart];
    //console.log('current', current);

    setData(obj);
  };

  const handlePathClick = async (
    newPath: string,
    index: number,
    item: string
  ) => {
    if (index <= path.split("/").length - 1) {
      newPath =
        path
          .split("/")
          .slice(0, index + 1)
          .join("/") +
        "/" +
        item;
    }
    setPath(newPath);
    const newData = await fetchPhonebook(newPath);
    const cleanPath = newPath.replace(/^\/|\/$/g, "");
    setValueAtPath(data, cleanPath, newData);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleAccept = async () => {
    setEditPath(false)
    if(inputRef && inputRef.current){
      setPath(inputRef.current.value);
      const newData = await fetchPhonebook(inputRef.current.value);
      const cleanPath = inputRef.current.value.replace(/^\/|\/$/g, "");
      setValueAtPath(data, cleanPath, newData);
    }
  };

  const handleCancel = () => {
    setEditPath(false)
    if(inputRef && inputRef.current){
      inputRef.current.value = path;
    }
  };

  return (
    <>
      {data ? (
        <div className="mx-4 mt-5 mb-20">
          <div className="flex items-center justify-between gap-x-2">
            <div>
              <span className="cursor-pointer font-jakarta text-superbill-jacarta text-xl font-bold mb-4" onClick={async () => {
                setPath('Phonebook');
                setData(await fetchPhonebook('Phonebook'));
              }}>
                Phonebook
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              
              <button
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-xs font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center"
                onClick={() => {
                  setModalState(AddToPhonebookModalStates.IVR);
                  setIsModalOpen(true);
                }}
              >
                Add/Update Phonebook
              </button>
            </div>
          </div>

          {loading ? (
            <>
              <div className="w-full h-full flex justify-center items-center">
                {" "}
                <Loader size={36}/>{" "}
              </div>
            </>
          ) : (
            <div className="mt-5 mb-2">
              {" "}
              {editPath ? (
                <div className="flex items-center w-full">
                <input
                  type="text"
                  ref={inputRef} 
                  defaultValue={path}
                  className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                />
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleAccept}
                    className="ml-2 text-green-500 hover:green-red-700 w-[20px] flex-none"
                  >
                    <CheckIcon className='w=[8px]'/>
                  </button>
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleCancel}
                    className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                  >
                    <XIcon className='w=[8px]'/>
                  </button>
                </div>
              ) : (
                <span className="text-sm font-bold text-superbill-jacarta" onClick={() => {/* setEditPath(true) */}}>
                  {path.replace(/^\/|\/$/g, "")}
                </span>
              )}
            </div>
          )}

          <div className="mt-2 w-full">
            <FirestoreTable path={path} onClick={handlePathClick} data={data} />
          </div>
        </div>
      ) : null}
      <AddToPhonebookModal isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}} onSuccess={async () => {
        setData(await fetchPhonebook('Phonebook'));
      }} modalState={modalState}/>
    </>
  );
}
