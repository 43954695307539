import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AttemptsIcon } from 'assets/number.svg';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as AuditIcon } from "assets/boolean.svg";
import { ReactComponent as ViewInputsIcon } from "assets/view-inputs.svg";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { paginationHelper, PaginationStates } from "utils/pagination";
import { ReactComponent as BundleIcon } from "assets/multiple.svg";
import { DataItem, QMScriptsResponseType } from "types/types";
import KeyValueGrid from "./key-value-grid";
import ShowQuestionsModal from "../show-questions-modal";
import EditInstructionsModal from "../edit-instructions-modal";

type ScriptsTableProps = {
  scripts: DataItem[] | null;
  refresh: () => void
};
export default function ScriptsTable({ scripts, refresh }: ScriptsTableProps) {
  const [selectedScript, setSelectedScript] = useState<number | null>(null);
  const [selectedScriptInstructionsIndex, setSelectedScriptInstructionsIndex] = useState<number | null>(null);
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);
  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: scripts ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 25,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  return (
    <>
      <div className="bg-white">
        <div className="w-full grid grid-cols-24 gap-x-2 bg-superbill-anti-flash-white divide-x divide-superpay-soap">
          <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Created At
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Robodialer
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Script ID
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Name
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Type
          </div>
          <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
            Hidden
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap">
          scriptVersion
          </div>
          <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b overflow-x-auto border-superpay-soap text-right">
            Actions
          </div>
        </div>
        {((currentActiveResults as DataItem[]) ?? []).map((script, index) => (
          <div
            key={`${script?.scriptId}-${index}`}
            className={`grid grid-cols-24 gap-x-2 py-2 divide-x border-b border-superpay-soap divide-superpay-soap`}
          >
            <div className=" col-span-4 px-2 py-1 overflow-x-auto">
              {script?.createdAt.et ?? "0"}
            </div>
            <div className=" col-span-3 px-2 py-1 overflow-x-auto">
              {script?.robodialerId ?? "-"}
            </div>
            <div className=" col-span-3 px-2 py-1 overflow-x-auto">
              {script?.scriptId ?? "-"}
            </div>
            <div className=" col-span-3 px-2 py-1 overflow-x-auto">
              {script?.name ?? "-"}
            </div>
            <div className=" col-span-3 px-2 py-1 overflow-x-auto">
              {script?.type ?? "-"}
            </div>
            <div className=" col-span-2 px-2 py-1 overflow-x-auto">
                {(script?.hidden ? "Yes" : "No") ?? "-"}
            </div>
            <div className=" col-span-3 px-2 py-1 overflow-x-auto">
              {script?.scriptVersion ?? "-"}
            </div>
            <div className="col-span-3 px-2 py-1 text-right flex items-center justify-center gap-x-3">
              <Tooltip
                content={
                  <span style={{ whiteSpace: "pre-line" }}>View Questions</span>
                }
                className="max-w-[200px] bg-black py-1 px-2"
              >
                <ViewInputsIcon
                  onClick={() => {
                    setSelectedScript(index)
                  }}
                  className="cursor-pointer w-[20px]"
                />
              </Tooltip>
            
              <Tooltip
                content={<span style={{ whiteSpace: "pre-line" }}>Instructions</span>}
                className="max-w-[200px] bg-black py-1 px-2"
              >
                <InfoIcon
                  onClick={() => {
                    setSelectedScriptInstructionsIndex(index)
                  }}
                  className="cursor-pointer w-[20px]"
                />
              </Tooltip>
            </div>

          </div>
        ))}
        <div className="flex items-center justify-end flex-row py-3 px-2 font-sans">
          <div
            className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.backward)}
          >
            {currentLowerIndex === 0 ? <></> : <BackIcon />}
          </div>
          <div className="mx-2">
            <strong>
              {currentLowerIndex + 1} to {currentUpperIndex + 1}
            </strong>{" "}
            of {scripts?.length}
          </div>
          <div
            className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
            onClick={() => changePagination(PaginationStates.forward)}
          >
            {currentUpperIndex + 1 === scripts?.length ? <></> : <ForwardIcon />}
          </div>
        </div>
      </div>
      <ShowQuestionsModal modalIsOpen={selectedScript != null} setSelectedScript={setSelectedScript} n_questions={scripts && scripts[selectedScript??0] ? scripts[selectedScript??0]['questions'] : null}/>
      <EditInstructionsModal onSuccess={() => {
        refresh()
      }} modalIsOpen={selectedScriptInstructionsIndex != null} setSelectedScript={setSelectedScriptInstructionsIndex} data={{
        instructions: scripts && scripts[selectedScriptInstructionsIndex??0] ? scripts[selectedScriptInstructionsIndex??0]['instructions'] : '',
        robodialerId: scripts && scripts[selectedScriptInstructionsIndex??0] ? scripts[selectedScriptInstructionsIndex??0]['robodialerId'] : '',
        scriptId: scripts && scripts[selectedScriptInstructionsIndex??0] ? scripts[selectedScriptInstructionsIndex??0]['scriptId'] : ''
      }}/>
    </>
  );
}
