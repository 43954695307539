import React, { useState, useEffect, useRef, useMemo } from "react";
import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { DateTime } from "luxon";
import Loader from "components/Loader";
import { toast, Toaster } from "react-hot-toast";
import { getScripts, getRobodialers } from "apis/agent";
import FilterForm from "./components/filter-form";
import { QMScriptsResponseType } from "types/types";
import { useLocation } from "react-router-dom";
import ScriptsTable from "./components/scripts-table";


const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};
export default function QMScriptsPage() {
  const query = useQuery();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [scripts, setScripts] = useState<QMScriptsResponseType | null>(null);

  const fetchScripts = async () => {
    setIsSearching(true);
    const response = await getScripts({
      scriptId: query.get("scriptId") ?? '',
      callId: query.get("callId") ?? '',
      batchStatus: query.get("batchStatus") ?? '',
      filterBy: query.get("filterBy") ?? '',
      dateOption: query.get("dateOption") ?? '',
      dateFrom: query.get("dateFrom") ?? '',
      dateTo: query.get("dateTo") ?? '',
      robodialerId: query.get("robodialerId") ?? ''
    });
    console.log(response.data);
    setIsSearching(false);
    setLoading(false);
    if (response.httpCode === 200) {
      console.log(response.data.data);
      setScripts(response.data);
    } else {
      toast.error(
        response.httpCode +
          " Unable to load scripts! Try again:" +
          response.data.error ?? response.msg,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    }
  };

  useEffect(() => {
    fetchScripts();
  }, []);

  useEffect(() => {
    if (scripts ) {
      setLoading(false);
    }
  }, [scripts]);

  const search = async (data) => {

    if(data.scriptId && !data.robodialer.value){
      toast.error(
        "Please select Robodialer and Script ID",
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
      return
    }
    
    if (data?.robodialer) {
      data.robodialerId = data?.robodialer?.value ?? ""
      delete data?.robodialer
    }
    setIsSearching(true);
    const response =  await getScripts(data);
    setIsSearching(false);
    setLoading(false);
    if (response.httpCode === 200) {
      console.log(response.data);
      setScripts(response.data);
    } else {
      toast.error(
        response.httpCode +
          " Unable to load scripts! Try again:" +
          response.data.error ?? response.msg,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    }
  };
  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className="mx-4 mt-5 mb-20">
          <div className="flex items-center justify-start gap-x-2">
            <div className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
              Scripts
            </div>
            {
              <div className="font-jakarta text-superbill-slate-grey text-xl mb-4">
                <>{DateTime.fromJSDate(new Date()).toFormat("MMMM dd")}</>
              </div>
            }
          </div>
          {/* <div className="flex items-end justify-start gap-x-2 m-4 w-2/3">
            <div className="w-4/12">
              <label className="block text-gray-700">From:</label>
              <input
                type="date"
                ref={startDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div>
            <div className="w-4/12">
              <label className="block text-gray-700">To:</label>
              <input
                type="date"
                ref={endDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div>
            <div className="w-2/12">
              <label className="block text-gray-700">Status:</label>
              <select
                ref={statusRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              >
                <option value="">All</option>
                <option value="processing">Processing</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div className=" w-2/12 flex items-center justify-center">
              <button
                className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={search}
                disabled={isSearching}
              >
                {isSearching ? <Spinner /> : <>Search</>}
              </button>
            </div>
          </div> */}
          <FilterForm search={search} defaultValues={{
            scriptId: query.get("scriptId") ?? '',
            callId: query.get("callId") ?? '',
            batchStatus: query.get("batchStatus") ?? '',
            filterBy: query.get("filterBy") ?? 'createdAt',
            dateOption: query.get("dateOption") ?? 'today',
            dateFrom: query.get("dateFrom") ?? '',
            dateTo: query.get("dateTo") ?? '',
            robodialerId: query.get("robodialerId") ?? ''
          }} showBatchOption={false}/>
          <ScriptsTable refresh={() => {
            fetchScripts()
          }} scripts={scripts?.data ?? null} />
        </div>
      ) : null}
      
    </>
  );
}
