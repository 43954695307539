import './css/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

initializeApp({
  apiKey: atob('QUl6YVN5RGI0RUVWbmFSUnI4QmlhQVI0MVhDbTJQWXNlbUNFdG9V'),
  authDomain: 'superbill-a35cd.firebaseapp.com',
});

root.render(
  
    <App />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
