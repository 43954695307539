import React, { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import Loader from "components/Loader";
import { useForm } from "react-hook-form";
import { getPHData, getScriptTypes, updatePHData } from "apis/agent";
import { PhDataType, ReactSelectOptions } from "types/types";
import EditPhComponent from "./edit-ph";
import { toast } from "react-hot-toast";

type FormValues = {
  status: string;
  phoneNumber: string;
};

type AddIvrInstructionsPageProps = {
  onClose: () => void;
  onSuccess: () => void;
};
export default function AddIvrInstructionsPage({
  onClose,
  onSuccess,
}: AddIvrInstructionsPageProps) {
  const {
    register,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [phData, setPhData] = React.useState<PhDataType | null>(null);

  
  const toggleModal = () => {
    reset();
    onClose();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [phErrorMsg, setPhErrorMsg] = useState<string | null>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<Array<ReactSelectOptions>>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  
  useEffect(() => {
    let timeoutId;
    let delay = 1500;
    const fetchData = async () => {
      if (searchValue === null) {
        return
      }
      try {
        setLoading(true)
        const {data, httpCode} = await getPHData(searchValue);
        
        setPhData( httpCode == 200 ? data : {
          instructions:{},
          officeHours: {
            contains_office_hours: false,
            openSlots: {
            },
          }
        });
        setPhErrorMsg(httpCode == 200 ? null : data?.error);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(fetchData, delay);

    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptTypes();
      
      setOptions((data?.scripts ?? []).map( e => {
        return {'label': e.name, 'value': e.id}
      }))
      setPageLoading(false)
    }
    fetch();
  }, []);

  return (
    <>
      {pageLoading ? 
        <div className="w-full h-full flex items-center justify-center">
          <Loader size={42} />
        </div>
      :null}
      {!pageLoading ? <>
        {/* Navbar */}
        <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
          IvrInstruccions
        </nav>
        <div className="flex-grow overflow-y-auto p-9 flex items-center justify-center">
          <form className="flex flex-col w-full h-full">
            <div className="mt-1">
              <label
                className="block text-sm text-superbill-jacarta"
                htmlFor="phoneNumber"
              >
                Phone #
              </label>
              <input
                disabled={loading}
                value={searchValue!}
                className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                id="phoneNumber"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            {phErrorMsg ? 
              <div className='flex justify-between items-center w-full h-fit rounded bg-superbill-banner-red p-2 my-3 '>
                  <div className="flex justify-start items-center">
                    <div className='flex justify-start pl-2 items-center'>
                      <InfoIcon className='child:fill-superbill-ultra-red-hover min-w-[22px]' />
                    </div>
                    <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
                      {phErrorMsg}
                    </div>
                  </div>
                </div>
            :null}
            {phData ? 
              <div className="mt-5">
                <EditPhComponent formData={phData} setFormData={setPhData} options={options} />
              </div>
            : null}
          </form>
        </div>
        {/* Toolbar */}
        <div className="flex flex-row justify-between px-4 py-3">
          <button
            className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
            onClick={toggleModal}
          >
            Close
          </button>
          <div className="flex items-center">
            <button
              disabled={isLoading || !isValid}
              className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
              onClick={async () => {
                setIsLoading(true);
                const {data, httpCode} = await updatePHData(searchValue!, phData);
                setIsLoading(false);
                if(httpCode !== 200){
                  toast.error(httpCode + ' Unable to update Phonebook: ' + data?.error ?? '', {
                    iconTheme: {
                      primary: '#F36F82',
                      secondary: '#ffffff',
                    },
                  });
                }else{
                  toast.success('Phonebook updated successfully!',{
                    iconTheme: {
                      primary: '#4a43cd',
                      secondary: '#ffffff',
                    },
                  });
                  toggleModal();
                  onSuccess();
                }
                
              }}
            >
              {isLoading ? <Loader size={42} /> : <>Save</>}
            </button>
          </div>
        </div>
        </>
       : null}
    </>
  );
}
