import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};


type HangUpModalProps = {
  isOpen: boolean;
  onClose: () => void ;
  onSuccess?: () => void ;
}
export default function HangUpModal({ 
  isOpen,
  onClose,
  onSuccess}: HangUpModalProps) {
  //const [modalIsOpen, setModalIsOpen] = React.useState(true);
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Hangup call Modal"
      >
       <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Hang Up Call?
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <p className="text-superbill-jacarta text-sm"><strong>This call is part of a bundle.</strong> Only hang up if the representative is unable to answer your questions</p>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { onClose() }}
            >
              Stay on the call
            </button>
            <button
              onClick={onSuccess}
              className='bg-superbill-ultra-red disabled:bg-superbill-ultra-red/50 font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center'>
              <>Hang Up</>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
