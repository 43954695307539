import React from "react";
import Loader from "./components/Loader";
import logo from 'assets/superhero-loader.gif'

export default class MainLoading extends React.Component {

  render() {
    return (
      // <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      //   <Loader />
      // </div>
      <div className="w-full flex justify-center items-center mt-[20%]">
          {/* <Loader /> */}
          <img 
          src={logo}
          className="h-[115px]"
          />
        </div>
    )
  }
}