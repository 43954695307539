import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';

import Select, { components, OptionProps } from "react-select";
import toast, { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import ScriptForm from './components/script-form';
import SubmitFormComponent from './components/submit-form';
import { AssignNextCallInQueueResponse, getRobodialersResponseType, } from 'types/types';
import { discardAudit, getAttemptData, getRobodialers, getScript, getSignedAudio, loadHumanAudit, submitCall, loadRoboAudit, submitCallRobo, discardHumanAudit, submitCallHuman } from 'apis/agent';



import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useOutletContext } from 'react-router-dom';
import KeyValueGrid from './components/key-value-grid';
import ScriptOverview from './components/script-overview';
import AndyIssuesForm from './components/andy-issues-form';
import AndyIssuesFormWrapper from './components/andy-issues-form-wrapper';
import { AuditIssuesType } from '../../types/audit';

type AgentsAuditPageProps = {
  agentEmail: string
  agentSip: string
}
interface Option {
  value: boolean | string;
  other_reason?: string; //for CANNOT ANSWER - other
  label: string;
}

const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};
export default function AgentsAuditPage() {
  const query = useQuery();
  const [allIssues, setAllIssues] = useState<AuditIssuesType[]>([]);
  const { agentEmail, agentSip } = useOutletContext<AgentsAuditPageProps>()
  const [loading, setLoading] = useState<boolean>(true);
  const [btnisLoading, setBtnIsLoading] = useState<boolean>(false)
  const [robodialersData, setRobodialersData] = useState<null | getRobodialersResponseType>(null);
  const [expanded, setExpanded] = useState<string | false>('panel3');
  const [callData, setCallData] = useState<null | AssignNextCallInQueueResponse>(null)
  const [btnDiscardIsLoading, setBtnDiscardIsLoading] = useState<boolean>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getRobodialers();

      setRobodialersData(data)
      setLoading(false)
    }
    fetch()
  }, []);


  const { register, watch, handleSubmit, control, setValue, formState: { errors } } = useForm();
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Option | null }>({});
  const [scriptData, setScriptData] = useState<null | any>(null); //type TBD
  const [attemptData, setAttemptData] = useState<null | any>(null); //type TBD
  const [audioData, setAudioData] = useState<null | string>(null);

  const onSubmit = async (data: any) => {
    setBtnIsLoading(true)
    if(callData){
      try {
        await discard()
      } catch (error) {
        console.error('error discarding');
      }
    }
    const functToCall = watch('queueType') === 'robo' ? loadRoboAudit : loadHumanAudit;
    data['attempt'] = data['attempt'] || data['attempt_n'] || callData?.attempt_num
    let response = await functToCall({ ...data, ...{ retoolUser: { email: agentEmail } } });
    setBtnIsLoading(false)
    if(response.httpCode !== 200){
      toast.error(response.httpCode + ' ' + response.data.error ?? response.msg ?? 'Unable to get calls', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    setCallData(response.data);
    setExpanded(false)

  };

  useEffect(() => {
    if (!query) return
    const robodialerId = query.get("robodialerId") || "";
    const callId = query.get("callId") || "";
    const attemptId = query.get("attemptId") || "";
    
    if (robodialerId && callId){
      setExpanded('panel1')
      setValue('robodialer_id', robodialerId)
      setValue('call_id', callId)
      setValue('attempt_n', attemptId)
    }
    
    
  }, [query]);
  
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props} className="flex items-center">
        <div className="mx-1 ">{props.data.icon}</div>
        <div className="mx-1 font-sans">{props.data.label}</div>
      </components.SingleValue>
    );
  };
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center group">
          <div className="mx-1 fill-jacarta group-hover:fill-superbill-indigo">{props.data.icon}</div>
          <div className="mx-1">{props.data.label}</div>
        </div>
      </components.Option>
    );
  };
  
  const RobodialerSelect = (index) => {
    return (
      <>
        <label htmlFor="robodialerId" className="block text-xs font-bold text-gray-700 ">Robodialers</label>
        <Controller
          name={`${index}.robodialer`}
          control={control}
          render={({ field }) => (
            <Select
              placeholder={'Select Robodialer'}
              components={{
                Option,
                SingleValue: SingleValue,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,

              }}
              menuPortalTarget={document.body}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  '&:hover': { backgroundColor: '#EFF1F6', cursor: 'pointer' },
                  boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
                  borderColor: state.isFocused ? '#4a43cd' : '#ffffff',
                  zIndex: 11
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '15px',
                  fontFamily: 'DM Sans',
                  backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                  color: state.isFocused ? '#4a43cd' : '#38395B',
                  ':active': {
                    ...baseStyles[':active'],
                    backgroundColor: '#D0D3F2',
                  },
                })
              }}
              isSearchable={true}
              className='bg-white border-1 mb-2 block w-full focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 border border-superpay-soap'
              {...field}
              options={robodialersData?.robodialers?.map((e) => {
                return { value: e.id, label: e.name }
              })}
            />
          )}
          rules={{ required: false }}
        />
      </>
    )
  }

  const fetchScript = async () => {
    const response = await getScript(callData?._robodialer_id!, callData?.scriptId!, attemptData?.attemptData?.scriptVersion ?? null)
    if (response.httpCode === 200) {
      setScriptData(response.data)
    } else {
      toast.error(response.httpCode + ' Unable to load Script! Try again:' + response.data.error ?? response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const fetchAudio = async () => {
    const response = await getSignedAudio({ robodialer_id: callData?._robodialer_id!, call_id: callData?._call_id, attempt_n: callData?.attempt_num ??  callData?.success ?? callData?.n_attempts ?? 0 })
    if (response.httpCode === 200) {
      setAudioData(response.data?.url ?? null)
    } else {
      toast.error(response.httpCode + ' Unable to load Audio! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }
  const fetchAttemptData = async () => {
    const response = await getAttemptData({ robodialer_id: callData?._robodialer_id!, call_id: callData?._call_id, attempt_n: callData?.attempt_num ??  callData?.success ?? callData?.n_attempts ?? 0 })
    if (response.httpCode === 200) {
      setAttemptData(response.data)
      //{j2_kpis : {line_connected: true, reached_a_human: true}}
    } else {
      toast.error(response.httpCode + ' Unable to load transcript! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }
  
  const selectedOptionsValuesOnly = Object.fromEntries(
    Object.entries(selectedOptions).map(([key, value]) => {
      if (value?.value === 'CANNOT ANSWER - other') {
        if (value.other_reason && value.other_reason.trim() !== '') {
          return [key, `CANNOT ANSWER - ${value.other_reason}`];
        } else {
          return [key, ''];
        }
      }
      return [key, value?.value ?? value ?? ''];
    })
  );

  const validateIssuesArray =(issues: AuditIssuesType[]): { valid: boolean; missingFields?: string[]; line?: number }=>{
    for (let index = 0; index < issues.length; index++) {
        const issue = issues[index];
        const missingFields:string[] = [];
  
        if (!issue.componentId) {
            missingFields.push("componentId");
        }
        if (!issue.issueAndCorrectBehaviorDescription) {
            missingFields.push("issueAndCorrectBehaviorDescription");
        }
        if (!issue.issueCategoryId) {
            missingFields.push("issueCategoryId");
        }
  
        if (missingFields.length > 0) {
            return {
                valid: false,
                missingFields,
                line: index
            };
        }
    }
  
    return { valid: true };
  }
  
  const submitCallForm = async (data) => {

    if (data['outcome'].toLocaleLowerCase() === 'success' && !Object.values(selectedOptionsValuesOnly).every(value => value !== '' && value !== null && value !== undefined)) {
        console.log(Object.values(selectedOptionsValuesOnly))
      toast.error('Invalid answers inputs', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    let detailedReasonKey = ""
    let notesKey = ""
    switch (data['mainFailureReason']) {
      case 'input_missing':
        detailedReasonKey = "detailedMissingFailureReason"
        break;
      case 'input_incorrect':
        detailedReasonKey = "detailedIncorrectFailureReason"
        break;
      case 'phone':
        detailedReasonKey = "detailedPhoneFailureReason"
        break;
      case 'other':
        detailedReasonKey = "detailedOtherFailureReason"
        break;
      default:
        break;
    }
    switch (data['callOutcome']) {
      case 'failure':
        notesKey = 'failNotes'
        break;

      default:
        notesKey = 'callRetryReason'
        break;
    }

    const parsedData = {
      ...{
        "scriptVersionUsed": scriptData?.scriptVersion,
        "retoolUser": {
          "email": agentEmail,
        },
        "callResults": selectedOptionsValuesOnly,
        "combinedReason": [
          data['mainFailureReason'], data['detailedFailureReason']
        ],
        "internalNotes": ""
      },
      ...data
    }
    parsedData["phoneNumberValid"] = data["phoneNumberValid"] === 'true' ? true : false
    if (parsedData["callOutcome"]) {
      parsedData["callOutcome"] = (parsedData["callOutcome"].charAt(0).toUpperCase() + parsedData["callOutcome"].slice(1))
    }
    if (!parsedData["mainFailureReason"]) {
      parsedData["mainFailureReason"] = ''
    }
    if (!parsedData["detailedFailureReason"]) {
      parsedData["detailedFailureReason"] = ''
    }
    parsedData['lineConnected'] = data['lineConnected'] === 'true' ? true : false
    parsedData['humanReached'] = data['humanReached'] === 'true' ? true : false
    parsedData["outcome"] = parsedData["outcome"] === 'need_to_retry' ? 'Need To Retry' : (parsedData["outcome"].charAt(0).toUpperCase() + parsedData["outcome"].slice(1))
    parsedData[notesKey] = data['description']

    //!watch('callInitiatedAt') ||
    //!watch('callEndedAt') ||
    const now = DateTime.now();
    const formattedDate = now.toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
    let resultsApproved = 0;
    if (parsedData["outcome"] === 'Failure') {
      resultsApproved = 1
    } else if (parsedData["outcome"] === 'Success') {
      resultsApproved = 2
    } else {
      resultsApproved = 3
    }
    parsedData['callEndedAt'] = formattedDate;//data['callEndedAt'].toString()
    parsedData[detailedReasonKey] = data['detailedFailureReason']
    let failReasons = {
      "mainFailureReason": parsedData["mainFailureReason"],
    }
    failReasons[detailedReasonKey] = data['detailedFailureReason'];
    
    let submitObj = {
      scriptVersionUsed: scriptData?.scriptVersion,
      email: agentEmail,
      auditNotes: data['description'],
      phoneNumberValid: data["phoneNumberValid"] === 'true' ? true : false,
      auditType: watch('queueType') === 'robo' ? 'robo' : 'human',
      retoolUser: { email: agentEmail },
      genericAuditData: {
        callAuditResults: {
          auditedBy: agentEmail,
          auditedAt: new Date(),
          auditNotes: data['description'],
          resultsApproved //fail 1 success 2 ntr 3
        }
      },
      combinedReason: [
        data['mainFailureReason'], data['detailedFailureReason']
      ],
      detailedFailureReason: data['detailedFailureReason'],
      mainFailureReason: data['mainFailureReason'],
      outcome: parsedData["outcome"],
      robodialer_id: callData?._robodialer_id,
      call_id: callData?._call_id,
      attempt: callData?.attempt_num ?? callData?.success ?? callData?.n_attempts,
      callData: {
        robodialer_id: callData?._robodialer_id,
        call_id: callData?._call_id,
        attempt: callData?.attempt_num ?? callData?.success ?? callData?.n_attempts,
      },
      failReasons: failReasons,
      newResults: selectedOptionsValuesOnly,
      j2_kpis: {
        "reached_a_human": parsedData['humanReached'],
        "line_connected": parsedData['lineConnected']
      }
    }
    
    const functToCall = watch('queueType') === 'robo' ? submitCallRobo : submitCallHuman;
    const validationResult = validateIssuesArray(allIssues);
    if (!validationResult.valid) {
      toast.error(<span className='w-full'>Invalid Issues. Missing fields {validationResult?.missingFields?.join(', ')}. Line {(validationResult?.line ?? 0) + 1}</span>, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    submitObj['issues'] = allIssues;
    submitObj["betterNumberProvided"] = data['betterNumberProvided'] ?? null
    const { httpCode, msg, data: responseData } = await functToCall(submitObj)//parsedData)

    if (httpCode === 200) {
      setAllIssues([])
      toast.success('Call Submited!', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      setCallData(null)
      setScriptData(null)
    } else {
      toast.error(httpCode + ' Unable to Submit! Try again', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const filterObject = (obj: Record<string, any>): Record<string, string | number> => {

    let filtered =  Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => {
        // Trim the value if it's a string and filter out empty, null, undefined, or "-"
        if (value === null || value === undefined || value === '-') {
          return false;
        }
  
        if (typeof value === 'string') {
          const trimmedValue = value.trim();
          return trimmedValue !== '';
        }
        return typeof value === 'number' || typeof value === 'string';
      })
    );
    return filtered;
    
  }
  
  const discard = async () => {
    setBtnDiscardIsLoading(true)
    setAllIssues([])
    const functToCall = watch('queueType') === 'robo' ? discardAudit : discardHumanAudit;
    const { httpCode } = await functToCall({ retoolUser: { email: agentEmail } })
    if (httpCode === 200) {
      setAttemptData(null)
      setScriptData(null)
      setAudioData(null)
      setCallData(null)
      setExpanded('panel3')
    } else {
      toast.error(httpCode + ' Unable to Discard! Try again', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
    setBtnDiscardIsLoading(false)
  }


  useEffect(() => {
    if (callData && callData?._robodialer_id && callData?.scriptId) {
      if (callData?.attempt_num ||  callData?.success || callData?.n_attempts ) {
        fetchAudio()
        fetchAttemptData()
      }
    }
  }, [callData])

  useEffect(() => {
    if(attemptData){
      fetchScript()
      setSelectedOptions(Object.entries((attemptData?.attemptData?.results as Record<string, { answerType?: string, answer?: string }> ?? {})).reduce((acc, [key, value]) => {
        //if answer.value is empty, skip
        if (typeof value.answer === 'undefined' || value.answer === '' || value.answer === null) return acc;
            
        acc[key] = value.answer;
        acc[key] = value.answerType != 'multiple' && value.answer === 'Yes' ? true : value.answer;
        acc[key] = value.answerType != 'multiple' && value.answer === 'No' ? false : value.answer;
        
        return acc;
      }, {}))
    }
  }, [attemptData])

  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className="mx-4 mt-5 mb-20">
          <div className="w-full mt-5">
          <Accordion className={`!rounded-b`}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className='!cursor-default'
            >
              <div className='w-full justify-end items-center gap-x-3 flex'>
                <label className="cursor-pointer justify-start items-center sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150">
                  <input
                    {...register('queueType', { required: 'This field is required' })}
                    type="radio"
                    disabled={!!callData}
                    defaultChecked={true}
                    value="robo"
                    className="mr-1 cursor-pointer"
                  />
                  <span className={`${!!callData ? 'text-superbill-gray-2' :'text-jacarta'} text-sm`}>Audit Robo Queue</span>
                </label>
                <label className="cursor-pointer justify-start items-center sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150">
                  <input
                    {...register('queueType', { required: 'This field is required' })}
                    type="radio"
                    disabled={!!callData}
                    value="human"
                    className="mr-1 cursor-pointer"
                  />
                  <span className={`${!!callData ? 'text-superbill-gray-2' :'text-jacarta'} text-sm`}>Audit Human Queue</span>
                </label>
              </div>
            </AccordionSummary>
            </Accordion>
            
          </div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={` ${expanded === 'panel1' ? '!rounded' : '!rounded-b'}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <span className='font-bold text-superbill-jacarta text-sm'>Load specific call</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="mt-3 w-full grid grid-cols-9 gap-x-2 gap-y-3">
                <div className="col-span-7">
                  {RobodialerSelect(1)}
                </div>
                <div className="col-span-2 flex items-center justify-center">
                  <button
                    className='mt-2 w-full bg-green-600 disabled:bg-superbill-aero-green font-jakarta hover:bg-green-900 text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                    onClick={() => {
                      setValue('robodialer_id', watch(`1.robodialer`)?.value ?? '')
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
              <form className="mt-3 w-full grid grid-cols-9 gap-x-2 gap-y-3">
                <div className="col-span-3">
                  <label htmlFor="robodialerId" className="block text-xs font-bold text-gray-700 ">Robodialer ID</label>
                  <input id="robodialerId" type="text"
                    className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                    {...register('robodialer_id', { required: true })} />
                  {errors.robodialerId && <span className="text-red-500 text-sm">Required field</span>}
                </div>
                <div className="col-span-3">
                  <label htmlFor="CallId" className="block text-xs font-bold text-gray-700 ">Call ID</label>
                  <input id="CallId" type="text"
                    className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                    {...register('call_id', { required: true })} />
                  {errors.CallId && <span className="text-red-500 text-sm">Required field</span>}
                </div>
                <div className="col-span-3">
                  <label htmlFor="Attempt" className="block text-xs font-bold text-gray-700 ">Attempt (optional)</label>
                  <input id="Attempt" type="text"
                    className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                    {...register('attempt_n')} />
                </div>
              </form>
              <div className="flex items-center justify-center w-full mt-3">
                <button className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4'
                  onClick={() => {
                    if(!watch('robodialer_id') || !watch('call_id')){
                      toast.error('All inputs are required', {
                        iconTheme: {
                          primary: '#F36F82',
                          secondary: '#ffffff',
                        },
                      });
                      return
                    }
                    onSubmit({
                      robodialer_id: watch('robodialer_id'),
                      call_id: watch('call_id'),
                      attempt_n: watch('attempt_n'),
                    })
                  }}
                  disabled={btnisLoading}
                >
                  {btnisLoading ? <Spinner /> : <>Get Call</>}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={` ${expanded === 'panel2' ? '!rounded' : '!rounded-b'}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                <span className='font-bold text-superbill-jacarta text-sm'>Load from batch</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="mt-3 w-full grid grid-cols-9 gap-x-2 gap-y-3">
                <div className="col-span-7">
                  {RobodialerSelect(2)}
                </div>
                <div className="col-span-2 flex items-center justify-center">
                  <button
                    className='mt-2 w-full bg-green-600 disabled:bg-superbill-aero-green font-jakarta hover:bg-green-900 text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                    onClick={() => {
                      setValue('robodialer_id2', watch(`2.robodialer`)?.value ?? '')
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
              <form className="mt-3 w-full grid grid-cols-8 gap-x-2 gap-y-3">
                <div className="col-span-4">
                  <label htmlFor="robodialerId2" className="block text-xs font-bold text-gray-700 ">Robodialer ID</label>
                  <input id="robodialerId2" type="text"
                    className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                    {...register('robodialer_id2', { required: true })} />
                  {errors.robodialerId && <span className="text-red-500 text-sm">Required field</span>}
                </div>
                <div className="col-span-4">
                  <label htmlFor="batchId" className="block text-xs font-bold text-gray-700 ">Batch ID</label>
                  <input id="batchId" type="text"
                    className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                    {...register('batch_id', { required: true })} />
                  {errors.batchId && <span className="text-red-500 text-sm">Required field</span>}
                </div>
              </form>
              <div className="flex items-center justify-center w-full mt-3">
                <button className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4'
                  onClick={() => {
                    if(!watch('robodialer_id2') || !watch('batch_id')){
                      toast.error('All inputs are required', {
                        iconTheme: {
                          primary: '#F36F82',
                          secondary: '#ffffff',
                        },
                      });
                      return
                    }
                    onSubmit({
                      robodialer_id: watch('robodialer_id2'),
                      batch_id: watch('batch_id'),
                    })
                  }}
                  disabled={btnisLoading}
                >
                  {btnisLoading ? <Spinner /> : <>Get Call</>}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className={` ${expanded === 'panel3' ? '!rounded' : '!rounded-b'}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                <span className='font-bold text-superbill-jacarta text-sm'>Load from queue</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex items-center justify-center w-full mt-3">
                <button className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4'
                  onClick={() => {
                    onSubmit({})
                  }}
                  disabled={btnisLoading}
                >
                  {btnisLoading ? <Spinner /> : <>Get Next Call</>}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          {callData ?
            <>
              <div className="flex justify-end items-center mt-5">
                <button className='bg-superbill-ultra-red disabled:bg-superbill-ultra-red/50 font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center' onClick={discard} disabled={!callData}>
                  {btnDiscardIsLoading ? <Spinner /> : <>Discard call</>}
                </button>
              </div>
              <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-4 mx-4'>
                    <KeyValueGrid
                      data={{
                        start: attemptData?.attemptData?.start ?? '-',
                        end: attemptData?.attemptData?.end ?? '-'
                      }} />
                  </div>
              <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
                <div className='overflow-y-auto h-[350px] border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white'>
                  <div className="flex items-center w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Call Information</div>
                  </div>
                  <div className='border border-superpay-soap divide-y divide-superpay-soap rounded'>
                    <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                      <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Assigned to:</span>
                      <span className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>{attemptData?.attemptData?.retoolUser?.email ?? callData?.assignedToRetoolUser}</span>
                    </div>
                    <KeyValueGrid
                      data={{
                        "Outcome": callData?.outcome ?? callData?.supportTeamOutcome ?? "-",
                        "Placed At": callData?.end ?? "-",
                        "Robodialer ID": callData?._robodialer_id,
                        "Call ID": callData?._call_id,
                        "Attempts": callData?.n_attempts ?? '-',
                        "Attempt": callData?.attempt_num ?? callData?.success ?? callData?.n_attempts,
                        "Success": callData?.success,
                        "Script ID": callData?.scriptId,
                        "Batch ID": callData?.batchId,
                      }} />
                  </div>
                </div>
                <div className='overflow-y-auto h-[350px] border border-superpay-soap rounded p-2 col-span-6 bg-superbill-anti-flash-white'>
                  <div className="flex items-center w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Inputs Information</div>
                  </div>
                  {callData?.inputs ?
                    <div className='border border-superpay-soap divide-y divide-superpay-soap rounded'>
                      <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                        <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Inputs</div>
                        <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Value</div>
                      </div>
                      <KeyValueGrid aspect="4-6" data={filterObject(callData.inputs ?? {})} />
                    </div>
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
                <div className='overflow-y-auto h-[600px] bg-superbill-anti-flash-white border border-superpay-soap rounded p-2 col-span-4'>
                  <div className="flex items-center justify-between w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Audio <span className='text-sm'>(Attempt: {callData?.attempt_num ??  callData?.success ?? callData?.n_attempts})</span></div>
                    <div className='cursor-pointer hover-underline-animation text-xs' onClick={fetchAudio}>Reload</div>
                  </div>
                  {audioData ? <div className='w-full items-center flex justify-center bg-white p-2'>
                    <audio src={audioData ?? ""} controls />
                  </div>
                    : null}
                  <div className="flex items-center justify-between w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Transcript <span className='text-sm'>(Attempt: {callData?.attempt_num ??  callData?.success ?? callData?.n_attempts})</span></div>
                    <div className='cursor-pointer hover-underline-animation text-xs' onClick={fetchAttemptData}>Reload</div>
                  </div>
                  {attemptData?.attemptData ? <div className='bg-white p-2 w-full'>
                    {(attemptData?.attemptData?.transcript ?? "").split('\n').map((line) => (
                      <div className='text-xs text-superbill-jacarta'>{line ?? '-'}</div>
                    ))}
                  </div>
                    : null}
                </div>
                <div className='overflow-y-auto h-[600px] bg-superbill-table-grey border border-superpay-soap rounded p-2 col-span-6'>
                  <div className="flex items-center justify-start w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Form</div>
                  </div>
                  {scriptData ?
                    <div className="border border-superpay-soap rounded p-2 bg-white">
                      <ScriptForm callInputs={callData?.inputs as Record<string, string>} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} questions={scriptData?.questions || []} />
                    </div>
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
                <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white'>
                  <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-2'>
                    <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                      <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Question ID</div>
                      <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Answer</div>
                    </div>
                    <KeyValueGrid aspect="4-6" data={selectedOptionsValuesOnly as Record<string, string>} />
                  </div>
                </div>
                <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-6 bg-superbill-anti-flash-white'>
                  <div className="flex items-center justify-between w-full py-2">
                    <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Script</div>
                    <div className='cursor-pointer hover-underline-animation text-xs' onClick={fetchScript}>Reload</div>
                  </div>
                  {scriptData ?
                    <div className="border border-superpay-soap rounded p-2 bg-white">
                      {/* <ScriptOverview2 questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>}/> */}
                      <ScriptOverview questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>} />
                    </div>
                    : null}
                </div>
              </div>
               {attemptData ? 
                <>
                  <div className='mt-4 mx-4 border border-superpay-soap p-4 bg-superbill-table-grey rounded'>
                    <SubmitFormComponent onSubmit={submitCallForm} 
                    // string to lowercase y reemplazar espacios por _ -> replace(/\s/g, '_')
                    formData={{
                      outcome: (attemptData?.attemptData?.outcome ?? attemptData?.attemptData?.roboOutcome ?? '').toLowerCase()?.replace(/\s/g, '_'),
                      phoneNumberValid: (attemptData?.attemptData?.phoneNumberValid ?? '').toString(),
                      lineConnected: (attemptData?.attemptData?.j2_kpis?.lineConnected ?? attemptData?.attemptData?.lineConnected ?? '').toString(),
                      humanReached: (attemptData?.attemptData?.j2_kpis?.humanReached ?? attemptData?.attemptData?.humanReached ?? '').toString(),
                      detailedFailureReason: attemptData?.attemptData?.detailedFailureReason,
                      mainFailureReason: attemptData?.attemptData?.mainFailureReason,
                      tryAgainIn: attemptData?.attemptData?.tryAgainIn?.toString(),
                      description: attemptData?.attemptData?.auditNotes || attemptData?.attemptData?.failNotes || attemptData?.attemptData?.callRetryReason || attemptData?.attemptData?.description,
                    }}/>
                  </div>
                  <div className='mt-4 mx-4 border border-superpay-soap p-4 bg-superbill-table-grey rounded'>
                    <AndyIssuesFormWrapper issues={attemptData?.attemptData?.issues ?? []} allIssues={allIssues} setAllIssues={setAllIssues} 
                      robodialer_id= {callData?._robodialer_id! }
                      call_id= {callData?._call_id }
                      attempt_id= {String(callData?.attempt_num ??  callData?.success ?? callData?.n_attempts ?? '0')}
                    />
                  </div>
                </>
              :null}
            </>
            : null}
        </div>

      ) : null}
    </>
  );
}
