import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { ReactComponent as CopyIcon } from "assets/copy.svg";
import toast from 'react-hot-toast';
import { RobodialerUser } from 'types/types';
import { getRobodialerUsers} from 'apis/robodialer';
import data from './resources.json';
import Accordion from "components/general/accordion";


const ResourcesPage: React.FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Array<RobodialerUser> |  null>([])
  const claimStatus = data.claim_status;


  useEffect(() => {
    const fetch = async () => {
      const allowlistResponse = await getRobodialerUsers();
      const usersList = Object.entries(allowlistResponse.data).map(
        ([id, email]) => {
          return {
            id,
            ...email,
          };
        }
      );
      setUsers(usersList);
      
    }
    fetch()
  }, []);

  useEffect(() => {
    if (users) {
      setIsLoading(false)
    }
  }, [users])

  const onCopy = (text) => {

    navigator.clipboard.writeText(text);
    toast.success('Copied number to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }
  return (
    <>
      {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
        < Loader />
      </div>) : null}
      {!isLoading ? (
      <>
      <div className='text-superbill-jacarta font-jakarta font-semibold text-xl'>
        Resources
      </div>
      <div className='text-superbill-jacarta text-sm my-2 font-sans'>Below you'll resources for payer phone numbers as well as answers to frequently asked questions.</div>
      <div className='text-superbill-slate-grey font-semibold text-lg my-6 font-jakarta'>Payer Numbers</div>
        <div className='w-full max-w-[600px]'>
          <Accordion title={'Claim Status'} content={
            <>
              <div className='bg-white border border-superbill-soap divide-y divide-superbill-soap block w-full rounded mt-2 font-sans'>
              <div className='grid grid-cols-3 gap-4 content-center items-center bg-superbill-anti-flash-white rounded-t' >
              <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1 rounded-tl">
                <div className='flex w-full h-full justify-left items-center'>
                  <span className='text-sm text-superbill-jacarta font-bold'>
                    Payer</span>
                </div>
              </div>
              <div className="col-span-1 bg-superbill-anti-flash-white px-2 py-1 rounded-tr">
                <div className='flex w-full h-full justify-left items-center'>
                  <span className='text-sm text-superbill-jacarta font-bold'>
                    Number</span>
                </div>
              </div>
                    </div>
              {(claimStatus)?.map((item) => {
                  return (
                    item.number !== null ? 
                  <>
                    <div className='grid grid-cols-3 gap-4 content-center items-center hover:bg-superbill-lavender/50  ease-in-out duration-150 last:rounded-b' key={item?.payer} >
                      <div className="col-span-2 px-2 py-1">
                        <div className='flex w-full h-full justify-left items-center'>
                          <span className='text-sm text-superbill-jacarta'>
                            {item.payer}</span>
                        </div>
                      </div>
                      <div className="col-span-1 px-2 py-1">
                        <div className='flex w-full h-full justify-left items-center'>
                          <span className='text-sm text-superbill-slate-grey flex justify-between w-full group'>
                            {item.number}
                            <CopyIcon className='hidden group-hover:block cursor-pointer hover:fill-superbill-indigo'
                            onClick={() => { onCopy(item.number) }}
                            />
                            </span>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <></>
                  )
                  })}
              </div>
            </>
            }
          />
          <div className='text-superbill-slate-grey font-semibold text-lg my-6 font-jakarta'>Script Support</div>
          <>
          <Accordion title={'When I place calls, which version of the script will be used?'} content={<div className="font-sans text-sm text-superbill-secondary">
              When you place a call, we will use the version of the script at the <strong>time we make the call</strong>. It could take up to 2 business days for us to make the call, so if you edit your script in that time period those changes may or may not be reflected in our calls. We do not recommend editing a script when calls are “in progress”.
            </div>} />
            <Accordion title={'What are some examples of questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
              The most successful questions are phrased as standalone, straightforward questions. For example, if you’d like to ask about coverage for specific codes, write the question as “Is there coverage for D-4346?” or “Does the plan cover code D-4346?” We would not recommend writing questions with multiple components, such as “What percentage is covered and does it apply to the deductible?”
            </div>} />
            <Accordion title={'How do I re-order questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
              To reorder questions, drag the purple sliver on the left side of the question, and place it where you’d like the question to go.
            </div>} />
            <Accordion title={'How do I duplicate questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
              To duplicate a question, click the kebab menu (vertical 3 dots) and select “Duplicate” from the dropdown.
            </div>} />
          </>
        </div>
      </>
      ) : null}
    </>
  );
};

export default ResourcesPage;