/**
 * 
 * @param inputs object
 * @returns Boolean 
 */
export const downloadInputsCsv = async (inputs: Object) => {
  if ( 
    !inputs ||
    typeof inputs !== 'object' ||
    Object.keys(inputs).length === 0) {
    return false;
  }
  const headers = Object.keys(inputs);
  
  const dummyData = headers.map(() => "exampleCsv");

  const csvContent = `${headers.join(",")}\n${dummyData.join(",")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `inputs.csv`);
  link.click();
  return true
}