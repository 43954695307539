import React, { useEffect } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm, Controller } from 'react-hook-form';
import Modal from "react-modal";
import { PlaceDemoCallsModalStates } from "enums/enums";
import { Script } from "types/types";
import SelectionPage from "./selection-page";
import { useNavigate } from "react-router-dom";
import SuccessPage from "./success-page";
import { getScripts, placeDemoCall } from "apis/robodialer";
// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
type PlaceDemoCallsModalProps = {
  modalState?: PlaceDemoCallsModalStates;
  isOpen?: boolean;
  onClose?: () => void;
  selectedScriptId?: string;
  onSuccess?: () => void;
}
export default function PlaceDemoCallsModal({ modalState = PlaceDemoCallsModalStates.ScriptSelection,
  isOpen,
  onClose,
  selectedScriptId,
  onSuccess, }: PlaceDemoCallsModalProps) {
  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
  const [scriptData, setScriptData] = React.useState<Array<Script> | null>(null);
  const navigate = useNavigate();
  const [currentState, setCurrentState] = React.useState<PlaceDemoCallsModalStates>(modalState);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {

    const fetchScripts = async () => {
      const { data, httpCode } = await getScripts();
      if (httpCode !== 200) {
        return;
      }
      setScriptData(data)

    }
    fetchScripts()
  }, []);

  const toggleModal = () => {
    setSuccess(false)
    setCurrentState(PlaceDemoCallsModalStates.ScriptSelection)
    onClose ? onClose() : (() => { })();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        {currentState === PlaceDemoCallsModalStates.ScriptSelection ? (
          <div className="flex flex-col h-full divide-y font-sans">
            <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
              Place a Demo Call
            </nav>
            <div className="flex-grow overflow-y-auto p-9">
              <SelectionPage scriptData={scriptData} register={register} control={control} loading={loading} setLoading={setLoading} success={success} watch={watch}/>
            </div>
            <div className="flex flex-row justify-between px-4 py-3">
              <button
                className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                onClick={onClose}
              >
                Close
              </button>
              {!success ?
                <button
                  disabled={!isValid || loading}
                  className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                  onClick={handleSubmit(async (e) => {
                    console.log(e);
                    setLoading(true)
                    const {httpCode} = await placeDemoCall({
                      scriptId: e.script.value,
                      to: e.phone
                    })
                    if(httpCode === 200){
                      setSuccess(true)
                    }
                    setLoading(false)
                  })}
                >
                  {loading ? <Spinner /> : <>Call me</>}
                </button>
              :null}
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
