import { RobodialerComponentsResponseType, SubmitIssuesResponseType } from "types/responses";
import { validateRobodialerComponentsResponse } from "apis/validators/audit_validators";
import { makeTypedRequest} from "apis/agent";
import { Response } from "types/responses";
import { AuditIssuesType } from "types/audit";


type getRobodialerComponentsResponse = Response<RobodialerComponentsResponseType>;
export async function fetchRobodialerComponents(): Promise<getRobodialerComponentsResponse> {
  const url = '/audit/robodialer-components'
  const method = 'GET';
  const auth = true;
  const { data, msg, httpCode } = await makeTypedRequest(url, method, auth, {}, null, false, 'sbs');
  // Validate and parse the response data
  const validatedData = validateRobodialerComponentsResponse(data);
  return {
    data:validatedData, msg, httpCode
  };
}


type submitAuditIssuesResponse = Response<SubmitIssuesResponseType>;



type submitAuditIssuesProps = {
  robodialer_id: string;
  call_id: string;
  attempt_id: string;
  issues: AuditIssuesType[]
}
export async function submitAuditIssues(data: submitAuditIssuesProps): Promise<submitAuditIssuesResponse> {
  const url = '/v1/sidekick/submit-issues'
  const method = 'POST';
  const auth = true;
  const response = await makeTypedRequest(url, method, auth, data, null, false, 'sbs');
  return response
}

