import React, { useEffect } from "react";
import Modal from "react-modal";
import { SimulateCallModalStates } from "enums/enums";

import { RunScriptErrosType, Script } from "types/types";
import SelectionPage from "./selection-page";
import { useNavigate } from "react-router-dom";
// custom styles
const customStyles = {
  content: {
    height: "max(50%,350px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
type SimulateCallModalProps = {
  modalState?: SimulateCallModalStates;
  scriptData: Array<Script> | null;
  isOpen: boolean;
  onClose: () => void ;
  onSuccess?: () => void ;
}
export default function SimulateCallModal({ modalState = SimulateCallModalStates.ScriptSelection,
  isOpen,
  onClose,
  onSuccess = () => {},
  scriptData }: SimulateCallModalProps) {
  const [currentState, setCurrentState] = React.useState<SimulateCallModalStates>(modalState);
  
  const toggleModal = () => {
    setCurrentState(SimulateCallModalStates.ScriptSelection)
    onClose()
  };
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        {currentState === SimulateCallModalStates.ScriptSelection ? (
          <SelectionPage scriptData={scriptData} onClose={toggleModal} onSuccess={() => {
            onSuccess()
         }}/>
        ) : null}
      </Modal>
    </div>
  );
};
