import React, { useEffect } from "react";
import { ReactComponent as XIcon } from 'assets/x.svg';
import Modal from "react-modal";
import ClientStatus from "./ClientStatus";


// custom styles
const customStyles = {
  content: {
    height: "100%",
    maxWidth: "100%",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    inset: '10px',
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "20",
  },
};

// components
type SidekickLogsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  cStatus: string[];
  userActions: string[];
  fesponses: string[];
  lastStatus: React.ReactNode;
  telnyxEvents: {type:string, ev: Element | JSX.Element | React.ReactNode}[];
  handleStatusChange: (status: string[]) => void;
  copyLogs: () => void;
  isMicrophoneAllowed: boolean;
  isOnLine: boolean;
  client: any;
}
export default function SidekickLogsModal({ isOpen,
  onClose,
  cStatus,
  userActions,
  lastStatus,
  fesponses,
  telnyxEvents,
  handleStatusChange,
  isMicrophoneAllowed,
  copyLogs,
  isOnLine,
  client, }: SidekickLogsModalProps) {


  const toggleModal = () => {
    onClose()
  };

  return (
    <div>
      <Modal
        class='z-20'
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Sidekick LOGs Modal"
      >
        <div className="flex flex-col h-full divide-y z-20">
          <div className="flex justify-between items-center z-20">
            <nav className='font-jakarta text-superbill-jacarta font-bold text-lg px-6 py-4 w-full'>
              Logs
            </nav>
            {/* <button className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-3 text-xs border border-superbill-soap rounded-full ease-in-out duration-300 w-fit text-nowrap mr-2' onClick={() => copyLogs()}>
              Copy Logs
            </button> */}

            <XIcon className="z-20 w-4 h-4 mr-4 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey cursor-pointer ease-in-out duration-150 min-w-[14px] z-50" onClick={onClose} />
          </div>
          <div className="flex-grow p-3">
            <div className="grid grid-cols-12">
              <div className='col-span-3'><strong className="text-xs text-superbill-jacarta">Mic enabled:</strong> {isMicrophoneAllowed ? <span className="text-xs text-green-700">Yes</span> : <span className="text-xs text-superbill-ultra-red-hover">No</span>}</div>
              <div className='col-span-3'><strong className="text-xs text-superbill-jacarta">Client enabled:</strong> {client?.connected ? <span className="text-xs text-green-700">Yes</span> : <span className="text-xs text-superbill-ultra-red-hover">No</span>}</div>
              <div className='col-span-3'><strong className="text-xs text-superbill-jacarta">Online: </strong>{isOnLine ? <span className="text-xs text-green-700">Yes</span> : <span className="text-xs text-superbill-ultra-red-hover">No</span>}</div>
              <div className='flex flex-col items-center col-span-3 gap-x-2'><strong className="text-xs text-superbill-jacarta">Last Status: </strong>{lastStatus} <span className="hover-underline-animation cursor-pointer text-xs" onClick={() => {
                console.log('client.connect()');
                
                client.connect();
              }}>Reconnect</span></div>
            </div>
            <div className="grid grid-cols-12 border rounded border-superpay-soap p-2 bg-superbill-anti-flash-white divide divide-superbill-soap">
              <div className='col-span-2 border border-superpay-soap p-1 overflow-y-auto'>
                <div className='font-bold text-superbill-jacarta text-base'>Conn Status:</div>
                {cStatus.map((e, i) => {
                  return <div key={'cStatus' + i} className='text-xs text-superbill-jacarta italic'>Was Online:{e}</div>
                })}
              </div>
              <div className='col-span-2 border border-superpay-soap p-1 overflow-y-auto'>
                <div className='font-bold text-superbill-jacarta text-base'>User Actions</div>
                {userActions.map((e, i) => {
                  return <div key={'userActions' + i} className='text-xs text-superbill-jacarta italic'>{e}</div>
                })}
              </div>
              <div className='col-span-3 border border-superpay-soap p-1 overflow-y-auto'>
                <div className='font-bold text-superbill-jacarta text-base'>Api Responses</div>
                {fesponses.map((e, i) => {
                  return <div key={'fesponses' + i} className='text-xs text-superbill-jacarta italic'>{e}</div>
                })}
              </div>
              <div className='col-span-3 border border-superpay-soap p-1 overflow-y-auto'>
                <div className='font-bold text-superbill-jacarta text-base'>Main Telnyx Events</div>
                {telnyxEvents.filter((e) => e.type === 'main').map((e, i) => {
                  return <span key={'telnyxEvents_main' + i}>
                    {e?.ev as React.ReactNode}
                  </span>
                })}
              </div>
              <div className='col-span-2 border border-superpay-soap p-1 overflow-y-auto'>
                <div className='font-bold text-superbill-jacarta text-base'>Other Telnyx Events</div>
                {telnyxEvents.filter((e) => e.type !== 'main').map((e, i) => {
                  return <span key={'telnyxEvents_not_main' + i}>
                    {e?.ev as React.ReactNode}
                  </span>
                })}
              </div>
              

            </div>
          </div>
          {/* <div className="flex flex-row justify-center px-4 py-3 sticky bottom-0 bg-white">
                <div
                  className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 cursor-pointer'
                >
                  Contact Support
                </div>

              </div> */}
        </div>
      </Modal>
    </div>
  );
};
