/**
 * All possible answer types with their corresponding labels
 */
export const ANSWER_TYPES = {
  BOOLEAN: "boolean",
  TEXT: "text",
  NUMBER: "number",
  ALPHANUMERIC: "alphanumeric",
  DOLLAR: "dollar",
  DATE: "date",
  ADDRESS: "address",
  PHONE_NUMBER: "phoneNumber",
  FAX_NUMBER: "faxNumber",
  MULTIPLE: "multiple"
} as const;

/**
 * Type for answer type values
 */
export type AnswerType = typeof ANSWER_TYPES[keyof typeof ANSWER_TYPES];

/**
 * Type for answer type options with labels
 */
export const ANSWER_TYPE_OPTIONS = [
  { value: ANSWER_TYPES.BOOLEAN, label: "Yes/No" },
  { value: ANSWER_TYPES.TEXT, label: "Text" },
  { value: ANSWER_TYPES.NUMBER, label: "Number" },
  { value: ANSWER_TYPES.ALPHANUMERIC, label: "Alphanumeric" },
  { value: ANSWER_TYPES.DOLLAR, label: "Dollar" },
  { value: ANSWER_TYPES.DATE, label: "Date" },
  { value: ANSWER_TYPES.ADDRESS, label: "Address" },
  { value: ANSWER_TYPES.PHONE_NUMBER, label: "Phone Number" },
  { value: ANSWER_TYPES.FAX_NUMBER, label: "Fax Number" },
  { value: ANSWER_TYPES.MULTIPLE, label: "Multiple Choice" }
] as const;

/**
 * Base properties that all questions share
 */
export interface BaseQuestion {
  question: string;
  questions: BaseQuestion[]; // recursive type fer GetScriptOnly
  questionId: string;
  answerType: AnswerType;
  backgroundContext?: string;
  responseInstruction?: string;
  optional: boolean;
  confirmationRequired: boolean;
}

/**
 * Follow-up structure for boolean questions (Yes/No)
 */
interface BooleanFollowUps {
  Yes?: Question[];
  No?: Question[];
}

/**
 * Follow-up structure for multiple choice questions
 */
interface MultipleFollowUps {
  multiple: {
    [key: string]: Question[];
  };
}

/**
 * Question with multiple choice options
 */
interface MultipleChoiceQuestion extends BaseQuestion {
  answerType: typeof ANSWER_TYPES.MULTIPLE;
  answerOptions: string[];
  followUps: MultipleFollowUps;
  
}

/**
 * Question with boolean answer
 */
interface BooleanQuestion extends BaseQuestion {
  answerType: typeof ANSWER_TYPES.BOOLEAN;
  followUps?: BooleanFollowUps;
}

/**
 * Question with simple input types (text, number, etc.)
 */
interface SimpleQuestion extends BaseQuestion {
  answerType: Exclude<AnswerType, typeof ANSWER_TYPES.BOOLEAN | typeof ANSWER_TYPES.MULTIPLE>;
  followUps?: Record<string, never>; // empty object
}

/**
 * Union type for all possible question types
 */
export type Question = MultipleChoiceQuestion | BooleanQuestion | SimpleQuestion;

/**
 * Type guards for different question types
 */
export const isMultipleChoiceQuestion = (question: Question): question is MultipleChoiceQuestion => {
  return question.answerType === ANSWER_TYPES.MULTIPLE;
};

export const isBooleanQuestion = (question: Question): question is BooleanQuestion => {
  return question.answerType === ANSWER_TYPES.BOOLEAN;
};

export const isSimpleQuestion = (question: Question): question is SimpleQuestion => {
  return question.answerType !== ANSWER_TYPES.BOOLEAN && 
         question.answerType !== ANSWER_TYPES.MULTIPLE;
};

/**
 * Helper to get the label for an answer type
 */
export const getAnswerTypeLabel = (type: AnswerType): string => {
  const option = ANSWER_TYPE_OPTIONS.find(opt => opt.value === type);
  return option?.label || type;
};

/**
 * Validator for question structure
 */
export const validateQuestion = (question: Question): boolean => {
  // Validate basic properties
  if (!question.question || !question.questionId || !question.answerType) {
    return false;
  }

  // Validate answer type specific properties
  if (isMultipleChoiceQuestion(question)) {
    if (!Array.isArray(question.answerOptions) || question.answerOptions.length === 0) {
      return false;
    }
    if (!question.followUps?.multiple) {
      return false;
    }
  }

  if (isBooleanQuestion(question)) {
    if (question.followUps && !('Yes' in question.followUps || 'No' in question.followUps)) {
      return false;
    }
  }

  return true;
};

/**
 * Parser to validate and type the incoming JSON
 */
export const parseQuestions = (json: string): Question[] => {
  const questions = JSON.parse(json) as Question[];
  
  const validQuestions = questions.every(validateQuestion);
  if (!validQuestions) {
    throw new Error('Invalid question structure detected');
  }

  return questions;
};