import React, { useState, useMemo, useEffect } from "react";
import { ReactComponent as FilterIcon } from "assets/filters.svg";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { Tooltip } from "@material-tailwind/react";
import toast, { Toaster } from 'react-hot-toast';
import Loader from "components/Loader";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import DraggableList from "./components/draggable-list";
import { getBatches, getPrioQueue, getRobodialers, updatePrioQueue } from "../../apis/agent";
import KeyValueGrid from "./components/key-value-grid";
import QMToolsFilterModal from "./qm-tools-page-filter-modal";
import { FilterFormValues } from "./components/filter-form";

interface Item {
    id: string;
    priority: number;
    robodialer_name: string;
    robodialer_id: string;
    batch_id: string | null;
    completed: boolean | null;
}

interface RobodialerBannedOrAllowedItem {
    robodialer_name: string;
    robodialer_id: string;
}
interface RobodialerPrioItem {
    priority: number;
    robodialer_name: string;
    robodialer_id: string;
}

interface BatchPrioItem {
    priority: number;
    robodialerName: string;
    robodialerId: string;
    batch_id: string;
    completed: boolean;
}



const useQuery = () => {
    const location = useLocation();
    return useMemo(() => new URLSearchParams(location.search), [location.search]);
};

interface FormValues {
    name: string;
    id: string;
}


export default function QMToolsPage() {

    const [sourceData, setSourceData] = useState<null | {
        id: string;
        name: string;
        robodialerName?: string;
        robodialerId?: string;
        batchId?: string;
    }[]>(null);
    const [errorID, setErrorID] = useState<string | null>(null);
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);
    const [isFilterModalOpened, setIsFilterModalOpened] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [selectValue, setSelectValue] = useState("fusion");
    const [selectQueueType, setSelectQueueType] = useState("customers");
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [pendingSelectValue, setPendingSelectValue] = useState("");
    const [pendingSelectValueQueue, setPendingSelectValueQueue] = useState("");
    const [filterValues, setFilterValues] = useState<FilterFormValues>({
        dateOption: 'sinceBeginning',
        batchStatus: 'processing',
        filterBy: 'createdAt',
    });

    const [loading, setLoading] = useState<boolean>(false);


    const [items, setItems] = useState<Item[]>([]);
    
    const fetchRobodialers = async () => {
        setLoading(true);
        const { data } = await getRobodialers();

        setSourceData(data?.robodialers || []);
    };

    useEffect(() => {
        fetchRobodialers();
    }, []);

    useEffect(() => {
        if(filterValues && selectQueueType === 'batch' && sourceData) {
            fetchBatches();
        }
    }, [filterValues]);

    useEffect(() => {
        const updateQueue = async () => {
            setLoading(true)

            // check if we have an illegal pairing (due to swapping user type while queue type is still set to the old user type), fix if necessary
            if (selectValue === 'fusion' && selectQueueType === 'banned') {
                setSelectQueueType('allowed');
                return;
            } else if (['robodialer', 'support'].includes(selectValue) && selectQueueType === 'allowed') {
                setSelectQueueType('banned');
                return;
            }
            const response = await getPrioQueue(selectValue, selectQueueType);
            setLoading(false)
            if (response.httpCode !== 200) {
                toast.error(response.httpCode + ' Unable to get Queue: ' + response.data?.error ?? '', {
                    iconTheme: {
                        primary: '#F36F82',
                        secondary: '#ffffff',
                    },

                });
                setItems([])
                return
            } 
            setItems(
                (response?.data?.queue ?? []).map(item => ({
                    id: item.robodialer_id,
                    priority: parseInt(item.priority),
                    robodialer_name: item.robodialer_name,
                    robodialer_id: item.robodialer_id,
                    batch_id: item.batch_id ?? null,
                    completed: item.completed === 'true' || !!item.completed
                })) as Item[]
            );
        }
        updateQueue()
    }, [sourceData]);

    const addToPrioList = (id: string, name: string, batchId:string | null = null) => {
        let key = selectQueueType === 'batch' ? 'batch_id' : 'robodialer_id';
        let value  = selectQueueType === 'batch' ? batchId : id;
        if (items.some((item) => item[key] === value)) {
            setErrorID(id); // Set error to highlight the duplicate id field
            setTimeout(() => setErrorID(null), 2000); // Remove error highlight after 2 seconds
            return;
        }
        const newItem: Item = {
            id: id,
            priority: items.length + 1,
            robodialer_name: name,
            robodialer_id: id,
            batch_id: batchId,
            completed: null
        };
        setHasChanges(true)
        setItems([...items, newItem]);
    };

    const handleSelectChange = (value: string) => {

        if (hasChanges) {
            setPendingSelectValue(value);
            setShowConfirmModal(true);
        } else {
            setSelectValue(value);
            setEditEnabled(false);
        }
    };

    const handleQueueTypeSelectChange = (value: string) => {
        if (hasChanges) {
            setPendingSelectValueQueue(value);
            setShowConfirmModal(true);
        } else {
            setSelectQueueType(value);
            setEditEnabled(false);
        }
    };

    const confirmSelectChange = () => {
        if (pendingSelectValue) {
            setSelectValue(pendingSelectValue);
        }
        if (pendingSelectValueQueue) {
            setSelectQueueType(pendingSelectValueQueue);
        }
        //onItemsChange(initialItems);
        setShowConfirmModal(false);
        setHasChanges(false);
        setEditEnabled(false);
    };

    const cancelSelectChange = () => {
        setPendingSelectValue("");
        setPendingSelectValueQueue("");
        setShowConfirmModal(false);
    };

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            if (selectQueueType === 'batch') {
                fetchBatches()
            } else {
                fetchRobodialers()
            }
        }
        fetch()
    }, [selectValue, selectQueueType])

    const fetchBatches = async () => {
        setLoading(true)
        //const twoWeeksAgo = DateTime.now().minus({ weeks: 2 }).toISODate();
        //const twoWeeksinthefuture = DateTime.now().plus({ weeks: 2 }).toISODate();
        
        const response = await getBatches({...filterValues, ...{
            orderDirection:'DESC',
        }});
        setLoading(false)
        if (response.httpCode !== 200) {
            toast.error(response.httpCode + ' Unable to get Batches: ' + response.data?.error ?? '', {
                iconTheme: {
                    primary: '#F36F82',
                    secondary: '#ffffff',
                },
            });
            return
        }

        setSourceData(response?.data?.data || [])
    }


    return (
        <>
            {loading ? (
                <>
                    <div className="w-full h-full flex justify-center items-center">
                        {" "}
                        <Loader />{" "}
                    </div>
                </>
            ) : null}
            {!loading ? (
                <>
                    <div className="mx-4 mt-5 mb-20">
                        {showConfirmModal && (
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-6 rounded-lg shadow-lg">
                                    <h2 className="text-xl font-bold mb-4">Pending Changes</h2>
                                    <p className="mb-4">
                                        You have unsaved changes. If you proceed, these changes will
                                        be lost. Do you want to continue?
                                    </p>
                                    <div className="flex justify-end">
                                        <button
                                            onClick={cancelSelectChange}
                                            className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={confirmSelectChange}
                                            className="px-4 py-2 bg-red-500 text-white rounded"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex items-center justify-start gap-x-2">
                            <span className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
                                Tools
                            </span>
                        </div>
                        {sourceData ? (
                            <>
                                <div className="overflow-y-auto border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white">
                                    <div className="text-superbill-jacarta font-bold text-lg font-jakarta">
                                        Priority Queue
                                    </div>
                                    <div className="flex items-center justify-between w-full py-2 gap-x-4">
                                        <div className="flex items-center w-full gap-x-4">
                                            <select
                                                value={selectValue}
                                                onChange={(e) => handleSelectChange(e.target.value)}
                                                className='bg-white border-1 block focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 w-1/2'
                                            >
                                                <option value="fusion">Fusion</option>
                                                <option value="robodialer">Robodialer</option>
                                                <option value="support">Support</option>
                                            </select>
                                            <select
                                                value={selectQueueType}
                                                onChange={(e) => handleQueueTypeSelectChange(e.target.value)}
                                                className='bg-white border-1 block focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 w-1/2'
                                            >
                                                <option value="customers">Robodialer</option>
                                                <option value="batch">Batch</option>
                                                {selectValue === "fusion" ? (
                                                    <option value="allowed">Allowed</option>
                                                ) : (
                                                    <option value="banned">Banned</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="flex items-center justify-end w-full gap-x-4">
                                            <button
                                                onClick={() => setEditEnabled(!editEnabled)}
                                                className='bg-white font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                                            >
                                                {editEnabled ? "Disable Editing" : "Enable Editing"}
                                            </button>
                                            <button
                                                disabled={!hasChanges || saveIsLoading}
                                                onClick={async () => {
                                                    setSaveIsLoading(true)
                                                    const response = await updatePrioQueue({
                                                        'user_type': selectValue,
                                                        'queue_type': selectQueueType,
                                                        'queue_data': items
                                                    });
                                                    setSaveIsLoading(false)
                                                    if (response.httpCode === 200) {
                                                        setEditEnabled(false)
                                                        setHasChanges(false)
                                                        toast.success("Priority queue updated", {
                                                            iconTheme: {
                                                                primary: '#4a43cd',
                                                                secondary: '#ffffff',
                                                            },
                                                        }
                                                        )
                                                    } else {
                                                        toast.error(response.httpCode + ' Unable to update queue: ' + response.data.error ?? response.msg ?? '', {
                                                            iconTheme: {
                                                                primary: '#F36F82',
                                                                secondary: '#ffffff',
                                                            },
                                                        });
                                                    }
                                                }}
                                                className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                                            >
                                                {saveIsLoading ? <Spinner /> : <>Save</>}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-10">
                                        <div className="col-span-4 px-2 py-1">
                                            <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                                                <div className="grid grid-cols-10 items-center gap-4 bg-superbill-anti-flash-white-darken">
                                                    <span className="col-span-4 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                                                        {selectQueueType !== 'batch' ? 'Name' : 'Batch ID'}
                                                    </span>
                                                    <div className="col-span-6 px-2 py-1">
                                                        <div className="flex items-center justify-between gap-x-2 w-full">
                                                            <span className="text-sm text-superbill-jacarta font-jacarta text-right">{selectQueueType !== 'batch' ? 'ID' : 'Robo Name'}</span>
                                                            {selectQueueType === 'batch' ? 
                                                            <Tooltip
                                                                content={
                                                                    <span style={{ whiteSpace: "pre-line" }}>
                                                                        Filter Batches
                                                                    </span>
                                                                }
                                                                className="max-w-[200px] bg-black py-1 px-2"
                                                            >
                                                                <FilterIcon className="cursor-pointer w-[20px]" onClick={() => {
                                                                    setIsFilterModalOpened(true)
                                                                }}/>
                                                            </Tooltip>
                                                            :null}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <KeyValueGrid
                                                    aspect="4-6"
                                                    maxEntriesPerPage={(sourceData ?? []).length}
                                                    action={(value, key) => {
                                                        if (selectQueueType === 'batch') {
                                                            let robodialer_id = sourceData.find((item) => item.batchId === key)?.robodialerId;
                                                            addToPrioList(robodialer_id ?? '', value?.toString(), (key ?? '')!.toString())
                                                        } else {
                                                            addToPrioList(value?.toString() ?? '', (key ?? '')!.toString())
                                                        }
                                                    }}
                                                    icon={
                                                        <Tooltip
                                                            content={
                                                                <span style={{ whiteSpace: "pre-line" }}>
                                                                    Add to priority queue
                                                                </span>
                                                            }
                                                            className="max-w-[200px] bg-black py-1 px-2"
                                                        >
                                                            <strong className="text-sm text-superbill-jacarta cursor-pointer">
                                                                {">"}
                                                            </strong>
                                                        </Tooltip>
                                                    }
                                                    data={(sourceData ?? [])?.reduce(
                                                        (acc, { id, name, robodialerName, batchId }) => {
                                                            acc[name ?? batchId] = id ?? robodialerName;
                                                            return acc;
                                                        },
                                                        {} as { [key: string]: string }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-6 px-2 py-1 ">
                                            <div className="border border-superpay-soap divide-y divide-superpay-soap rounded">
                                                <div className="grid grid-cols-12 items-center gap-2 bg-superbill-anti-flash-white-darken px-2">
                                                    <span className={`text-sm py-4 text-superbill-jacarta font-jacarta font-bold ${selectQueueType === "batch" ? "col-span-1" : "col-span-2"}`}>
                                                        <Tooltip
                                                            content={
                                                                <span style={{ whiteSpace: "pre-line" }}>
                                                                    Priority
                                                                </span>
                                                            }
                                                            className="max-w-[200px] bg-black py-1 "
                                                        >
                                                            <strong className="text-sm text-superbill-jacarta cursor-pointer">
                                                                {"#"}
                                                            </strong>
                                                        </Tooltip>
                                                    </span>

                                                    {selectQueueType === "batch" ? (
                                                        <span className="col-span-3  text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                                                            Batch ID
                                                        </span>
                                                    ) : null}

                                                    <span className={` text-sm py-4 text-superbill-jacarta font-jacarta font-bold ${selectQueueType === "batch" ? "col-span-3" : "col-span-4"}`}>
                                                        Robodialer ID
                                                    </span>

                                                    <span className={` text-sm py-4 text-superbill-jacarta font-jacarta font-bold ${selectQueueType === "batch" ? "col-span-3" : "col-span-4"}`}>
                                                        Robodialer Name
                                                    </span>

                                                    <span className={`text-right  text-sm py-4 text-superbill-jacarta font-jacarta font-bold col-span-2`}>
                                                        Remove
                                                    </span>
                                                </div>

                                                <DraggableList
                                                    errorID={errorID}
                                                    items={items}
                                                    selectQueueType={selectQueueType}
                                                    onItemsChange={setItems}
                                                    editEnabled={editEnabled}
                                                    setEditEnabled={setEditEnabled}
                                                    setHasChanges={setHasChanges}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <QMToolsFilterModal modalIsOpen={isFilterModalOpened} onClose={() => {
                        setIsFilterModalOpened(false)
                    }} onConfirm={(e:FilterFormValues) => {
                        setFilterValues(e)
                        setIsFilterModalOpened(false)
                    }} defaultValues={filterValues}/>
                </>
            ) : null}
        </>
    );
}
