import React, { useState, useRef, useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;

  children: React.ReactNode;
  title: string;
}

const DraggableModal: React.FC<ModalProps> = ({ title, isOpen, onClose, children }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && modalRef.current) {
        const newX = e.clientX - offset.x;
        const newY = e.clientY - offset.y;

        setPosition({
          x: Math.max(0, Math.min(newX, window.innerWidth - modalRef.current.offsetWidth)),
          y: Math.max(0, Math.min(newY, window.innerHeight - modalRef.current.offsetHeight)),
        });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.classList.remove('select-none');
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, offset]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current) {
      setIsDragging(true);
      document.body.classList.add('select-none');
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  if (!isOpen) return null;


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
      <div
        ref={modalRef}
        className={`absolute bg-white rounded-lg shadow-lg transition-all duration-300 ${isMinimized ? 'h-12' : 'h-auto'} pointer-events-auto w-1/3 max-w-[800px]`}
        style={{ top: `${position.y}px`, left: `${position.x}px` }}
      >
        <div
          className="flex justify-between items-center p-2 cursor-move bg-gray-200 rounded-t-lg"
          onMouseDown={handleMouseDown}
        >
          <div className="text-lg">{title}</div>
          <div className="flex space-x-2">
            <button onClick={() => setIsMinimized(!isMinimized)} className="p-1 rounded-full hover:bg-gray-300">
              {isMinimized ? '🔼' : '🔽'}
            </button>
            <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-300">❌</button>
          </div>
        </div>
        {!isMinimized && (
            <div className='max-h-[600px] overflow-y-auto'>{children}</div>
        )}
      </div>
    </div>
  );
};

export default DraggableModal;
