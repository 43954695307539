import React, { useState } from "react";

import { DateTime } from "luxon";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoResults } from "assets/no-script-results.svg";
import {
  updateAgentPermisions,
} from "apis/agent";
import { toast } from "react-hot-toast";
import { getAgentsResponseType } from "types/types";
import { paginationHelper, PaginationStates } from "../../../utils/pagination";
import { useNavigate } from "react-router-dom";
import AgentsAdminTableMenu from "./agents-admin-table-menu";

type AgentsTableProps = {
  data: getAgentsResponseType | null;
  refresh: () => void;
};

function AgentsAdminTable({ data,refresh }: AgentsTableProps) {
  const navigate = useNavigate();
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: data?.agents ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 20,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });


  return (
    <>
      {(data?.agents ?? []).length === 0 ? (
        <>
          <NoResults className="w-full text-center mb-4" />
          <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
            No Agents
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-10 w-full mt-4 font-semibold font-sans text-jacarta rounded-t border-t border-l border-r border-superbill-soap overflow-hidden">
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              Email
            </div>
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              Last Login Date
            </div>
            <div className="col-span-3 text-sm bg-superbill-anti-flash-white px-3 py-2">
              Feature Flags
            </div>
            <div className="col-span-1 text-sm bg-superbill-anti-flash-white px-3 py-2"></div>
          </div>
          <div className="w-full bg-white rounded-b border border-superbill-soap  divide-y divide-superbill-soap font-sans">
            {currentActiveResults?.map((row) => {
              return (
                <div
                  className="grid grid-cols-10 font-sans items-center hover:bg-superbill-lavender/50  ease-in-out duration-150"
                  key={`robo-admin-table-${row.id}`}
                >
                  <div className="col-span-3 overflow-x-auto text-superbill-jacarta text-base px-3 py-2 flex items-center">
                    {row.email}
                  </div>
                  <div className="col-span-3 overflow-x-auto text-superbill-jacarta text-base px-3 py-2 flex items-center">
                    {row.lastLoginAt ?
                    <>{DateTime.fromJSDate(new Date(row.lastLoginAt)).toFormat("yyyy MMMM dd")}</>:'Never Logged In'
                    }
                  </div>
                  <div className="col-span-3 overflow-x-auto text-superbill-jacarta text-base px-3 py-2 flex items-center">
                    {row.featureFlags && 0 < Object.keys(row.featureFlags ?? {}).length ?
                    <>{
                        Object.keys(row.featureFlags ?? {}).filter((e) => row.featureFlags![e] === true).join(', ')
                      }</>:'-'
                    }
                  </div>
                  
                  <div className="col-span-1 text-superbill-jacarta text-base px-3 py-2 flex items-center justify-end">
                    <AgentsAdminTableMenu
                      featureFlags={row?.featureFlags ?? {}}
                      onChangePermisions={async(data) => {
                        data['agent_email'] = row.email
                        const {httpCode} = await updateAgentPermisions(data)
                        if(httpCode === 200){
                            toast.success("Agent updated successfully", {
                              iconTheme: {
                                primary: "#4a43cd",
                                secondary: "#ffffff",
                              },
                            });
                            refresh()
                        }else{
                          toast.error("Unable to update Agent", {
                            iconTheme: {
                              primary: "#F36F82",
                              secondary: "#ffffff",
                            },
                          });
                        }
                      }}
                    />
                  </div>

                </div>
              );
            })}
          </div>
          <div className="flex justify-end flex-row px-3 pt-2">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.backward)}
            >
              {currentLowerIndex == 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2">
              <strong>
                {currentLowerIndex + 1} to {currentUpperIndex + 1}
              </strong>{" "}
              of {(data?.agents ?? [])?.length}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.forward)}
            >
              {currentUpperIndex + 1 == (data?.agents ?? [])?.length ? (
                <></>
              ) : (
                <ForwardIcon />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AgentsAdminTable;
