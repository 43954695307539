import React, { createContext, useContext, useState} from "react";

type SocketContextType = {
  socketInstance: WebSocket | null,
  setSocketInstance: (socket: WebSocket) => void,
};

type SocketProviderProps = {
  children: React.ReactNode
};

export const SocketContext = createContext<SocketContextType>({
  socketInstance: null,
  setSocketInstance: (socket: WebSocket) => {},
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socketInstance, setSocketInstance] = useState<WebSocket | null>(null);

  return (
    <SocketContext.Provider value={{ socketInstance, setSocketInstance }}>
      {children}
    </SocketContext.Provider>
  )
}
