import React, { useEffect } from "react";
import Modal from "react-modal";

import { AddToPhonebookModalStates } from "enums/enums";
import AddIvrInstructionsPage from "./add-ivr-instructions";

const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};


// components
type AddRobodialerModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  modalState?: AddToPhonebookModalStates;
};
export default function AddToPhonebookModal({
  onClose,
  onSuccess,
  isOpen,
  modalState = AddToPhonebookModalStates.IVR,
}: AddRobodialerModalProps) {
  
  const [currentState, setCurrentState] = React.useState<AddToPhonebookModalStates>(modalState);
  
  useEffect(() => {
    if (modalState) {
      setCurrentState(modalState);
    }
  }, [modalState]);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add ph# to Phonebook Modal"
      >
        <div className="flex flex-col h-full divide-y">
          {currentState === AddToPhonebookModalStates.IVR ? (
            <AddIvrInstructionsPage onClose={onClose} onSuccess={onSuccess}/>
          ):null}
        </div>
      </Modal>
    </div>
  );
}
