import React from "react";
import Loader from "components/Loader";
import Modal from "react-modal";
import { useForm  } from "react-hook-form";

const customStyles = {
  content: {
    height: "max(30%,350px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

type FormValues = {
  status: string;
  name: string;
};

// components
type AddRobodialerModalProps = {
  onClose: () => void;
  onSuccess: (data) => void;
  isOpen: boolean;
};
export default function AddRobodialerModal({
  onClose,
  onSuccess,
  isOpen,
}: AddRobodialerModalProps) {
  const {
    register,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>();

  const toggleModal = () => {
    reset();
    onClose();
  };
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add Robodialer Modal"
      >
        <div className="flex flex-col h-full divide-y">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Add Robodialer
          </nav>
          <div className="flex-grow overflow-y-auto p-9 flex items-center justify-center">
            <form className='flex flex-col w-full'>
              <div className="mt-1">
                <label className='block text-sm text-superbill-jacarta' htmlFor="name">Name</label>
                <input className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base" id="name" {...register("name", { required: true })} />
                {errors.name && <span>This field is required</span>}
              </div>
              <div className="mt-1">
                <label className='block text-sm text-superbill-jacarta'>Status</label>
                <select className="w-full mt-1 block w-full p-2 border border-superpay-soap rounded-md" id="status" {...register("status", { required: true })}>
                  <option value="live">Live</option>
                  <option value="not live">Not Live</option>
                </select>
                {errors.status && <span>This field is required</span>}
              </div>
            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
              onClick={toggleModal}
            >
              Close
            </button>
            <div className="flex items-center">
              <button
                disabled={isLoading || !isValid}
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={async () => {
                  setIsLoading(true);
                  await onSuccess(watch())
                  setIsLoading(false);
                }}
              >
                {isLoading ? <Loader size={42} /> : <>Save</>}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
