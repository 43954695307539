import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as XIcon } from 'assets/x.svg';
interface Item {
    id: string;
    priority: number;
    robodialer_name: string;
    robodialer_id: string;
    batch_id: string | null;
    completed: boolean | null;
  }

interface DraggableListProps {
  items: Item[];
  errorID: string | null;
  onItemsChange: (items: Item[]) => void;
  editEnabled: boolean;
  setEditEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
  selectQueueType: string;
  }
  
  const DraggableList: React.FC<DraggableListProps> = ({ items, selectQueueType, onItemsChange, errorID, editEnabled, setEditEnabled, setHasChanges }) => {
  const [showNotification, setShowNotification] = useState(false);

  const handleDelete = (id: string | null) => {
    
    if (!id) {
      return;
    }
    let key = selectQueueType === 'batch' ? 'batch_id' : 'robodialer_id';
    
    const updatedItems = items.filter(item => {
      return item[key] !== id
    });
    
    onItemsChange(updatePriorities(updatedItems));
    setHasChanges(true)
  };

  const updatePriorities = (items: Item[]): Item[] => {
    return items.map((item, index) => ({
      ...item,
      priority: index + 1
    }));
  };

  const onDragEnd = (result: DropResult) => {
    
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    onItemsChange(updatePriorities(reorderedItems));
    setHasChanges(true)
  };

  useEffect(() => {
    if (editEnabled) {
      setShowNotification(true)
    }
  }, [editEnabled])
  
  return (
    <div>
      {showNotification && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Drag and Drop</h2>
            <p className="mb-4">You can reorder the items by dragging and dropping them.</p>
            <button
              onClick={() => setShowNotification(false)}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
            >
              Understood
            </button>
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={editEnabled ? onDragEnd : () => {}}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${snapshot.isDraggingOver ? 'bg-gray-100' : ''}`}
            >
              {items.map((item, index) => (

                <Draggable key={`${item.batch_id ? item.batch_id : item.robodialer_id}-${item.id}`} draggableId={`${item.batch_id ? item.batch_id : item.robodialer_id}`} index={index} isDragDisabled={!editEnabled}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`grid grid-cols-12 items-center p-2 border ${errorID === item.id ? '!border-red-500 animate-pulse bg-superbill-banner-red' : 'bg-white'}
                      ${errorID === item.id ? '' : 
                       `${snapshot.isDragging ? 'border-superbill-indigo' : 'border-superpay-soap'} ${snapshot.isDraggingOver ? 'border-b-2 border-superbill-indigo' : ''}`
                       }`}
                  >
                    {/* Priority column */}
                    <span className={`flex-grow text-xs text-superbill-jacarta font-jacarta ${item.batch_id ===  null ? 'col-span-2' : 'col-span-1'}`}>
                      {item.priority}
                    </span>
              
                    {/* Batch ID column (conditionally rendered if selectQueueType is "batch") */}
                    {item.batch_id !==  null ? (
                      <span className="col-span-3 flex-grow text-xs text-superbill-jacarta font-jacarta">{item.batch_id}</span>
                    ) : null}
              
                    {/* Robodialer ID column */}
                    <span className={`flex-grow text-xs text-superbill-jacarta font-jacarta ${item.batch_id ===  null ? 'col-span-4' : 'col-span-3'}`}>
                      {item.robodialer_id}
                    </span>
              
                    {/* Robodialer Name column */}
                    <span className={`flex-grow text-xs text-superbill-jacarta font-jacarta ${item.batch_id ===  null ? 'col-span-4' : 'col-span-3'}`}>
                      {item.robodialer_name}
                    </span>
              
                    {/* Remove button */}
                    <span className={`flex justify-end items-center flex-grow text-xs text-superbill-jacarta font-jacarta col-span-2`}>
                      <XIcon className="z-20 w-4 h-4 mr-1 fill-superbill-slate-grey/50 hover:fill-superbill-ultra-red cursor-pointer ease-in-out duration-150 min-w-[14px]" onClick={() => handleDelete( selectQueueType === 'batch' ? item.batch_id : item.robodialer_id)} />
                    </span>
                  </div>
                )}
              </Draggable>
              
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableList;
