import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { getBatches, getPmsPatients } from 'apis/robodialer';

import { RobodialerUser } from 'types/types';
import ResultsTable from './scripts-results-table';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PmsPatientsTable from './pms-patients-table';


type PathType = {
  name: string;
  href: string;
  current: boolean;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

type ScriptBuilderPatientsProps = {
  userData: RobodialerUser | null,
}
export default function ScriptBuilderPatients() {

  const [loading, setLoading] = useState<boolean>(true);
  const props = useOutletContext<ScriptBuilderPatientsProps>();
  const [patientsData, setPatientsData] = useState<any | null>({});

  useEffect(() => {
    //console.log('###ScriptBuilderPatients props!!!', props);
    if (props?.userData) {
      setLoading(false)
    }
  }, [props]);


  useEffect(() => {
    const fetchPatients = async () => {
      setLoading(true)

      const { data, httpCode } = await getPmsPatients(0 , 15);
      setPatientsData(httpCode === 200 ? data : {});
      setLoading(false)

    }
    fetchPatients()
  }, []);


  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className=' mx-5 md:mx-20 lg:max-w-7xl lg:mx-auto px-2'>
          <PmsPatientsTable pmsPatientsData={patientsData} setPmsPatients={setPatientsData} refresh={async (offset, limit, searchValue) => {
            const { data, httpCode } = await getPmsPatients(offset , limit, searchValue);
            setPatientsData(httpCode === 200 ? data: {});
            setLoading(false)
          }} />
        </div>
      ) : null}
    </>
  );
}