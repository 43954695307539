import React, { useEffect, useState } from "react";
import Loader from 'components/Loader';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { updateScriptDoc, getScriptInputs } from "apis/robodialer";
import { Script } from "types/types";


// custom styles
const customStyles = {
  content: {
    height: "max(80%,600px)",
    maxWidth: "max(80%,600px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  refresh: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScript: string | null;
  scriptData: Script | null;
  setSelectedScript: React.Dispatch<React.SetStateAction<string | null>>;

}

/* interface FormValues {
  scriptInputs: []
} */
// components
export default function DefineInputsModal({ modalIsOpen, setModalIsOpen, scriptData, setSelectedScript, selectedScript, refresh }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [inputs, setInputs] = useState<any>(null);
  const [mode, setMode] = useState<string>('all');
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
  });


  const onSubmit = async () => {
    let data = watch();
    let response = await updateScriptDoc(selectedScript, {
      scriptInputs: data
    });
    setLoading(false)

    let error: string | null = null;
    if (response.httpCode !== 200) {
      error = "Oops! Something went wrong. If the problem persist please reach out to support@thesuperbill.com."
    }
    if (error) {
      setSubmitError(error)
      return;
    }
    toggleModal()
    refresh()
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedScript(null);
    setInputs(null);
  };
  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptInputs((scriptData?.type ?? '')?.replaceAll(' ', '-').toLocaleLowerCase())
      setInputs(data);
      
    }
    if(scriptData && scriptData?.type){
      fetch()
    }
    
  }, [scriptData?.type])

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Define Script-Level Inputs
          </nav>
          <div className="flex-grow overflow-y-auto p-9 text-superbill-jacarta">
            These script-level "fallback" inputs will be used for all calls placed with this script <strong>if the individual call does not contain the input.</strong>
            <div className=' mt-1 w-full'>
              <div className='w-full my-1 mt-3 bg-white rounded border border-superbill-soap divide-y divide-superbill-soap font-sans overflow-hidden'>
                <div className={`grid grid-cols-8 gap-4 p-2 bg-superbill-anti-flash-white content-center items-center`}>
                  <div className={`col-span-3 text-superbill-jacarta text-base font-semibold`}>Input</div>
                  <div className={`col-span-2 text-superbill-jacarta text-base font-semibold overflow-hidden`}>Required</div>
                  <div className='col-span-3 text-superbill-jacarta text-base font-semibold'>Fallback Value</div>

                </div>
                {!inputs ?
                  <div className='w-full h-full flex justify-center items-center'>
                    {' '} <Loader />{' '}
                  </div> :
                  <>
                    {Object.keys(inputs ?? {}).map((key) => {
                      return (
                        <div className='grid grid-cols-8 gap-4 p-2 content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150' key={key} >
                          <div className='col-span-3 text-superbill-jacarta text-base font-semibold break-words'>{key}</div>
                          <div className={`col-span-2 text-superbill-jacarta text-base ${inputs[key].required ? ' font-bold bg-superbill-lavender w-fit px-1 rounded' : ''}`}>{inputs[key].required ? 'True' : 'False'}</div>
                          <div className='col-span-3 text-superbill-jacarta text-base'>
                            <input
                              id={key}
                              // placeholder={key}
                              defaultValue={(scriptData && scriptData.scriptInputs && scriptData.scriptInputs[key]) ? scriptData.scriptInputs[key] : ''}
                              className={`w-full rounded font-sans border border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-1 pl-2`}
                              {...register("" + key)}
                            />
                          </div>

                        </div>
                      )
                    })}
                  </>}
              </div>
            </div>

          </div>
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setModalIsOpen(false); setInputs(null) }}
            >
              Cancel
            </button>
            <button
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
              onClick={async () => {
                setButtonIsLoading(true)
                await onSubmit()
                setButtonIsLoading(false)
              }}
            >
              {buttonIsLoading ? <Spinner /> : <>Save</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
