import React, { useState, useEffect } from 'react';
import { useRef } from 'react';


const AudioVisualizer: React.FC = () => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [analyserNode, setAnalyserNode] = useState<AnalyserNode | null>(null);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [audioInput, setAudioInput] = useState<MediaStreamAudioSourceNode | null>(null);
  const [dataArray, setDataArray] = useState<Uint8Array | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        const inputNode = audioCtx.createMediaStreamSource(stream);
        analyser.fftSize = 256;
        inputNode.connect(analyser);
        setAudioContext(audioCtx);
        setAnalyserNode(analyser);
        setAudioStream(stream);
        setAudioInput(inputNode);
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        setDataArray(dataArray);
        const canvas = canvasRef.current;
        const canvasCtx = canvas?.getContext('2d');
        const draw = () => {
          if (canvas && canvasCtx && dataArray) {
            analyser.getByteTimeDomainData(dataArray);
            canvasCtx.clearRect(0, 0, canvas?.width, canvas?.height);
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#00ff00';
            canvasCtx.beginPath();
            const sliceWidth = (canvas?.width * 1.0) / bufferLength;
            let x = 0;
            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = (v * canvas?.height) / 2;
              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }
              x += sliceWidth;
            }
            canvasCtx.lineTo(canvas?.width, canvas?.height / 2);
            canvasCtx.stroke();
          }
          animationFrameId.current = requestAnimationFrame(draw);
        };
        draw();
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };
    initAudio();
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (audioContext) {
        audioContext.close();
      }
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div className='w-full items-center flex flex-col justify-center'>
      <canvas ref={canvasRef} width={100} height={50} />
    </div>
  );
};

export default AudioVisualizer;
