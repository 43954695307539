import React, { useState }  from 'react';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import { Script } from 'types/types';
import { createSandboxCall } from 'apis/robodialer';


type SelectionPageProps = {
  onClose: () => void;
  onSuccess: () => void;
  scriptData: Array<Script> | null;
}
const SelectionPage = ({ onClose, onSuccess, scriptData }: SelectionPageProps) => {

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);



  const onSubmit = async (formData) => {
    console.log(formData);
    setLoading(true)
    const { data } = await createSandboxCall({
      scriptId: formData.script.value,
      resultType: formData.resultType
    })
    setLoading(false)
    onSuccess()
    
  };

  const selectOptions = scriptData
    ? scriptData.map((script) => ({ value: script.id, label: script.name }))
    : [];


  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        <form >
          <label className='text-sm text-superbill-jacarta font-bold' htmlFor="script">Select the script you’d like to simulate</label>
          <Controller
            control={control}
            name="script"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select a script"
                className='bg-white border-1 border-superbill-soap block w-full focus:ring-superbill-indigo focus:border-superbill-indigo sm:text-sm rounded placeholder:text-sm text-superbill-jacarta'
                classNamePrefix="react-select"
                isSearchable={false}
                options={selectOptions}
              />
            )}
          />
          <div className="w-full mt-5">
            <div className="flex items-center justify-between">
              <label className="cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150">
                <input
                  {...register('resultType', { required: 'This field is required' })}

                  type="radio"
                  value="200"
                  className="mr-2"
                />
                Successful call (200)
              </label>
              <label className="cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150">
                <input
                  {...register('resultType', { required: 'This field is required' })}

                  type="radio"
                  value="500"
                  className="mr-2"
                />
                Failed call (500)
              </label>
            </div>
          </div>
        </form>
      </div >
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={onClose}
        >
          Close
        </button>
        <button
          disabled={!isValid}
          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
          onClick={handleSubmit(onSubmit)}
        >
          {isLoading ? <Spinner /> : <>Simulate</>}
          
        </button>
      </div>
    </div >
  );
};

export default SelectionPage;
