import { AgentStatsResponse, AssignCallResponseType, AssignNextCallInQueueResponse, getAgentsResponseType, getPrioQueueResponseType, getRobodialersResponseType, QMScriptsResponseType } from "types/types";
import { makeRequest } from "./common";
import { Response } from "types/responses";

type getNextCallInQueueAndAssignResponse = Response<AssignNextCallInQueueResponse>;
type AssignCallResponse = Response<AssignCallResponseType>;
type getRobodialersResponse = Response<getRobodialersResponseType>;
type getAgentsResponse = Response<getAgentsResponseType>;

type getPrioQueueResponse = Response<getPrioQueueResponseType>;

type getAgentsStatsResponse = Response<AgentStatsResponse>;

type getScriptsResponse = Response<QMScriptsResponseType>;


const appV = 22

export async function makeTypedRequest(
  url: string,
  method: string,
  auth: boolean,
  data?: any,
  form?: any,
  returnData?: boolean,
  apiToUse?: 'portal' | 'sbs'
): Promise<Response<any>> {
  return (await makeRequest(url, method, auth, data,form, returnData, apiToUse)) as Response<any>;
}



type loadHumanAuditProps ={
  retoolUser: {
    email:string  
  }
  robodialer_id?: string
  call_id?: string
  attempt_n?: string
  batch_id?: string
}
export async function loadHumanAudit(data: loadHumanAuditProps): Promise<any> {
  let url = '/v1/retool/dev/load-human-audit-qa'
  
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function loadRoboAudit(data: loadHumanAuditProps): Promise<any> {
  let url = '/v1/retool/robodialer/assign-next-call-in-audit-queue'
  
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function discardAudit(data: loadHumanAuditProps): Promise<any> {
  //let url = '/v1/retool/dev/load-human-audit-qa'
  let url = '/v1/retool/robodialer/discard-audit?queue_type=robodialer'
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function discardHumanAudit(data: loadHumanAuditProps): Promise<any> {
  let url = '/v1/retool/robodialer/discard-audit?queue_type=human'
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getRobodialers(): Promise<getRobodialersResponse> {
  let url = '/v1/sidekick/robodialers?appV='+appV
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getAgents(): Promise<getAgentsResponse> {
  let url = '/api/sidekick/agents?appV='+appV
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getPrioQueue(user_type = 'fusion', queue_type='customers'): Promise<getPrioQueueResponse> {
  let url = `/v1/sidekick/get-priority-queue?user_type=${user_type}&queue_type=${queue_type}`
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function updatePrioQueue(data): Promise<getPrioQueueResponse> {
  let url = '/v1/sidekick/update-priority-queue'
  const response = await makeTypedRequest(
    url,
    'PUT',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getAgentUserCredentials() {
  let url = '/agents/users/credentials?appV='+appV
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function getCallFilters(robodialer_id, call_id) {
  let url = `/v1/queue/robodialers/${robodialer_id}/calls/${call_id}`
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getNextCallInQueueAndAssign(data): Promise<getNextCallInQueueAndAssignResponse> {
  let url = `/v1/retool/robodialer/initiate-sidekick-call?add_from=1`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getAgentsStats(): Promise<getAgentsStatsResponse> {
  let url = `/v1/sidekick/agents-stats-overview`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getScript(robodialer_id: string, script_id: string, script_version: string | null = null): Promise<any> {

  let url = `/v1/robodialers/${robodialer_id}/script/${script_id}?appV=${appV}`;
  if(script_version){
    url += `&script_version=${script_version}`
  }
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    {},
    null,
    false,
    'sbs'
  );
  return response;
}

export async function submitCallHuman(data): Promise<any> {
  //let url = `/v1/retool/dev/submit-audit-qa`;
  let url = `/v1/sidekick/submit-audit`;
  
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function submitCallRobo(data): Promise<any> {
  //let url = `/v1/retool/robodialer/submit-audit`;
  let url = `/v1/sidekick/submit-audit`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function submitCall(robodialer_id, call_id, data): Promise<any> {

  let url = `/v1/retool/robodialers/${robodialer_id}/calls/${call_id}/retool-results`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function discardCall(robodialer_id, call_id): Promise<any> {
  let url = `/v1/retool/remove-retool-doc-lock/robodialer/${robodialer_id}/call/${call_id}`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    {},
    null,
    false,
    'sbs'
  );
  return response;
}

export async function initiateCall(robodialer_id, call_id, data): Promise<any> {
  let url = `/v1/retool/robodialer/${robodialer_id}/initiate-call/${call_id}`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function agentSignIn(): Promise<any> {
  let url = `/v1/sidekick/session-started-or-resumed`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    {},
    null,
    false,
    'sbs'
  );
  return response;
}

export async function logCallEvents(data): Promise<any> {
  let url = `/v1/sidekick/logs-call-events`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function logCallIssues(data): Promise<any> {
  let url = `/v1/sidekick/logs-call-issues`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getSignedAudio({ robodialer_id, call_id, attempt_n}): Promise<any> {
  let url = `/v1/retool/robodialer/get-signed-audio-url?robodialer_id=${robodialer_id}&call_id=${call_id}&attempt=${attempt_n}`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getAttemptData({ robodialer_id, call_id, attempt_n}): Promise<any> {
  let url = `/v1/sidekick/get-attempt-data?robodialer_id=${robodialer_id}&call_id=${call_id}&attempt=${attempt_n}`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getBatches(data): Promise<any> {
  //remove empty and undefined from data
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
  const params = new URLSearchParams(data).toString();
  let url = `/v1/queue/batches?${params}`;
    const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getBatchData(robodialer_id, batch_id): Promise<any> {
  let url = `/v1/queue/robodialers/${robodialer_id}/batches/${batch_id}/details`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function getBatchCalls(robodialer_id, batch_id): Promise<any> {
  let url = `/v1/queue/robodialers/${robodialer_id}/batches/${batch_id}/calls`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function createRobodialer(data): Promise<any> {
  let url = `/v1/sidekick/robodialers`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function createAgent(data): Promise<any> {
  let url = `/api/sidekick/agents`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}
export async function updateRobodialer(data): Promise<any> {
  let url = `/v1/sidekick/robodialers`;
  const response = await makeTypedRequest(
    url,
    'PUT',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function updateAgentPermisions(data): Promise<any> {
  let url = `/api/sidekick/agents`;
  const response = await makeTypedRequest(
    url,
    'PUT',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getPhonebook(path = ''): Promise<any> {
  let url = `/api/sidekick/phonebook?path=${path}`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getPHData(to = ''): Promise<any> {
  let url = `/api/sidekick/phonebook/${to}`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function updatePHData(to = '', data): Promise<any> {
  let url = `/api/sidekick/phonebook/${to}`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getScriptTypes(): Promise<any> {
  let url = `/scripts-types`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function getScripts(data): Promise<getScriptsResponse> {
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
  const params = new URLSearchParams(data).toString();
  let url = `/scripts?${params}`;
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function updateInstructions(robodialer_id, script_id, data): Promise<getScriptsResponse> {
  let url = `/robodialers/${robodialer_id}/scripts/${script_id}`;
  const response = await makeTypedRequest(
    url,
    'POST',
    true,
    data,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function setBatchCancelled(data): Promise<any> {
    let url = `/v1/retool/dev/set-batch-cancelled`;
    const response = await makeTypedRequest(
      url,
      'POST',
      true,
      data,
      null,
      true,
      'sbs'
    );
    return response;
  }

  export async function setBatchHidden(data): Promise<any> {
    let url = `/v1/retool/dev/set-batch-hidden`;
    const response = await makeTypedRequest(
      url,
      'POST',
      true,
      data,
      null,
      true,
      'sbs'
    );
    return response;
  }

  export async function setBatchPaused(data): Promise<any> {
    let url = `/v1/retool/dev/set-batch-paused`;
    const response = await makeTypedRequest(
      url,
      'POST',
      true,
      data,
      null,
      true,
      'sbs'
    );
    return response;
  }

  export async function updatePhoneNumber(robodialer_id, call_id, data): Promise<any> {
    // PUT to /audit/robodialers/<robodialer_id>/calls/<call_id>
    const url = `/audit/robodialers/${robodialer_id}/calls/${call_id}/update-phone-number`
    const response = await makeTypedRequest(
      url,
      'PUT',
      true,
      data,
      null,
      true,
      'sbs'
    );
    return response;
  }
export async function assignCallToAgent(
  robodialerId: string,
  callId: string,
  userId: string,
  force: boolean = false,
): Promise<AssignCallResponse> {
  const url = `/tools/assign-call/robodialer/${robodialerId}/call/${callId}/user/${userId}?force=${force}`;
  
  const response = await makeTypedRequest(
    url,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );

  return response;
}


export async function downloadZipResults(robodialer_id, batchId) {
  const response = await makeRequest(
    `/batches/download/${robodialer_id}/batches/${batchId}`,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function downloadCallInputs(robodialer_id, scriptId, batchId) {
  const response = await makeRequest(
    `/batches/download/${robodialer_id}/scripts/${scriptId}/batches/${batchId}/inputs`,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}

export async function downloadResults(robodialer_id, scriptId, batchId, successfulCalls = true) {
  const response = await makeRequest(
    `/batches/download/${robodialer_id}/scripts/${scriptId}/batches/${batchId}?successfulCalls=${successfulCalls}`,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


export async function regeneratePdfs(robodialer_id, batchId) {
  const response = await makeRequest(
    `/batches/regenerate/${robodialer_id}/batches/${batchId}/pdfs`,
    'GET',
    true,
    null,
    null,
    true,
    'sbs'
  );
  return response;
}

export async function regenerateCsvs(robodialer_id, batchId) {
  const response = await makeRequest(
    `/batches/regenerate/${robodialer_id}/batches/${batchId}/csvs`,
    'GET',
    true,
    null,
    null,
    false,
    'sbs'
  );
  return response;
}


