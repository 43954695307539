import React from "react";
import Modal from "react-modal";
import { SuperDialTutorialModalStates } from "enums/enums";
import SuccessPage from "./create-script-info";
import CreditsInfo from "./credits-info";
import RunScriptInfo from "./run-script-info";
import ResultsInfo from "./results-info.tsx";
import CreateScriptInfo from "./create-script-info";
// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
type SuperDialTutorialModalProps = {
  modalState?: SuperDialTutorialModalStates;
  modalIsOpen: boolean;
  onClose: () => void ;
  onSuccess?: () => void ;
}
export default function SuperDialTutorialModal({ modalState = SuperDialTutorialModalStates.CreateScriptInfo,
  modalIsOpen,
  onClose,
  onSuccess }: SuperDialTutorialModalProps) {
  const [currentState, setCurrentState] = React.useState<SuperDialTutorialModalStates>(modalState);


  const toggleModal = () => {
    setCurrentState(SuperDialTutorialModalStates.CreateScriptInfo)
    onClose()
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        {currentState === SuperDialTutorialModalStates.CreateScriptInfo ? (
          <CreateScriptInfo onClose={toggleModal} onNext={() => {
            setCurrentState(SuperDialTutorialModalStates.RunScriptInfo)
         }} />
        ) : null}
        {currentState === SuperDialTutorialModalStates.RunScriptInfo ? (
          <RunScriptInfo onClose={toggleModal} onNext={() => {
            setCurrentState(SuperDialTutorialModalStates.ResultsInfo)
         }} />
        ) : null}
        {currentState === SuperDialTutorialModalStates.ResultsInfo ? (
          <ResultsInfo onClose={toggleModal} onNext={() => {
            setCurrentState(SuperDialTutorialModalStates.CreditsInfo)
         }} />
        ) : null}
        {currentState === SuperDialTutorialModalStates.CreditsInfo ? (
          <CreditsInfo onClose={toggleModal} onNext={onSuccess!}/* onNext={() => {
            setCurrentState(SuperDialTutorialModalStates.CreditsInfo)
         }} *//>
        ) : null}
      </Modal>
    </div>
  );
};
