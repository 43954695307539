import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg';
import { useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import { ReactComponent as QuestionIcon } from 'assets/question-icon.svg';
import { Tooltip } from '@material-tailwind/react';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as BackgroundIcon } from 'assets/icon_backgroundInfo.svg';
import { ReactComponent as ResponseIcon } from 'assets/icon_responseInstructions.svg';
import { ReactComponent as XIcon } from 'assets/x.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-circle.svg';


import { 
  ANSWER_TYPE_OPTIONS, 
  ANSWER_TYPES,
  Question,
  isMultipleChoiceQuestion,
  isBooleanQuestion 
} from './newQuestionsType';
import StyledQuestionInput from './StyledQuestionInput';
import QuestionMenu from './QuestionOptions';
import { toast, Toaster } from 'react-hot-toast';
import { Script } from 'types/types';
import Accordion from '../../components/general/accordion';
import CalendlyModal from './CalendlySuperdial';
import { getScriptInputs, getScriptsTypes } from '../../apis/robodialer';


const MAX_FOLLOWUPS = 50;

interface QuestionRowProps {
  index: number;
  questionPath:string;
  question: Question;
  questions: Question[];
  setQuestions: React.Dispatch<React.SetStateAction<Array<Question> | null>>;
  remove: (index: string) => void;
  duplicate: (index: string) => void;
  updateValue: (index: string, value:any) => void;
  getNestedObject: (questions: Question[], index: string) => Question | undefined | any;
  register: any;
  allowedString: string[];

}

const QuestionRow: React.FC<QuestionRowProps> = ({
  index,
  questionPath = `questions.${index}`,
  remove,
  duplicate,
  register,
  question,
  questions,
  updateValue,
  getNestedObject,
  setQuestions,
  allowedString
}) => {
  const [dragIndex, setDragIndex] = React.useState<string | null>(null);
  const answerType = question.answerType;
  const followUps = question.followUps;
  const questionText = JSON.parse(JSON.stringify(question.question));
  const backgroundContext = question.backgroundContext;
  const responseInstruction = question.responseInstruction;
  const questionId = question.questionId ?? null;
  const isFollowUp = questionPath.split('.').length > 2;
  let answerOptions: string[] = []
  if (isMultipleChoiceQuestion(question)) {
    answerOptions = question.answerOptions;
  }

  const getNestedValue = (obj, path) => {
    return path.reduce((acc, key) => acc && typeof acc[key] !== undefined, obj);
  };

  const setNestedValue = (obj, path, value) => {
    const lastKey = path.pop();
    const nestedObj = path.reduce((acc, key) => acc && acc[key], obj);
    
    if (nestedObj && lastKey) {
      nestedObj[lastKey] = value;
    }
  };
  
  
  const handleAnswerTypeChange = (selected: any, path) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    let q = getNestedObject(updatedQuestions, path)
    if(!q) return;
    if (selected.value !== ANSWER_TYPES.BOOLEAN && selected.value !== ANSWER_TYPES.MULTIPLE) {
      if(q['answerOptions']){
        delete q['answerOptions'];
      }
      if(q['followUps']){
        delete q['followUps'];
      }
    }
    
    if (selected.value === ANSWER_TYPES.BOOLEAN) {
      if(q['answerOptions']){
        delete q['answerOptions'];
      }
      if (q['followUps']) {
        for (const key in q['followUps']) {
          if (key !== 'Yes' && key !== 'No') {
            delete q['followUps'][key];
          }
        }
      }
    }

    if (selected.value === ANSWER_TYPES.MULTIPLE) {
      if (q['followUps'] && q['followUps']['No']) {
        delete q['followUps']['No']
      }
      if (q['followUps'] && q['followUps']['Yes']) {
        delete q['followUps']['Yes']
      }
      q['answerOptions'] = ['opt1', 'opt2', 'other'];
    }
    q['answerType'] = selected.value;
    setQuestions(updatedQuestions);
  };

  const addFollowUp = (type: 'Yes' | 'No' | string) => {
    
    // Get the existing questions
    const updatedQuestions = JSON.parse(JSON.stringify(questions)); // Clone current questions
    let path1 = `${questionPath}.followUps`.split('.')
    path1.shift()
    const followUps = getNestedValue(updatedQuestions, path1) || {};
    
    
    let newFollowUps;

    // Create new follow-up based on answer type
    if (answerType === ANSWER_TYPES.BOOLEAN) {
        newFollowUps = {
            ...followUps,
            [type]: [
                ...(followUps[type] || []),
                {
                    question: '',
                    answerType: ANSWER_TYPES.TEXT,
                    questionId: question.questionId,
                    optional: false,
                    confirmationRequired: false
                }
            ]
        };
    } else if (answerType === ANSWER_TYPES.MULTIPLE) {
        newFollowUps = {
            ...followUps,
            multiple: {
                ...followUps.multiple,
                [type]: [
                    ...(followUps.multiple?.[type] || []),
                    {
                        question: '',
                        answerType: ANSWER_TYPES.TEXT,
                        questionId: question.questionId,
                        optional: false,
                        confirmationRequired: false
                    }
                ]
            }
        };
    }

    // Set the entire questions object
    
    setNestedValue(updatedQuestions, path1, newFollowUps);
    //setValue("questions", updatedQuestions); // Update the questions field
};


  const isDirectParentChild = (path1, path2) => {
    const shorterPath = path1.length < path2.length ? path1 : path2;
    const longerPath = path1.length >= path2.length ? path1 : path2;
    
    return (
      shorterPath.every((key, index) => key === longerPath[index]) &&
      longerPath.length > shorterPath.length
    );
  };

  const swapQuestions = (start, end) => {
    
    if (!start || !end) return;
    // Swap content directly instead of using move
    
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    let path1 = start.split('.')
    path1.shift()
    let path2 = end.split('.')
    path2.shift()
    
    
    if (isDirectParentChild(path1, path2)) {
      let errorMsg = 'Cannot swap parent and child questions';
      toast.error(errorMsg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    }
    


    let item1 = getNestedObject(updatedQuestions, start)
    if(!item1){
      return
    }
    //const unref_item1 = JSON.parse(JSON.stringify(item1));

    
    let item2 = getNestedObject(updatedQuestions, end)
    if(!item2){
      return
    }
    //const unref_item2 = JSON.parse(JSON.stringify(item2));

    const temp = { ...item1 }; // to avoid reference issues
    Object.assign(item1, item2);
    Object.assign(item2, temp);
    
    setQuestions(updatedQuestions);

  };


  
  return (
    <>
      {questionId &&
        <div className="bg-superbill-anti-flash-white rounded-t w-fit px-3 ml-9 text-superbill-slate-grey text-xs border-t border-l border-r border-superbill-soap">
          <Tooltip content={
              <span style={{ whiteSpace: 'pre-line' }}>
                Question ID
              </span>
            } className="max-w-[200px] bg-black py-1 px-2">
            {questionId}
          </Tooltip>
        </div>
      }
      <div className={"flex  mb-2 rounded hover:shadow" + (0 === index ? ' bg-superbill-lavender-dark rounded' : ' bg-white ')} key={questionPath + index}>
        <div draggable
          onDragOver={(e) => { e.preventDefault(); }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (index) {
              setDragIndex(questionPath);
            }
          }}
          onDragLeave={() => { setDragIndex(null); }}
          onDragStart={(e) => { e.dataTransfer.setData('index', questionPath); e.stopPropagation(); }}
          onDrop={(e)  => {
            e.preventDefault();
            e.stopPropagation();
            const oldIndex = e.dataTransfer.getData('index');
            swapQuestions(oldIndex, questionPath);
            setDragIndex(null);
          }} className={`cursor-pointer ${isFollowUp ? 'bg-superbill-lavender-dark' :'bg-superbill-indigo'} rounded-l-[3px] flex-grow-0 `}>
            <div className={`${isFollowUp ? 'text-superbill-indigo' :'text-white'}` +" flex items-center justify-center m-auto h-full px-1  font-jakarta text-xs font-semibold " + (index > 8 ? 'w-[22px]' : 'w-[15px]')}>
                {index + 1}
            </div>
        </div>
        {/* <div className={"flex items-center justify-center m-auto h-full px-1 text-white font-jakarta text-xs font-semibold " + (index > 8 ? 'w-[22px]' : 'w-[15px]')}>
          {index + 1}
        </div> */}
        <div className="flex-grow h-auto rounded-r bg-white border border-superbill-soap pr-1">
          <div className="mb-2 rounded">

            {(typeof backgroundContext) !== 'undefined' && typeof backgroundContext !== null ?
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-center py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer">
                <div>
                  <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                </div>
                <div className='w-full px-2'>
                  <StyledQuestionInput register={register} defaultValue={backgroundContext} onChange={(e) => {
                      updateValue(`${questionPath}.backgroundContext`, e.target.value);
                    }} index={`${questionPath}`} iKey={`key-${questionPath}.backgroundContext`}  allowedString={allowedString}/>
                </div>
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    updateValue(`${questionPath}.backgroundContext`, undefined);
                  }}
                  className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                >
                  <DeleteIcon className='w=[14px]'/>
                </button>
              </div>
            : null}
            

            <div className={"flex items-center justify-between p-2"}>
              <div className="mr-2 grow">
                <StyledQuestionInput className='w-full' register={register} defaultValue={questionText} onChange={(e) => {
                  
                    updateValue(`${questionPath}.question`, e.target.value);
                  }} index={`${questionPath}`} iKey={`key-${questionPath}.question`}  allowedString={allowedString}/>
              </div>
              <div className="mr-2 w-[200px] min-w-[170px]">
                <Select
                  options={ANSWER_TYPE_OPTIONS}
                  value={ANSWER_TYPE_OPTIONS.find(opt => opt.value === answerType)}
                  onChange={(e) => {
                    handleAnswerTypeChange(e, questionPath)
                  }}
                  isSearchable={false}
                  className='bg-white border-1 block w-full focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300'
                />
              </div>
              <div className="flex max-w-[30px] items-center justify-center hover:bg-superbill-anti-flash-white rounded min-w-[30px]">
              
                <QuestionMenu showAddSubQuestions={(answerType === ANSWER_TYPES.BOOLEAN) && (typeof followUps !== 'object' || Object.keys(followUps).length < 2)}
                
                canAddSubQuestions={true}
                  addSubQuestion={() => {
                    const optionPath = `${questionPath}`;
                    const updatedQuestions = JSON.parse(JSON.stringify(questions));
                    const optionRef = getNestedObject(updatedQuestions, optionPath);
                    if (!optionRef) {
                      
                      console.error('optionRef not found', optionPath, updatedQuestions);
                      return
                    }
                    if (! optionRef['followUps']){
                      optionRef['followUps'] = {}
                    }
                    if (! optionRef['followUps']['Yes']){
                      optionRef['followUps']['Yes'] = []
                    }
                    if (! optionRef['followUps']['No']){
                      optionRef['followUps']['No'] = []
                    }
                    setQuestions(updatedQuestions);
                    
                  }}
                  duplicate={() => {
                    duplicate(questionPath)
                  }}
                  deleteQ={() => {
                    remove(questionPath)
                  }}
                  addBackgroundContext={() => { 
                    updateValue(`${questionPath}.backgroundContext`, '');
                  }}
                  addResponseInstruction={() => { 
                    updateValue(`${questionPath}.responseInstruction`, '');
                  }}
                />
              </div>
            </div>

          </div>
          {/* Multiple Choice Options */}
          {answerType === ANSWER_TYPES.MULTIPLE && (
            <div className="mt-4 ml-8">
              <div className="mb-2 flex items-center">
                <span className=" text-superpay-light-text-gray font-medium font-sans text-sm">Multiple choice options</span>
              </div>
              {answerOptions.map((_, optIndex) => (
                <div key={`${questionPath}-option-${optIndex}`} className="flex items-center gap-2 mb-2">
                  <input
                    disabled={question?.followUps?.multiple && question?.followUps?.multiple[_] && question?.followUps?.multiple[_]?.length !== 0}
                    {...register(`${questionPath}.answerOptions.${optIndex}-${_}}`)}
                    value={_}
                    onChange={(e) => {
                      const optionPath = `${questionPath}`;
                      const updatedQuestions = JSON.parse(JSON.stringify(questions));
                      const optionRef = getNestedObject(updatedQuestions, optionPath);
                      if (optionRef) {
                        if(optionRef['followUps'] && optionRef['followUps']['multiple']){
                          delete optionRef['followUps']['multiple'][_];
                        }
                        optionRef['answerOptions'][optIndex] = e.target.value;
                      }
                      
                      setQuestions(updatedQuestions);
                    }}
                    className={`w-1/4 border-superbill-soap rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none text-base textarea border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans ease-in-out duration-200 min-h-[38px] flex items-center`}
                  />
                  <div className='flex items-center' onClick={() => {
                      
                      const updatedQuestions = JSON.parse(JSON.stringify(questions));
                      const optionPath = `${questionPath}`;
                      const optionRef = getNestedObject(updatedQuestions, optionPath);
                      if (!optionRef) {
                        
                        console.error('optionRef not found', optionPath, updatedQuestions);
                        return
                      }
                      optionRef.answerOptions.splice(optIndex, 1);
                      if(optionRef.followUps && optionRef.followUps['multiple']){
                        delete optionRef.followUps['multiple'][_];
                      }
                      setQuestions(updatedQuestions);
                      
                    }}>
                    {question?.answerOptions?.length === 2? null : 
                      <XIcon className="cursor-pointer block h-4 w-4 ml-1 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                    }
                    {question?.followUps?.multiple && question?.followUps?.multiple[_] && question?.followUps?.multiple[_]?.length !== 0 ?  
                      <Tooltip
                        content={
                          <span style={{ whiteSpace: "pre-line" }}>
                            To edit this option, delete followUps   
                          </span>
                        }
                        className="max-w-[200px] bg-black py-1 px-2"
                      >
                        <InfoIcon className="cursor-pointer block h-6 w-6 ml-1 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                      </Tooltip>
                    :null}
                  </div>
                  {(question?.answerOptions?.length! - 1) === optIndex ?
                    <div className='flex items-center' onClick={() => {
                      const updatedQuestions = JSON.parse(JSON.stringify(questions));
                      const q:string[] = getNestedObject(updatedQuestions, `${questionPath}.answerOptions`);
                      if (!q) return;
                      q.push(`Opt${q.length + 1}`);
                      setQuestions(updatedQuestions);
                      
                    }}/*  onClick={() => {
                      const updatedQuestions = JSON.parse(JSON.stringify(questions));
                      const q = getNestedObject(updatedQuestions, `${questionPath}`);
                      if (!q) return;
                      if (! q['followUps']){
                        q['followUps'] = {}
                      }
                      if (! q['followUps']['multiple']){
                        q['followUps']['multiple'] = {}
                      }
                      if (! q['followUps']['multiple'][answerOptions[optIndex]]){
                        q['followUps']['multiple'][answerOptions[optIndex]] = [];
                      }
                      q['followUps']['multiple'][answerOptions[optIndex]].push({ question: '', answerType: ANSWER_TYPES.TEXT } as Question);
                      setQuestions(updatedQuestions);
                    }} */>
                      <PlusIcon className="cursor-pointer block h-6 w-6 child:fill-superbill-slate-grey/50 hover:child:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                    </div>
                    : null}
                </div>
              ))}
              {answerOptions.map((_, optIndex) => (
                <div key={`${questionPath}-option-${optIndex}.show.followUps`}>
                  <div className="ml-5 mb-2 flex items-center">
                    <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>{_}</strong></span>
                    <div className='flex items-center' onClick={() => {
                      const updatedQuestions = JSON.parse(JSON.stringify(questions));
                      const q = getNestedObject(updatedQuestions, `${questionPath}`);
                      if (!q) return;
                      if (! q['followUps']){
                        q['followUps'] = {}
                      }
                      if (! q['followUps']['multiple']){
                        q['followUps']['multiple'] = {}
                      }
                      if (! q['followUps']['multiple'][answerOptions[optIndex]]){
                        q['followUps']['multiple'][answerOptions[optIndex]] = [];
                      }
                      if(q['followUps']['multiple'][answerOptions[optIndex]]?.length >= MAX_FOLLOWUPS){
                        let errorMsg = `Max ${MAX_FOLLOWUPS} follow-ups allowed for each option`;
                        toast.error(errorMsg, {
                          iconTheme: {
                            primary: '#F36F82',
                            secondary: '#ffffff',
                          },
                        });
                        return;
                      }
                      
                      q['followUps']['multiple'][answerOptions[optIndex]].push({ question: '', answerType: ANSWER_TYPES.TEXT } as Question);
                      setQuestions(updatedQuestions);
                    }}>
                      <PlusIcon />
                      <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
                    </div>
                  </div>
                  <div className="mt-4 ml-8">
                    <FollowUpsMultiple
                      key={`${questionPath}-option-${optIndex}.followUps`}
                      allowedString={allowedString}
                      followUps={question?.followUps?.multiple ?? {}}
                      remove={remove}
                      getNestedObject={getNestedObject}
                      questions={questions}
                      currentIndex={_}
                      setQuestions={setQuestions}
                      question={question}
                      duplicate={duplicate}
                      updateValue={updateValue}
                      register={register}
                      parentPath={questionPath}
                      answerType={answerType}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Boolean Follow-ups */}
          {followUps && (
            <div className="mt-4 ml-8">
              <FollowUpsBoolean
                allowedString={allowedString}
                duplicate={duplicate}
                remove={remove}
                getNestedObject={getNestedObject}
                questions={questions}
                setQuestions={setQuestions}
                question={question}
                updateValue={updateValue}
                register={register}
                parentPath={questionPath}
                answerType={answerType}
              />
            </div>
          )}
          {/* Render Follow-ups recursively */}
          {followUps && (
            <div className="mt-4 ml-8">
              <FollowUpsMultiple
                key={`${questionPath}-option.followUps.multiple`}
                allowedString={allowedString}
                remove={remove}
                getNestedObject={getNestedObject}
                questions={questions}
                setQuestions={setQuestions}
                question={question}
                updateValue={updateValue}
                register={register}
                parentPath={questionPath}
                answerType={answerType}
              />
            </div>
          )}
          {(typeof responseInstruction) !== 'undefined' && typeof responseInstruction !== null ?
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-center py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer">
                <div>
                  <ResponseIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                </div>
                <div className='w-full px-2'>
                  <StyledQuestionInput register={register} defaultValue={responseInstruction} onChange={(e) => {
                      updateValue(`${questionPath}.responseInstruction`, e.target.value);
                    }} index={`${questionPath}`} iKey={`key-${questionPath}.responseInstruction`}  allowedString={allowedString}/>
                </div>
                <button
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    updateValue(`${questionPath}.responseInstruction`, undefined);
                  }}
                  className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                >
                  <DeleteIcon className='w=[14px]'/>
                </button>
              </div>
            : null}
        </div>

      </div>
    </>
  );
};

const FollowUpsMultiple: React.FC<any> = ({ followUps, register, remove, currentIndex, parentPath, answerType, duplicate, setQuestions, questions, updateValue, getNestedObject, allowedString }) => {

  if (answerType === ANSWER_TYPES.MULTIPLE && followUps && followUps[currentIndex]) {
    return (
      <>
          {followUps[currentIndex].map((_, index) => (
              <QuestionRow
                allowedString={allowedString}
                setQuestions={setQuestions}
                getNestedObject={getNestedObject}
                updateValue={updateValue}
                key={`${parentPath}.followUps.multiple.${currentIndex}.${index}`}
                questions={questions}
                index={index}
                questionPath={`${parentPath}.followUps.multiple.${currentIndex}.${index}`}
                question={(_ as Question)}
                register={register}
                remove={remove}
                duplicate={duplicate}
              />
        ))}
      </>
    );
  }

  return null;
};


const FollowUpsBoolean: React.FC<any> = ({ control, register, remove, duplicate, parentPath, answerType, question, setQuestions, questions, updateValue, getNestedObject, allowedString }) => {
    const addBooleanFollowUp = (questionPath, type: 'Yes' | 'No' | string) => {
      //console.log('adding followup', questionPath, type);
      
      const updatedQuestions = JSON.parse(JSON.stringify(questions));
      const q = getNestedObject(updatedQuestions, `${questionPath}`);
      if (!q) return;
      if (! q['followUps']){
        q['followUps'] = {}
      }
      
      //console.log(q['followUps'][type]);
      
      if (! q['followUps'][type]){
        q['followUps'][type] = [];
      }
      if(q['followUps'][type]?.length >= MAX_FOLLOWUPS){
        let errorMsg = `Max ${MAX_FOLLOWUPS} follow-ups allowed for each option`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        return;
      }
      //console.log('pushing');
      
      q['followUps'][type].push({ question: '', answerType: ANSWER_TYPES.TEXT } as Question);
      //console.log('updatedQuestions', updatedQuestions);
      setQuestions(updatedQuestions);
    }
  if (answerType === ANSWER_TYPES.BOOLEAN) {
    const followUps = question.followUps;
    return (
      <>
        {followUps?.Yes && (
          <div key={`container.${parentPath}.followUps.Yes`}>
            <div className="ml-5 mb-2 flex items-center">
              <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>Yes</strong></span>
              <div className='flex items-center' onClick={() => { 
                  addBooleanFollowUp(parentPath, 'Yes');
               }}>
                <PlusIcon />
                <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
              </div>
            </div>
            {followUps.Yes.map((_, index) => (
              <QuestionRow
                allowedString={allowedString}
                setQuestions={setQuestions}
                getNestedObject={getNestedObject}
                updateValue={updateValue}
                key={`${parentPath}.followUps.Yes.${index}`}
                questions={questions}
                questionPath={`${parentPath}.followUps.Yes.${index}`}
                index={index}
                question={followUps.Yes[index]}
                register={register}
                remove={remove}
                duplicate={duplicate}
              />
            ))}
          </div>
        )}
        {followUps?.No && (
          <div key={`container.${parentPath}.followUps.No`}>
            <div className="ml-5 mb-2 flex items-center">
              <span className="text-superbill-jacarta text-sm mr-4">If the answer is <strong>No</strong></span>
              <div className='flex items-center' onClick={() => { 
                  addBooleanFollowUp(parentPath, 'No');
               }}>
                <PlusIcon />
                <span className="ml-1 hover-underline-animation cursor-pointer text-sm" >Add question</span>
              </div>
            </div>
            {followUps.No.map((_, index) => (
              <QuestionRow
                allowedString={allowedString}
                setQuestions={setQuestions}
                getNestedObject={getNestedObject}
                updateValue={updateValue}
                key={`${parentPath}.followUps.No.${index}`}
                questions={questions}
                questionPath={`${parentPath}.followUps.No.${index}`}
                index={index}
                question={followUps.No[index]}
                register={register}
                remove={remove}
                duplicate={duplicate}
              />
            ))}
          </div>
        )}
      </>
    );
  }

  return null;
};
type FormValues = {
  questions: Question[];
};

interface Props {
  questions: Question[];
  onSuccess?: (e: Array<Question>, externalInstructions: string, pmsForm?: any) => void;
  scriptData?: Script | null;
  setQuestions: React.Dispatch<React.SetStateAction<Array<Question> | null>>;
}
export const QuestionForm = forwardRef(({questions, setQuestions, onSuccess, scriptData}: Props, ref) => {
  const { register } = useForm<FormValues>({
    defaultValues: {
      questions: questions ?? []
    }
  });

  const unparse = (input?: string): string | undefined => {
    if (input) {
      return (input.replace(/<span class="[^"]*">([^<]*)<\/span>/g, (match, content) => `${content.trim()}`)).trim();
    }
    return input
  }

  const processData = () => {
    // Create a deep copy of questions
    const deepCopyQuestions = JSON.parse(JSON.stringify(questions));

    if(!deepCopyQuestions || deepCopyQuestions.length === 0){
      let errorMsg = `Questions are empty`;
      toast.error(errorMsg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      throw new Error('Invalid question');
    }
    // Recursive function to validate and replace questions
    function validateQuestion(questionObj) {
      // Validate `question` property      
      
      if (!questionObj.question.trim() || typeof questionObj.question !== 'string') {
        let errorMsg = `The "question" property is invalid or empty`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        //console.error(`The "question" property is invalid or empty in:`, questionObj);
        throw new Error('Invalid question');
      }

      if (typeof questionObj.backgroundContext !== 'undefined' && (!questionObj.backgroundContext.trim() || typeof questionObj.backgroundContext !== 'string')) {
        let errorMsg = `The "backgroundContext" property is invalid or empty in: ${unparse(questionObj.question)}`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        //console.error(`The "question" property is invalid or empty in:`, questionObj);
        throw new Error('Invalid question');
      }

      if (typeof questionObj.responseInstruction !== 'undefined' && (!questionObj.responseInstruction.trim() || typeof questionObj.responseInstruction !== 'string')) {
        let errorMsg = `The "responseInstruction" property is invalid or empty in: ${unparse(questionObj.question)}`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        //console.error(`The "question" property is invalid or empty in:`, questionObj);
        throw new Error('Invalid question');
      }

      if (!questionObj.question.trim() || typeof questionObj.question !== 'string') {
        let errorMsg = `The "question" property is invalid or empty`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        //console.error(`The "question" property is invalid or empty in:`, questionObj);
        throw new Error('Invalid question');
      }


      console.log('questionObj.question.trim()', questionObj.question.trim());
      
      if (questionObj.question.trim().indexOf('text-superbill-ultra-red') !== -1) {
        
        let errorMsg = `The "question" property contains invalid inputs in: ${unparse(questionObj.question)}`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        throw new Error('Invalid question');
      }
      // Validate `answerType` property
      if (!questionObj.answerType || typeof questionObj.answerType !== 'string') {
        let errorMsg = `The "answerType" property is invalid or empty in: ${unparse(questionObj.question)}`;
        toast.error(errorMsg, {
          iconTheme: {
            primary: '#F36F82',
            secondary: '#ffffff',
          },
        });
        //console.error(`The "answerType" property is invalid or empty in:`, questionObj);
        throw new Error('Invalid question');
      }
  
      // Replace `question` value with its "unparsed" version
      questionObj.question = unparse(questionObj.question);
  
      // Validation based on `answerType`
      if (questionObj.answerType === "multiple") {
        // Recursively iterate over each element in `followup.answerOptions`
        if (typeof questionObj.answerOptions !== 'object' || !Array.isArray(questionObj.answerOptions)) {
          let errorMsg = `Invalid followup.answerOptions in: ${unparse(questionObj.question)}`;
          toast.error(errorMsg, {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
            },
          });
          //console.error(`Invalid followup.answerOptions in:`, questionObj.followup);
          throw new Error('Invalid question');
        }
        for (const element of questionObj.answerOptions) {
          if (!element || typeof element !== 'string') {
            
            //console.error(`The "answerOption" property is invalid or empty in:`, questionObj.followup);
            let errorMsg = `The "answerOption" property is invalid or empty in: ${unparse(questionObj.question)}`;
            toast.error(errorMsg, {
              iconTheme: {
                primary: '#F36F82',
                secondary: '#ffffff',
              },
            });
            throw new Error('Invalid question');
          }
        }
        console.log('here1');
        
        // Validate that `followup.multiple` is a Record<string, QuestionType>
        if (questionObj.followUps && questionObj.followUps?.multiple) {
          if(typeof questionObj.followUps?.multiple !== 'object'){
            
            //console.error(`"followup.multiple" must be a Record<string, QuestionType> for type "multiple":`, questionObj);
            let errorMsg = `invalid "followup.multiple" in: ${unparse(questionObj.question)}`;
            toast.error(errorMsg, {
              iconTheme: {
                primary: '#F36F82',
                secondary: '#ffffff',
              },
            });
            throw new Error('Invalid question');
          }
          // Recursively iterate over each key in `followup.multiple`
          for (const key in questionObj.followUps.multiple) {
            const nestedFollowUp = questionObj.followUps.multiple[key];
            for (const nestedQuestion of nestedFollowUp) {
              validateQuestion(nestedQuestion); // Recursive call
            }
          
          }
        }
      } else if (questionObj.answerType === "boolean") {
        if(questionObj.followUps ){

          for (const key in questionObj.followUps) {
            const nestedFollowUp = questionObj.followUps[key];
            for (const nestedQuestion of nestedFollowUp) {
              validateQuestion(nestedQuestion); // Recursive call
            }
          }
        }
      }
    }
  
    // Start recursive validation for each object in the questions array

    deepCopyQuestions.forEach(question => validateQuestion(question));
  
    return deepCopyQuestions;
  };
  
  
  const onSubmit = () => {
    try {
      const result = processData();
      if (onSuccess) {
        onSuccess(result as Array<Question>, '', {})
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const addQuestion = () => {
    //add new questions to questios using setQuestions]
    console.log('add question');
    
    setQuestions((prevQuestions) => {
      if (!prevQuestions) return [];
      return [...prevQuestions, { question: '', answerType: ANSWER_TYPES.TEXT }] as Question[];
    });
  };

  useImperativeHandle(ref, () => ({ onSubmit }));
  
  const duplicateInNestedObject = (qPath) => {
    
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    const pathArray = qPath.split('.');
    pathArray.shift()
    const isFollowUp = pathArray.length > 1
    let current = updatedQuestions;
   
    for (let i = 0; i < pathArray.length - 1; i++) {
      const key = pathArray[i];
      current = current[key];
    }
  
    const lastKey = pathArray[pathArray.length - 1];
    const itemToDuplicate = Array.isArray(current) ? current[parseInt(lastKey, 10)] : current[lastKey];
  
    if (itemToDuplicate) {
      const duplicatedItem = JSON.parse(JSON.stringify(itemToDuplicate));
      if (Array.isArray(current)) {
        current.splice(parseInt(lastKey, 10) + 1, 0, duplicatedItem);
      } else {
        current[lastKey + '_copy'] = duplicatedItem;
      }
    }
    
  
    return updatedQuestions;
  };

  const deleteFromNestedObject = (qPath) => {
    
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    const pathArray = qPath.split('.');
    pathArray.shift() 
    
    let current = updatedQuestions; 
  
    
    for (let i = 0; i < pathArray.length - 1; i++) {
      const key = pathArray[i];
      current = current[key]; 
    }
  
    
    const lastKey = pathArray[pathArray.length - 1];
    
    if (Array.isArray(current)) {
      
      const indexToRemove = parseInt(lastKey, 10);
      if (!isNaN(indexToRemove) && indexToRemove >= 0 && indexToRemove < current.length) {
        current.splice(indexToRemove, 1); 
      }
    } else if (typeof current === 'object' && current !== null) {
      
      delete current[lastKey];
    }
  
    return updatedQuestions; 
  };

  const updateValueInNestedObject = (qPath, newValue) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    const pathArray = qPath.split('.');
    pathArray.shift()
  
    let current = updatedQuestions;
  
  
    for (let i = 0; i < pathArray.length - 1; i++) {
      const key = pathArray[i];
      current = current[key];
    }
  
    const lastKey = pathArray[pathArray.length - 1];
    if (Array.isArray(current)) {
      current[parseInt(lastKey, 10)] = newValue;
    } else if (typeof current === 'object' && current !== null) {
      current[lastKey] = newValue;
    }
  
    return updatedQuestions;
  };

  const getNestedObject = (questions, qPath): Question | undefined | any=> {
    const pathArray = qPath.split('.');
    pathArray.shift();
    let current: Question | Question[] = questions;
  
    for (const key of pathArray) {
      if (Array.isArray(current)) {
        current = current[parseInt(key, 10)];
      } else if (typeof current === 'object' && current !== null) {
        current = current[key];
      } else {
        return undefined;
      }
    }
  
    return current as Question;
  };

  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState<boolean>(false);
  const [inputs, setInputs] = useState({});
  const [scriptType, setScriptType] = useState<string>('');

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptInputs(scriptData?.type?.replaceAll(' ', '-').toLocaleLowerCase())
      setInputs(data);
    }
    if (scriptData && scriptData?.type) {
      fetch()
    }

  }, [scriptData?.type])

  useEffect(() => {

    const fetch = async () => {
      const { httpCode, data } = await getScriptsTypes()
      if (httpCode === 200) {
        setScriptType((data.scripts.find(e => e.id === scriptData?.type))?.name || '');

      }
    }
    fetch()
  }, [scriptData?.type])
  
  return (
    <div className="flex w-full">
      <div className="p-6 max-w-6xl mx-auto w-full">
        <form>
          <Toaster/>
          <div>
            {questions.map((question, index) => (
              <QuestionRow
                key={`questions.${index}`}
                setQuestions={setQuestions}
                questionPath={`questions.${index}`}
                index={index}
                questions={questions}
                allowedString={scriptData?.inputs || []}
                question={question}
                register={register}
                getNestedObject={getNestedObject}
                updateValue={(qPath, newValue) => {
                  setQuestions(updateValueInNestedObject(qPath, newValue))
                }}
                remove={(qPath) => {
                  if (!qPath) return;
                  setQuestions(deleteFromNestedObject(qPath))
                }}
                duplicate={(qPath) => {              
                  setQuestions(duplicateInNestedObject(qPath))
                }}
              />
            ))}
          </div>
          <button
              onClick={addQuestion}
              type="button" className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
            >
              Add Question
            </button>

        </form>
      </div>
      <>
        {rightPanelOpen ?
              <div className="w-[350px] bg-white h-screen border-l sticky right-0 top-0 z-40">
                <div className="flex flex-col h-full divide-y ">
                  <div className="flex justify-between items-center">
                    <nav className='font-jakarta text-superbill-jacarta font-bold text-lg px-6 py-4 w-full'>
                      Help
                    </nav>
                    <XIcon className="w-4 h-4 mr-4 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey cursor-pointer ease-in-out duration-150 " onClick={() => setRightPanelOpen(false)} />
                  </div>
                  <div className="flex-grow overflow-y-auto p-3">
                    <Accordion title={'When I place calls, which version of the script will be used?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      When you place a call, we will use the version of the script at the <strong>time we make the call</strong>. It could take up to 2 business days for us to make the call, so if you edit your script in that time period those changes may or may not be reflected in our calls. We do not recommend editing a script when calls are “in progress”.
                    </div>} />
                    <Accordion title={'What are some examples of questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      The most successful questions are phrased as standalone, straightforward questions. For example, if you’d like to ask about coverage for specific codes, write the question as “Is there coverage for D-4346?” or “Does the plan cover code D-4346?” We would not recommend writing questions with multiple components, such as “What percentage is covered and does it apply to the deductible?”
                    </div>} />
                    <Accordion title={'What are the different question types?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      The most successful questions are phrased as standalone, straightforward questions. For example, if you’d like to ask about coverage for specific codes, write the question as “Is there coverage for D-4346?” or “Does the plan cover code D-4346?” We would not recommend writing questions with multiple components, such as “What percentage is covered and does it apply to the deductible?”
                      <ul className="ml-4 list-disc">
                        <li className="text-sm"><span className="font-bold">Yes/No:</span> Use yes/no type for any boolean question typically starts with "Does.." or "Is.." Boolean questions allow users to add follow-up questions based on the response ("if the answer is YES" or "if the answer is NO")</li>
                        <li className="text-sm"><span className="font-bold">Text:</span> Use text type for questions that expect sentences or lists as an answer. For example, questions like "What is the process for applying to this group?" or "What codes do you support?" would work well with the text type. </li>
                        <li className="text-sm"><span className="font-bold">Number:</span> Use number type for questions that expect ONLY numbers in its answer and don't require special formatting. For example, questions like "What is the visit limit" and "What is the co-insurance percentage" would work for number type, but "What is the phone number for verifying benefits" would work better with text type due to formatting </li>
                        <li className="text-sm"><span className="font-bold">Alphanumeric:</span> Use alphanumeric type for questions that expect a single string of alphanumeric characters, such as an ID or reference number. </li>
                        <li className="text-sm"><span className="font-bold">Dollar:</span> Use dollar type for questions whose answer should be formatted as a currency amount, such as "What is the co-pay" or "How much of the deductible is remaining" </li>
                        <li className="text-sm"><span className="font-bold">Date:</span> Use date type for questions asking about dates, such as "What is the start date of the benefit period" </li>
                        <li className="text-sm"><span className="font-bold">Address:</span> Use address type for questions asking for an address, such as "What is the address listed on file for this provider?" </li>
                        <li className="text-sm"><span className="font-bold">Phone Number:</span> Use the phone number type for questions asking for a phone number, such as "What number should I call for claim status updates?" </li>
                        <li className="text-sm"><span className="font-bold">Fax Number:</span> Use the fax number type for questions asking for a fax number, such as "To which fax number was the information sent to?" </li>
                        {/* <li className="text-sm"><span className="font-bold">Multiple Choice:</span> Use multiple choice type when the answer is expected to be one of a specific list of items. Expected answers must be defined in the "multiple choice options" list. An example of a multiple choice question would be "What is the plan type" with answer options "HMO", "PPO", "EPO" </li> */}
                      </ul>
                    </div>} />
                    <Accordion title={'How do I re-order questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      To reorder questions, drag the purple sliver on the left side of the question, and place it where you’d like the question to go.
                    </div>} />
                    <Accordion title={'How do I duplicate questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      To duplicate a question, click the kebab menu (vertical 3 dots) and select “Duplicate” from the dropdown.
                    </div>} />
                    <Accordion title={'Can I reference input data in my script?'} content={<div className="font-sans text-sm text-superbill-secondary">
                      Yes! Any required inputs can be referenced in the script by using curly braces &#123; &#125;. For example, you could mention the Claim Charge Amount by typing &#123;claimChargeAmount&#125;. <br /> The inputs available depend on your selected script type. For this script, you may use the following inputs:
                      <ul className="ml-4 list-disc">
                        {Object.keys(inputs).map((key) => {
                          if (inputs[key].required && inputs[key].required === true && key) {
                            return (
                              <li className="text-sm">{key}</li>
                            );
                          }
                        })}
                      </ul>
                      If you type an input in correctly, it will be highlighted in blue:
                      <div className="bg-superbill-lavender rounded p-1 text-superbill-indigo w-fit">&#123;claimChargeAmount&#125;</div>

                      If you there are any typos, or you use an unavailable input, it will be highlighted in red:
                      <div className="bg-superbill-banner-red rounded p-1 text-superbill-ultra-red w-fit">&#123;claimchargeamount&#125;</div>
                    </div>} />

                  </div>
                  <div className="flex flex-row justify-center px-4 py-3 sticky bottom-0 bg-white">
                    <div onClick={() => { setCalendlyModalIsOpen(true); }}
                      className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 cursor-pointer'
                    >
                      Contact Support
                    </div>

                  </div>
                </div>
              </div>
          : null}
          {!rightPanelOpen ? (
            <>
              {!rightPanelOpen ? (
                <div
                  onClick={() => setRightPanelOpen(true)}
                  className='font-jakarta text-sm font-semibold text-superbill-jacarta flex items-center cursor-pointer rounded-full hover:bg-superbill-anti-flash-white py-2 px-3 w-fit h-fit bg-white fixed border border-superbill-soap border-b-0.5 bottom-5 right-10 drop-shadow ease-in-out duration-150 '>
                  <QuestionIcon className='' />
                </div>
              ) : null}
              <CalendlyModal isOpen={calendlyModalIsOpen} setIsOpen={setCalendlyModalIsOpen} />
            </>
          ) : null}
      </>
    </div>
  );
});

export default QuestionForm;