import React from "react";
import Loader from 'components/Loader';
import { Controller, useForm } from "react-hook-form";

import { toast } from "react-hot-toast";
import { GenerateSecretModalStates } from "enums/enums";


type RegenerateSecretPageProps = {
  onClose: () => void;
  onNext: () => void;
}

export default function RegenerateSecretPage({onClose, onNext} : RegenerateSecretPageProps) {

  
  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Regenerate Secret
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
          <>
            <div className="my-1">
              <span className=" font-sans text-sm text-superbill-jacarta">Regenerating a secret will expire the existing secret. This action cannot be undone.</span>
            </div>
          </>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={() => {
            onClose();
          }}
        >
          Close
        </button>
        <button
                  type="button"
                  className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                  onClick={onNext}
                >
                  Regenerate
                </button>
      </div>
    </div>
  );
};
