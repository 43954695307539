import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import SignUpForm from './sign-up-form';
import { SignUpPageStates } from 'enums/enums';
import { useNavigate } from 'react-router-dom';
import {
  getAuth, sendEmailVerification,
} from "firebase/auth";

type SignUpEmailVerificationProps = {

  signedIn: boolean;
}
export default function ScriptBuilderSignUpEmailVerification({ signedIn = false, }: SignUpEmailVerificationProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const auth = getAuth();
    auth.tenantId = "RobodialersTenant-hvzmy"
    console.log('signInWithEmailAndPassword',auth.currentUser);
      sendEmailVerification(auth.currentUser!).then(() => {
        /* console.log('superpay_user_email_sent'); */
      });
  },[])
  
  const checkEmail = async () => {
    setLoading(true);
    const auth = getAuth();
    const currentPerson = await auth?.currentUser;
    await currentPerson?.reload();
    const verified = currentPerson?.emailVerified || false;
    //console.log('verified', verified);
    setLoading(false);
    if (!verified) {
      console.log('not verified');
      toast.error('Email not verified. Please click the link in your email and try again', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return;
    }
    window.location.assign("/super-dial-2fa");
  };

  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      <div className="bg-superdial-background-full bg-cover h-full text-superbill-jacarta">
        <div className="w-full flex justify-start items-center h-screen overflow-y-auto flex-col">
          <div className="w-full grid grid-cols-3">
            <div className='col-span-1 '></div>
            <div className="col-span-1 w-full text-white text-center font-bold mb-4 font-jakarta mt-2">
              <a href="https://www.thesuperbill.com">
                SuperBill
              </a>
            </div>
            <div className='col-span-1 flex items-center justify-end pr-2' onClick={() => {
              getAuth().signOut();
            }}>
              <span className='font-jakarta text-xs cursor-pointer hover-underline-animation w-fit'>
                Sign out
              </span>
            </div>
          </div>
          <div className="w-3/4 justify-center px-10 py-8 rounded bg-white">
            <div className="font-jakarta text-base text-center font-semibold text-superbill-jacarta mb-4">
              We’ve sent a verification link to your email.
            </div>
            <div className="font-jakarta text-base text-center text-superbill-jacarta mb-5">
              Once your account is verified, you’ll be able to log in to your account.
            </div>
            <div className="w-full flex justify-between items-center">
              <div
                className="cursor-pointer hover-underline-animation text-sm font-bold text-center"
                onClick={() => {
                  const auth = getAuth();
                  sendEmailVerification(auth.currentUser!);
                  toast.success("We've resent the verification link", {
                    iconTheme: {
                      primary: '#4a43cd',
                      secondary: '#ffffff',
                    },
                  });

                }}
              >
                {loading ? <Spinner /> :
                  <span>Resend email</span>
                }
              </div>
              <button
                className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
                onClick={() => {
                  if (!signedIn) {
                    navigate('./super-dial-sign-in')
                  } else {
                    checkEmail()
                  }
                }}
              >
                {loading ? <Spinner /> :
                  <span>I verified my email</span>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
