
export enum BuilderState {
    Setup = "Setup",
    Questions = "Questions",
    Communication = "Communication",
    Result = "Result"
}



export enum RunScriptModalStates {
    ScriptSelection = 'ScriptSelection',
    UploadCsv = 'UploadCsv',
    MatchHeaders = 'MatchHeaders',
    
    Success = 'Success',
    Error = 'Error',
}

export enum PlaceDemoCallsModalStates {
    ScriptSelection = 'ScriptSelection',
    UploadCsv = 'UploadCsv',
    MatchHeaders = 'MatchHeaders',
    
    Success = 'Success',
    Error = 'Error',
}


export enum SuperDialTutorialModalStates{
    CreateScriptInfo = 'CreateScriptInfo',
    RunScriptInfo = 'RunScriptInfo',
    ResultsInfo = 'ResultsInfo',
    CreditsInfo = 'CreditsInfo',
}

export enum GenerateSecretModalStates{
    GenerateSecret = 'GenerateSecret',
    RegenerateSecret = 'RegenerateSecret',
}

export enum SimulateCallModalStates{
    ScriptSelection = 'ScriptSelection',
}
export enum AutoVerificationModalStates{
    EnableVerification = 'EnableVerification',
}

export enum SignUpPageStates{
    SignupForm = 'SignupForm',
    Verification = 'Verification',
}


export enum AddToPhonebookModalStates{
    OH = 'officeHours',
    IVR = 'ivrInstructions',
}
