import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useNotification } from "@telnyx/react-client";
import toast from 'react-hot-toast';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import SidekickCallsContentToolbar from './sidekick-calls-content-toolbar';
import { useOutletContext } from 'react-router-dom';
import { discardCall, getNextCallInQueueAndAssign, getScript, initiateCall, submitCall, updatePhoneNumber } from 'apis/agent';
import KeyValueGrid from './components/key-value-grid';
import { AssignNextCallInQueueResponse, PhoneFromNumber } from 'types/types';
import ScriptOverview from './components/script-overview';
import ScriptForm from './components/script-form';
import SubmitFormComponent from './components/submit-form';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../routes/authProvider';
import { handleTelnyxEvent } from './components/telnyx-logger';


interface Option {
  value: boolean | string;
  other_reason?: string; //for CANNOT ANSWER - other
  label: string;
}

type SidekickCallsContentProps = {
  agentEmail: string
  agentSip: string
}

export default function SidekickCallsContent({ wsCallData, openIsuesModal, hangUpData,
  wsSetCallData, user_email, showKeypad, toggleKeypad, setIssueSubmitted, initWebCallData, setInitWebCallData,
  startOfHoldTime, setstartOfHoldTime,
  startOfIVRTime, setStartOfIVRTime, client,
  startOfHumanConversation, setStartOfHumanConversation,
}) {
  const { data } = useAuth();
  const appV = 22
  const { agentEmail, agentSip } = useOutletContext<SidekickCallsContentProps>()
  const [callData, setCallData] = useState<null | AssignNextCallInQueueResponse>(null);
  const [scriptData, setScriptData] = useState<null | any>(null); //type TBD
  const [initiateCallIsLoading, setInitiateCallIsLoading] = useState<boolean>(false)
  const [btnsDisabled, setBtnsDisabled] = useState<boolean>(false)
  const [callInitiated, setCallInitiated] = useState<boolean>(false)
  const [isFirstCallSubmitted, setIsFirstCallSubmitted] = useState<boolean>(false)
  const [callCount , setCallCount] = useState<number>(0)
  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();

  const [processedNumbers, setProcessedNumbers] = useState<PhoneFromNumber[]>([]);
  const [currentNumberIndex, setCurrentNumberIndex] = useState<number>(0); // Controls which number from `processedNumbers` to use
  const timerRef = useRef<number | null>(null); // Reference to store the interval ID

  const [callStatus, setCallStatus] = useState<string>(''); // Tracks the current call status for UI

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Option | null }>({});
  const [isGetCallLoading, setIsGetCallLoading] = useState<boolean>(false);
  const getNextCall = async () => {
    setIssueSubmitted(false)
    setIsGetCallLoading(true)
    const response = await getNextCallInQueueAndAssign({
      retoolUser: {
        email: agentEmail,
        dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
      },
      appV,
      agent_sip: agentSip,
      dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
    })
    setIsGetCallLoading(false)
    handleTelnyxEvent('userAction: getNextCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        retoolUser: {
          email: agentEmail,
          dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
        },
        appV,
        dummy: (agentEmail.includes("thesuperbill.com") || agentEmail.includes("superdial.ai"))
      },
    }, data?.user?.email)
    
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    if (response.httpCode === 200) {
      setCallInitiated(false)
      setSelectedOptions({})
      setValue('edit', false)
      setValue('phoneNumber', "")
      setCallData(response.data)
    } else {
      toast.error(response.httpCode + ' Unable to get call, Try again: ' + (response?.data?.error ?? response?.msg ?? 'Server error, contact support'), {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const onCopy = (content: string | number) => {
    navigator.clipboard.writeText(content.toString());
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }

  const fetchScript = async () => {
    const response = await getScript(callData?._robodialer_id!, callData?.scriptId!)
    handleTelnyxEvent('userAction: fetchScript', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        scriptId: callData?.scriptId!
      },
    }, data?.user?.email)
    
    if (response.httpCode === 200) {
      setScriptData(response.data)
    } else {
      toast.error(response.httpCode + ' Unable to load Script! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const discard = async () => {
    if(wsCallData && wsCallData?.call?.state && !['active','destroy'].includes(wsCallData?.call?.state) ){
      wsCallData?.call.hangup()
    }
    setCurrentNumberIndex(0);
    setCallStatus('')
    const response = await discardCall(callData?._robodialer_id, callData?._call_id)
    handleTelnyxEvent('userAction: discardCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        scriptId: callData?.scriptId!
      },
      response
    }, data?.user?.email)
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    
    if (response.httpCode === 200) {
      setCallData(null)
      setScriptData(null)
      setSelectedOptions({})
      setValue('edit', false)
      setValue('phoneNumber', "")
    } else {
      toast.error(response.httpCode + ' Unable to load Script! Try again:' + response.msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }

  const filterObject = (obj: Record<string, any>): Record<string, string | number> => {

    let filtered =  Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => {
        // Trim the value if it's a string and filter out empty, null, undefined, or "-"
        if (value === null || value === undefined || value === '-') {
          return false;
        }
  
        if (typeof value === 'string') {
          const trimmedValue = value.trim();
          return trimmedValue !== '';
        }
        return typeof value === 'number' || typeof value === 'string';
      })
    );
    return filtered;
    
  }
  
  
  useEffect(() => {
    const { hangupCauseHeader, hangupCodeHeader, sipCode } = (hangUpData ?? {})
    setCallStatus('')
    if( hangupCodeHeader && hangupCodeHeader.startsWith("6") || sipCode && sipCode.startsWith("6") || ["487", "480","503", "603"].includes(sipCode) ){
      retryCall()
      return
    }
    let errorMsg = ""
    if (hangupCodeHeader === 404){
      errorMsg = "YOU MUST FAIL THE CALL FOR NON OPERATIONAL PHONE NUMBER DO NOT USE FORM OR SEND SLACK MESSAGE";
    }else if (hangupCauseHeader) {
      const hangupCause = hangupCauseHeader;
      const hangupCode = hangupCodeHeader ? hangupCodeHeader.value : null;
      if (hangupCause === "user_busy" && hangupCode && hangupCode.startsWith("6")) {
        errorMsg = "Call busy, please retry later."
      } else {
        errorMsg = "Hangup initiated from REP side"
      }
    }/*  else {
      errorMsg = "Hangup initiated from AGENT side, please retry later."
      handleTelnyxEvent(errorMsg, {
        custom:true,
        date: (new Date()).toISOString(),
      }, data?.user?.email)
    } */
   if (errorMsg) {
     toast.error(errorMsg, {
       iconTheme: {
         primary: "#F36F82",
         secondary: "#ffffff",
       },
     });
   }
  },[hangUpData])
  
  useEffect(() => {
    if (callData && callData?._robodialer_id && callData?.scriptId) {
      fetchScript()
    }
    if (callData && callData?.num_bundled_calls && 0 < callData?.num_bundled_calls) {
      setCallCount(prev =>  prev+1)
    }else{
      setCallCount(0)
    }
    if(callData && callData.from_number){
      const numbers = callData.from_number;
      const tollFreeNumbers = numbers.filter(num => num.is_toll_free);
      const nonTollFreeNumbers = numbers.filter(num => !num.is_toll_free);

      if (tollFreeNumbers.length === 0 || nonTollFreeNumbers.length === 0) {
        setProcessedNumbers(numbers);
      } else {
        
        const maxLength = Math.max(tollFreeNumbers.length, nonTollFreeNumbers.length);
        const result: PhoneFromNumber[] = [];

        for (let i = 0; i < maxLength; i++) {
          if (i < tollFreeNumbers.length) result.push(tollFreeNumbers[i]);
          if (i < nonTollFreeNumbers.length) result.push(nonTollFreeNumbers[i]);
        }
        setProcessedNumbers(result);
      }
    }
    if(callData && callData.results){
          setSelectedOptions(Object.entries((callData.results as Record<string, { answer?: string, answerType?: string }> ?? {})).reduce((acc, [key, value]) => {
            //if answer.value is empty, skip
            if (typeof value.answer === 'undefined' || value.answer === '' || value.answer === null) return acc;
            
            acc[key] = value.answer;
            acc[key] = value.answerType != 'multiple' && value.answer === 'Yes' ? true : value.answer;
            acc[key] = value.answerType != 'multiple' && value.answer === 'No' ? false : value.answer;
            return acc;
          }, {}))
    }
  }, [callData])

  const selectedOptionsValuesOnly = Object.fromEntries(
    Object.entries(selectedOptions).map(([key, value]) => {
      if (value?.value === "CANNOT ANSWER - other") {
        if (value.other_reason && value.other_reason.trim() !== '') {
          return [key, `CANNOT ANSWER - ${value.other_reason}`];
        } else {
          return [key, ''];
        }
      }
      return [key, value?.value ?? value ?? ''];
    })
  );

  const submitCallForm = async (data) => {
    //debugSelectedOptions(selectedOptionsValuesOnly);
    if (data['outcome'].toLocaleLowerCase() === 'success' && !Object.values(selectedOptionsValuesOnly).every(value => value !== '' && value !== null && value !== undefined)) {
      toast.error('Invalid answers inputs', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    setCurrentNumberIndex(0);
    setCallStatus('')
    let detailedReasonKey = ""
    let notesKey = ""
    switch (data['mainFailureReason']) {
      case 'input_missing':
        detailedReasonKey = "detailedMissingFailureReason"
        break;
      case 'input_incorrect':
        detailedReasonKey = "detailedIncorrectFailureReason"
        break;
      case 'phone':
        detailedReasonKey = "detailedPhoneFailureReason"
        break;
      case 'other':
        detailedReasonKey = "detailedOtherFailureReason"
        break;
      default:
        break;
    }
    switch (data['callOutcome']) {
      case 'failure':
        notesKey = 'failNotes'
        break;

      default:
        notesKey = 'callRetryReason'
        break;
    }

    const parsedData = {
      ...{
        "scriptVersionUsed": scriptData?.scriptVersion,
        "n_attempt": initWebCallData?.inProgressAttemptId || initWebCallData?.n_attempt,
        "retoolUser": {
          "email": agentEmail,
        },
        "callResults": selectedOptionsValuesOnly,
        "combinedReason": [
          data['mainFailureReason'], data['detailedFailureReason']
        ],
        "internalNotes": ""
      },
      ...data
    }
    parsedData["phoneNumberValid"] = data["phoneNumberValid"] === 'true' ? true : false
    if (parsedData["callOutcome"]) {
      parsedData["callOutcome"] = (parsedData["callOutcome"].charAt(0).toUpperCase() + parsedData["callOutcome"].slice(1))
    }
    if (!parsedData["mainFailureReason"]) {
      parsedData["mainFailureReason"] = ''
    }
    if (!parsedData["detailedFailureReason"]) {
      parsedData["detailedFailureReason"] = ''
    }
    parsedData['lineConnected'] = data['lineConnected'] === 'true' ? true : false
    parsedData['humanReached'] = data['humanReached'] === 'true' ? true : false
    parsedData["outcome"] = parsedData["outcome"] === 'need_to_retry' ? 'Need To Retry' : (parsedData["outcome"].charAt(0).toUpperCase() + parsedData["outcome"].slice(1))
    parsedData[notesKey] = data['description']

    const now = DateTime.now();
    const formattedDate = now.toISO();
    parsedData['callInitiatedAt'] = formattedDate;//data['callInitiatedAt'].toString()
    parsedData['callEndedAt'] = formattedDate;//data['callEndedAt'].toString()
    parsedData[detailedReasonKey] = data['detailedFailureReason']
    if (initWebCallData?.n_attempt && !isFirstCallSubmitted) {
      parsedData["n_attempt"] = initWebCallData?.n_attempt;
    }
    parsedData["startOfHoldTime"] = startOfHoldTime;
    parsedData["startOfIVRTime"] = startOfIVRTime;
    parsedData["fromNumber"] = processedNumbers && processedNumbers[currentNumberIndex ?? 0] ? processedNumbers[currentNumberIndex ?? 0].number : null;
    parsedData["fromNumberList"] = callData?.from_number ?? [];
    parsedData["startOfHumanConversation"] = startOfHumanConversation;
    parsedData["scriptVersionUsed"] = scriptData?.scriptVersion
    parsedData["betterNumberProvided"] = data['betterNumberProvided'] ?? null
    if(wsCallData && wsCallData?.call?.state && wsCallData?.call?.state !== 'active'){
      wsCallData?.call.hangup()
    }
    const response = await submitCall(callData?._robodialer_id, callData?._call_id, parsedData)
    setBtnsDisabled(false)
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    setIsFirstCallSubmitted(true)
    
    handleTelnyxEvent('userAction: submitCallForm', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        call_id: callData?._call_id,
        data: parsedData
      },
      response
    }, data?.user?.email)

    const submitAndFetch = (callData?.bundle_numerator ?? 0) < (callData?.bundle_denominator ?? 0)
    if (response?.httpCode === 200) {
      setCallData(null)
      setScriptData(null)
      
      toast.success('Call Submited!', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      
      
      if (submitAndFetch) {
        getNextCall()
      }
    } else {
      toast.error(response?.httpCode + ' Unable to Submit! Try again', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  }



  const formatString = (template: string): string => {
    return template.replace(/\{([^}]+)\}/g, (match, key) => {
      const value = callData?.inputs[key.trim()];
      return typeof value !== 'undefined' ? value : match;
    });
  }

  const initWebCall = async (fromNumber:string|null = null) => {
    if (watch('edit') && !regex.test(watch('phoneNumber'))) {
      toast.error('Invalid phoneNumber', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      return
    }
    setCallStatus(`${fromNumber ? 'Re-trying' : 'Calling'}  ${watch('edit') ? watch('phoneNumber') : callData?.to} from ${fromNumber ? fromNumber : callData?.from_number[0].number}. (${currentNumberIndex+1}/${processedNumbers.length})`);
    setInitiateCallIsLoading(true)
    
    if(watch('edit')){
      updatePhoneNumber(callData?._robodialer_id, callData?._call_id, {to:  watch('phoneNumber') })
    }
    setTimerActive(true);
    const call = client?.newCall({
      destinationNumber: watch('edit') ? watch('phoneNumber') : callData?.to,
      callerNumber: fromNumber ? fromNumber : callData?.from_number[0].number,
      clientState: JSON.stringify({
        robodialer_id: callData?._robodialer_id,
        call_id: callData?._call_id,
        agent_sip: agentSip,
        from_num: fromNumber ? fromNumber : callData?.from_number[0].number,
        to: watch('edit') ? watch('phoneNumber') : null
      })
    });
    
    /* const response = await initiateCall(callData?._robodialer_id, callData?._call_id, {
      sidekick: true,
      agent_sip: agentSip,
      to: watch('edit') ? watch('phoneNumber') : null
    }) */
    /* handleTelnyxEvent('userAction: initWebCall', {
      custom:true,
      date: (new Date()).toISOString(),
      payload: {
        robodialer_id: callData?._robodialer_id!, 
        call_id: callData?._call_id,
        data: {
          sidekick: true,
          agent_sip: agentSip,
          to: watch('edit') ? watch('phoneNumber') : null
        }
      },
      response
    }, data?.user?.email) */
    //if(response.httpCode === 200){
    setCallInitiated(true)
    setBtnsDisabled(true)
    //}
    setstartOfHoldTime(null)
    setStartOfIVRTime(null)
    setStartOfHumanConversation(null)
    setIsFirstCallSubmitted(false)
    //setInitWebCallData(response.data)
    setInitiateCallIsLoading(false)
    /* if (response.httpCode === 200) {
      toast.success('Call initiated! Please wait for us to connect your call, this may take up to 60 seconds', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
    } else {
      toast.error(response?.httpCode + ' Unable to initiate call:' + (response?.data?.error ?? response?.data?.detail ?? response?.msg ?? 'Server error, contact support'), {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    } */
  }

  const retryCall = () => {
    
    const nextIndex = currentNumberIndex + 1;
    if (nextIndex < processedNumbers.length) {
      toast.success("Retrying this call with a new different number, please wait (DO NOT CLICK ANYTHING WHILE YOU'RE WAITING)", {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      setCurrentNumberIndex(nextIndex);
      initWebCall(processedNumbers[nextIndex].number);
    } else {
      setCallStatus('All numbers have been attempted. No successful call. Please submit the call as "Need to retry".');
      toast.error('All numbers have been attempted. Submit the call as "Need to retry".', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      handleTelnyxEvent('systemAction: allNumbersAttempted', {
        custom:true,
        date: (new Date()).toISOString(),
        payload: {
          robodialer_id: callData?._robodialer_id!, 
          call_id: callData?._call_id,
        },
      }, data?.user?.email)
    }
  };

  const regex = /^[0-9]+$/;

  const [timerActive, setTimerActive] = useState(false);
  
  useEffect(() => {
    
    if (timerActive) {
      const timer = setTimeout(() => {
        if (['new','requesting','trying'].includes(wsCallData?.call?.state)) {
          //hangup the call, wait 1.5 seconds and retry
          wsCallData?.call?.hangup();
          setTimeout(() => {
            retryCall()
          }, 1500);
        }
        clearTimeout(timer);
        setTimerActive(false);
      }, 15000);

      if(wsCallData?.call?.state === 'active'){
        clearTimeout(timer);
        setTimerActive(false);
      }

      return () => clearTimeout(timer);
    }
  }, [wsCallData?.call?.state, timerActive]);


  return (
    <>
      {(callData?.bundle_denominator ?? 0) > 1 ?
        <div className="gap-4 mt-4 mx-4">
          <div className='flex items-center justify-center gap-x-4 p-4 bg-superbill-lavender rounded'>
            <div className='p-4 bg-superbill-indigo rounded-lg'>
            {/* <span className='flex items-center text-white gap-x-1'><strong className='text-white'>{callCount}</strong>/<span>{callData?.num_bundled_calls + 1}</span></span> */}
            <span className='flex items-center text-white gap-x-1'><strong className='text-white'>{callData?.bundle_numerator ?? 0}</strong>/<span>{callData?.bundle_denominator ?? 0}</span></span>
            </div>
            <div>
              <span className='text-sm font-jacarta text-superbill-jacarta'><strong>Call Bundling In Progress. Stay on the line for {callData?.bundle_denominator ?? 0} calls.</strong> When you submit the results, you will <strong>continue talking to the same representative.</strong></span>
            </div>
          </div>
        </div>
      :null}
      <div className='mt-5 mb-1 text-xs text-superbill-jacarta mx-4'><strong>appV: {appV}</strong> | {wsCallData?.call?.state !== 'destroy' ? <strong>{callStatus}</strong> : 'Call has been disconnected.'} <strong>{wsCallData?.call?.state === 'active' ? 'Connected' : ''}</strong></div>
      <SidekickCallsContentToolbar 
      isGetCallLoading={isGetCallLoading}
      setIsGetCallLoading={setIsGetCallLoading}
      openIsuesModal={openIsuesModal} wsCallData={wsCallData} wsSetCallData={wsSetCallData} user_email={user_email} showKeypad={showKeypad} toggleKeypad={toggleKeypad} getNextCallDisabled={wsCallData?.call?.state === 'active' && 0 === callData?.num_bundled_calls || btnsDisabled} initiateWebCallDisabled={callInitiated || btnsDisabled} phoneNumber={watch('phoneNumber')} initiateCallIsLoading={initiateCallIsLoading} register={register} editChecked={watch('edit')} initWebCall={initWebCall} callData={callData} discard={discard} getNextCall={getNextCall} robodialerId={callData?._robodialer_id || null} />
      {callData ?
        <>
        { callData?.transcript && typeof callData?.transcript === 'string' ? <div className='mt-4 mx-4 bg-superbill-american-blue  rounded px-4 py-6'>
            <h5 className='text-white'><span className='font-bold text-white pb-2'>Transcript:</span></h5>
            <div className='mt-2 h-96 overflow-y-auto bg-white rounded p-3'>
              <h5 className=''>{(callData?.transcript)?.split('\n').map((line, index) => (
                  <p key={'script_instructions_'+index} className=' text-superbill-jacarta'>{line}</p>
                ))
              }</h5>
            </div>
          </div> : null}
          {scriptData && (scriptData?.instructions) ? <div className='mt-4 mx-4'>
            <h5 className='text-superbill-jacarta'>Script Instructions:</h5>
            <div className='flex items-center justify-start border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
              <h5 className='font-bold text-superbill-jacarta'>{formatString(scriptData?.instructions).split('\n').map((line, index) => (
                <p key={'script_instructions_'+index} className='font-bold text-superbill-jacarta'>{line}</p>
              ))
      }</h5>
            </div>
          </div> : null}
          {callData && (callData?.ivrInstructions) ? <div className='mt-4 mx-4'>
            <h5 className='text-superbill-jacarta'>IVR Instructions:</h5>
            <div className='flex items-center justify-start border border-superpay-soap rounded p-4 bg-superbill-table-grey'>
              <h5 className='font-bold text-superbill-jacarta'>{formatString(callData?.ivrInstructions).split('\n').map((line, index) => (
                <p key={'ivr_instructions_'+index} className='font-bold text-superbill-jacarta'>{line}</p>
              ))
      }</h5>
            </div>
          </div> : null}
          <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
            <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-4 bg-superbill-anti-flash-white'>
              <div className="flex items-center w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Call Information</div>
              </div>
              <div className='border border-superpay-soap divide-y divide-superpay-soap rounded'>
                <div className="flex gap-4 bg-superbill-anti-flash-white-darken">
                  <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Assigned to:</span>
                  <span className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>{callData?.assignedToRetoolUser}</span>
                </div>
                <KeyValueGrid
                  aspect="3-7"
                  icon={<div className='absolute right-0 bottom-2 hidden group-hover:block cursor-pointer bg-[#f3f2fe] px-2'>
                    <CopyIcon className='hover:fill-superbill-indigo' />
                  </div>}
                  action={onCopy}
                  data={{
                    "Robodialer ID": callData?._robodialer_id,
                    "Script ID": callData?.scriptId,
                    "Batch ID": callData?.batchId,
                    "Call ID": callData?._call_id,
                    "Attempts": callData?.n_attempts ?? '-',
                  }} />
              </div>
              {callData?.inputs ?
                <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-2'>
                  <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                    <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Inputs</div>
                    <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Value</div>
                  </div>
                  <KeyValueGrid aspect="4-6" data={filterObject(callData?.inputs ?? {})} />
                </div>
                : null}
            </div>
            <div className='overflow-y-auto h-[600px] border border-superpay-soap rounded p-2 col-span-6 bg-superbill-anti-flash-white'>
              <div className="flex items-center justify-between w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Script</div>
                <div className='cursor-pointer hover-underline-animation text-xs' onClick={fetchScript}>Reload</div>
              </div>
              {scriptData ?
                <div className="border border-superpay-soap rounded p-2 bg-white">
                  {/* <ScriptOverview2 questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>}/> */}
                  <ScriptOverview questions={scriptData.questions} callInputs={callData?.inputs as Record<string, string>} />
                </div>
                : null}
            </div>
          </div>
          <div className="grid grid-cols-10 gap-4 mt-4 mx-4">
            <div className='overflow-y-auto h-[600px] bg-superbill-table-grey border border-superpay-soap rounded p-2 col-span-4'>

              {/* <div className='border border-superpay-soap divide-y divide-superpay-soap rounded p-2'>
                <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                  <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Key</div>
                  <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Value</div>
                </div>
                <KeyValueGrid
                  aspect="3-7"
                  data={tableData} />
              </div> */}


              {callData?.inputs ?
                <div className='border border-superpay-soap divide-y divide-superpay-soap rounded mt-2'>
                  <div className="grid grid-cols-2 gap-4 bg-superbill-anti-flash-white-darken">
                    <div className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">Question ID</div>
                    <div className='col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta '>Answer</div>
                  </div>
                  <KeyValueGrid aspect="4-6" data={selectedOptionsValuesOnly as Record<string, string>} />
                </div>
                : null}
            </div>
            <div className='overflow-y-auto h-[600px] bg-superbill-table-grey border border-superpay-soap rounded p-2 col-span-6'>
              <div className="flex items-center justify-start w-full py-2">
                <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Form</div>
              </div>
              {scriptData ?
                <div className="border border-superpay-soap rounded p-2 bg-white">
                  <ScriptForm callInputs={callData?.inputs as Record<string, string>} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} questions={scriptData?.questions || []} />
                </div>
                : null}
            </div>
          </div>
          <div className='mt-4 mx-4 border border-superpay-soap p-4 bg-superbill-table-grey rounded'>
            <SubmitFormComponent onSubmit={submitCallForm} bundled={(callData?.bundle_numerator ?? 0) < (callData?.bundle_denominator ?? 0)} />
          </div>
        </>
        : null}
      {/* <RetoolPage /> */}
    </>
  );
}
