import React from "react";
import { ReactComponent as CopyIcon } from "sign-up/superpay/assets/copy.svg";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import { GenerateSecretModalStates } from "enums/enums";
import GenerateSecretPage from "./generate-secret-page";
import RegenerateSecretPage from "./regenerate-secret-page";
// custom styles
const customStyles = {
  content: {
    height: "max(40%,300px)",
    maxWidth: "max(50%,480px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
interface SecretFormData {
  secret: string;
}
type GenerateSecretModalProps = {
  hasSecret?: boolean;
  apiType?: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  modalState?: GenerateSecretModalStates;
}
export default function GenerateSecretModal({ hasSecret, apiType, modalState, isOpen, onClose, onSuccess }: GenerateSecretModalProps) {

  const [currentState, setCurrentState] = React.useState<GenerateSecretModalStates>(hasSecret ? GenerateSecretModalStates.RegenerateSecret : GenerateSecretModalStates.GenerateSecret);
  

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        {currentState === GenerateSecretModalStates.GenerateSecret ? (
          <GenerateSecretPage onClose={onClose} onSuccess={onSuccess} apiType={apiType}/>
        ) : null}
        {currentState === GenerateSecretModalStates.RegenerateSecret ? (
          <RegenerateSecretPage onClose={onClose} onNext={() => {
            setCurrentState(GenerateSecretModalStates.GenerateSecret)
          }}/>
        )
          : null}

      </Modal>
    </div>
  );
};
