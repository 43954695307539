import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  onAuthStateChanged,
  Auth,
  User,
} from "firebase/auth";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { updateRobodialerUser, signinLog } from "apis/robodialer";

type SignInFormProps = {
  agent?: boolean;
};

const SignInForm = ({ agent = false }: SignInFormProps) => {
  const [captchaRef, setCaptchaRef] = useState<ReCAPTCHA | null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [verificationId, setVerificationId] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [phonePreview, setPhonePreview] = useState<string>("");
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);
  const [auth, setAuth] = useState<Auth | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [displayEnterCode, setDisplayEnterCode] = useState<boolean>(false);
  const [stay30Days, setStay30Days] = useState<boolean>(true);

  const handleCheckboxChange = (event) => {
    setStay30Days(event.target.checked);
  };
  
  



  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  function handleCaptchaChange(value) {
    setCaptcha(value);
  }

  useEffect(() => {
    const authInstance = getAuth();
    setAuth(authInstance);

    const unsubscribe = onAuthStateChanged(authInstance, (user) => {
      setUser(user);
    });

    // Initialize RecaptchaVerifier
    const recaptchaVerifierInstance = new RecaptchaVerifier(
      "recaptcha-container-id",
      {
        size: "invisible",
      },
      authInstance
    );
    setRecaptchaVerifier(recaptchaVerifierInstance);

    // Cleanup function to unsubscribe from the listener
    return () => {
      unsubscribe();
      // Cleanup RecaptchaVerifier
      if (recaptchaVerifierInstance) {
        recaptchaVerifierInstance.clear();
      }
    };
  }, []);

  function formatPhoneNumber(inputString) {
    const lastFourChars = inputString.slice(-4);
    const formattedPhoneNumber = `(XXX) XXX - ${lastFourChars}`;
    return formattedPhoneNumber;
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    if (recaptchaVerifier) {
      try {
        setLoading(true);
        setLoginError(false);
        const auth = getAuth();
        auth.tenantId = agent
          ? "AgentsTenant-wf9gw"
          : "RobodialersTenant-hvzmy";
        await signInWithEmailAndPassword(auth, data.email, data.password)
          .then(function (userCredential) {
            // User is not enrolled with a second factor
            signinLog({
              email: data.email,
              failed: false,
            });
            // Currently softly making them do 2fa but they can just type in the dashboard URL to skip...
            window.location.assign("/super-dial-2fa");
          })
          .catch(async function (error) {
            //console.log('error.code', error.code);
            if (captchaRef) {
              captchaRef?.reset()
            }
            if (error.code === 'auth/multi-factor-auth-required') {
                const resolver = getMultiFactorResolver(auth, error);
                setPhonePreview((resolver.hints[0] as any).phoneNumber);
                // Ask user which second factor to use.
                if (resolver.hints[0].factorId ===
                    PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[0],
                        session: resolver.session
                    };
                    const phoneAuthProvider = new PhoneAuthProvider(auth);
                    // Send SMS verification code
                    if (!displayEnterCode) {
                      setVerificationId(await phoneAuthProvider.verifyPhoneNumber(
                        phoneInfoOptions,
                        recaptchaVerifier
                      ));
                      console.log('phoneInfoOptions', {phoneInfoOptions});
                    setDisplayEnterCode(true);
                    } else {
                      const cred = PhoneAuthProvider.credential(
                            verificationId, verificationCode);
                        const multiFactorAssertion =
                            PhoneMultiFactorGenerator.assertion(cred);
                        // Complete sign-in.
                        await resolver.resolveSignIn(multiFactorAssertion);
                        const currentDate = new Date();
                        // stay30Days is true if "remeber me" is checked
                  let date = stay30Days
                    ? currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
                    : currentDate.getTime() + 60 * 60 * 1000;
                  await updateRobodialerUser({
                    signInExpires: new Date(date),
                    signedInDate: currentDate,
                  });
                }
              }
            } else if (error.code === 'auth/wrong-password') {
                // Handle other errors such as wrong password.
                signinLog({
                  email: data.email,
                failed: true,
              });
              setLoginError(true);
            }else{
              setLoginError(true);
            }
          });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        signinLog({
          email: data.email,
          failed: true,
        });
        setLoginError(true);
        //console.log(err);
      }
    }
  };

  return (
    <>
      {displayEnterCode ? (
        <>
          <form className="mb-5 max-w-[500px]" autoComplete="off">
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              className="hidden"
            />
            <div className="mt-4">
              <div className="flex flex-col max-w-[300px]">
                <div className="font-jakarta text-superbill-jacarta text-sm">
                  Please verify your identity by entering the verification code
                  we sent to{" "}
                  <span className="font-semibold">
                    {formatPhoneNumber(phonePreview)}
                  </span>
                </div>
                <input
                  id="verificationCode"
                  type="text"
                  className={`w-full rounded text-sm font-jakarta border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </div>
            </div>
            <div className="my-2.5 text-red-500 text-xs text-center">
              {loginError ? loginError : <span></span>}
            </div>
            <div className="flex flex-col text-right justify-center items-center">
              <button
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 w-full"
                type="submit"
              >
                {loading && (
                  <svg
                    role="status"
                    className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
                {loading && <span></span>}
                {!loading && <span>Verify</span>}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="max-w-sm mx-auto">
            <div className="my-4">
              <div className="font-jakarta text-superbill-jacarta font-semibold mb-1 text-sm">
                Email
              </div>
              <input
                type="email"
                className={`w-full mb-2 rounded font-sans ${
                  errors.email
                    ? "border-red-500"
                    : "border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                }`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <div className="mb-1 text-red-500 text-xs">
                  {errors && errors?.email?.message && typeof errors?.email?.message === 'string' ? <span>{errors?.email?.message}</span> : <span></span> } 
                </div>
            </div>
            <div className="mb-4">
              <div className="flex justify-between text-sm mb-1">
                <div className="font-jakarta text-superbill-jacarta font-semibold">
                  Password
                </div>
                <a
                  href={
                    agent
                      ? "/agents-forgot-password"
                      : "/super-dial-forgot-password"
                  }
                  className="text-center text-superbill-indigo justify-center clickable hover-underline-animation w-fit font-jakarta"
                >
                  Forgot password?
                </a>
              </div>
              <input
                id="password"
                type="password"
                placeholder="Password"
                className={`w-full mb-2 rounded font-sans ${
                  errors?.password
                    ? "border-red-500"
                    : "border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                }`}
                {...register("password", { required: 'Required' })}
              />
              <div className="mb-1 text-red-500 text-xs">
                  {errors && errors?.password?.message && typeof errors?.password?.message === 'string' ? <span>{errors?.password?.message}</span> : <span></span> } 
                </div>
              <div>
                <input
                  type="checkbox"
                  id="staySignedIn"
                  className="rounded"
                  {...register("staySignedIn")}
                  onChange={handleCheckboxChange}
                  defaultChecked={stay30Days}
                />
                <label
                  htmlFor="staySignedIn"
                  className="text-sm text-superbill-jacarta ml-2 font-semibold"
                >
                  Remember me for 30 days
                </label>
              </div>
            </div>
            <div className="flex justify-center items center mb-2">
              {!agent ? (
                <ReCAPTCHA
                  ref={e => (setCaptchaRef(e))} 
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={handleCaptchaChange}
                />
              ) : null}
            </div>
            <div id="recaptcha-container-id" className="mt-4"></div>
            {loginError ? (
              <div className="color-red my-3.5">
                The email or password you entered is incorrect. Please try
                again!
              </div>
            ) : (
              <div> </div>
            )}
            <div className="flex flex-col text-right justify-center items-center">
              <button
                disabled={!isValid || loading || (!agent && !captcha)}
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 w-full"
                type="submit"
              >
                {loading && (
                  <svg
                    role="status"
                    className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
                {loading && <span></span>}
                {!loading && <span>Sign in</span>}
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default SignInForm;
