import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'assets/upload.svg';

type UploadCSVComponentProps = {
  setCsvFile: React.Dispatch<React.SetStateAction<any>>;
  csvFile: any;
  maxRows: number;
}

const UploadCSVComponent = ({setCsvFile, csvFile, maxRows=500}: UploadCSVComponentProps) => {
  const [fileContent, setFileContent] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setErrorMessage('')
    if (fileRejections.length > 0) {
      setErrorMessage(fileRejections[0].errors[0].message)
      return;
    }
    if (acceptedFiles.length > 0) {
      setCsvFile(acceptedFiles[0])
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const content = e.target.result as string;
          //console.log('content', content);
          
          setFileContent(content);
          setFileName(file.name);
        }
      };

      reader.readAsText(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {
    'text/plain': [],
    "text/comma-separated-values": [],
    "text/csv": [],
    "application/csv": [],
    "application/excel": [],
    "application/vnd.ms-excel": [],
    "application/vnd.msexcel": [],
  } });
  
  const removeFile = () => {
    setFileContent('');
    setFileName('');
    setCsvFile(null);
  };
  const renderFileName = () => {
    return (
      <>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className='mb-5 items-center flex justify-between rounded px-2 text-s font-semibold leading-5 bg-white border border-1 border-superbill-soap bg-superbill-anti-flash-white'>
          <p className='truncate p-2'>{fileName}</p>
          <div>
            <svg
              onClick={removeFile}
              xmlns='http://www.w3.org/2000/svg'
              className='cursor-pointer h-5 w-5 fill-[#4A43CD] hover:fill-[#AB60BB]'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {fileContent && fileName && renderFileName()}
      {!fileContent && !fileName && 
        <div {...getRootProps()} className="border border-dashed border-superbill-soap p-4 rounded text-center">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-gray-500 my-20">Drop here...</p>
          ) : (
            <div className="p-4">
              <div className='flex flex-col justify-center items-center'>
                <UploadIcon />
                <div className='my-1 text-base text-superpay-light-text-gray'>Drag & Drop
                  <div className='text-center'><strong>OR</strong></div>
                </div>
              </div>
              <button
                className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              >
                Upload
              </button>
              <div className="text-sm text-center text-superbill-slate-grey mt-3">Limit of {maxRows} rows</div>
            </div>
          )}
          <div className="flex items-center mb-4">
          </div>
        </div>
      }
      {
        errorMessage && <div className="text-red-500 text-center p-3">{errorMessage}</div>
      }
      {/* <label className="mt-2">
        <input className='checked:bg-superbill-ultramarine rounded-sm mr-2 text-sm' type="checkbox" checked={showPreview} onChange={togglePreview} />
        Show Preview
      </label> */}
      {/* {fileContent && showPreview && (
        <div className="mt-4">
          <h2 className="text-xl font-bold">FILE:</h2>
          <pre className="bg-gray-100 p-4 mt-2">{fileContent}</pre>
        </div>
      )} */}
    </div>
  );
};

export default UploadCSVComponent;
