import { BaseQuestion, isBooleanQuestion, isMultipleChoiceQuestion, isSimpleQuestion, Question } from "../script-builder/create-script/newQuestionsType";

export const extractInputs = (questions: Question[]): string[] => {
  const inputs: string[] = [];


  const extractFromQuestion = (questionObj: Question | BaseQuestion) => {
    
    const extractFromText = (text: string | undefined) => {
      if (text) {
        const regex = /\{([^}]+)\}/g;
        let match;
        while ((match = regex.exec(text)) !== null) {
          inputs.push(match[0]);
        }
      }
    };

    extractFromText(questionObj.question);
    
    extractFromText(questionObj.backgroundContext);
    
    extractFromText(questionObj.responseInstruction);
    
    if (questionObj.questions) {
      
        questionObj.questions.forEach((nestedQuestion) => {
          extractFromQuestion(nestedQuestion);
        });
    }
  };

  
  questions.forEach((question) => {
    extractFromQuestion(question);
  });

  return inputs;
};


// Example usage
//const inputs = [ "npi", "providerid", "taxid", "input1", "input2", "input11", "anotherInput1", "anotherInput2", "anotherAnotherInput2", "memberId11" ];
// Output: ["npi", "providerid", "taxid", "input1", "anotherInput1"]
export const removeEndingWithNumber = (inputs) => {
  return inputs.filter(input => {
    // Include inputs that:
    // 1. Do not end with a number
    // 2. End with a letter followed by the number 1
    return !/\d$/.test(input) || /[a-zA-Z]1$/.test(input);
  });
};



export const cleanQuestionInputs = (inputs: string[], requiredOnly:boolean = true): string[] => {
  const uniqueInputs = new Set(
    inputs.map(input => input.replace(/[{}]/g, "")) 
  );
  let toReturn =  Array.from(uniqueInputs);
  if (requiredOnly) {
    toReturn = removeEndingWithNumber(toReturn);
  }
  return toReturn
};

export const getRequiredInputsOnly = (questions: Question[]): string[] => {
  return cleanQuestionInputs(extractInputs(questions), true);
}