import React, { useEffect } from "react";
import Modal from "react-modal";
import { AutoVerificationModalStates } from "enums/enums";

import { RunScriptErrosType, Script } from "types/types";
import EnablePage from "./enable-page";
import { useNavigate } from "react-router-dom";
// custom styles
const customStyles = {
  content: {
    height: "max(30%,350px)",
    maxWidth: "max(50%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

// components
type AutoVerificationModalProps = {
  modalState?: AutoVerificationModalStates;
  isOpen: boolean;
  onClose: () => void ;
  onSuccess?: () => void ;
}
export default function AutoVerificationModal({ modalState = AutoVerificationModalStates.EnableVerification,
  isOpen,
  onClose,
  onSuccess = () => {},
   }: AutoVerificationModalProps) {
  const [currentState, setCurrentState] = React.useState<AutoVerificationModalStates>(modalState);
  
  const toggleModal = () => {
    setCurrentState(AutoVerificationModalStates.EnableVerification)
    onClose()
  };
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        {currentState === AutoVerificationModalStates.EnableVerification ? (
          <EnablePage onClose={toggleModal} onSuccess={() => {
            onSuccess()
            toggleModal()
         }}/>
        ) : null}
      </Modal>
    </div>
  );
};
