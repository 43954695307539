import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as PlusIcon } from 'assets/plus-circle.svg';
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import ExpandableObject from "./expandable-object";
interface FirestoreTableProps {
  path: string;
  onClick: (newPath: string, index: number, item: string) => void;

  data: {
    [key: string]: any;
  };
}

const FirestoreTable: React.FC<FirestoreTableProps> = ({
  path,
  onClick,
  data,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pathParts = path.split("/").filter((part) => part);
  let accumulatedPath = "";
  

  const getObject = (data, path) => {
    const parts = path.split("/").filter((part) => part);
    let current = data;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (current[part]) {
        current = current[part];
      } else {
        return {};
      }
      if (
        (i + 1) % 2 === 0 &&
        current["_subcollections"] &&
        parts.length > i + 1
      ) {
        current = current["_subcollections"];
      }
    }
    return current;
  };

  
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [path]);

  return (
    <div className="flex justify-start  border border-superpay-soap rounded w-full h-[550px]">
      <div ref={containerRef} className="flex items-center divide-x divide-superpay-soap w-full bg-white overflow-x-auto">
        {pathParts.map((part, index) => {
          const currentAccumulatedPath = `${accumulatedPath}/${part}`;
          accumulatedPath = `${accumulatedPath}/${part}`;
          const obj = getObject(data, accumulatedPath);
          const subcollections = Object.keys(obj).filter(
            (key) => key === "_subcollections"
          );
          const fields = Object.keys(obj).filter(
            (key) => key !== "_subcollections"
          );
          
          return (
            <div
              key={index}
              className="min-w-96 flex-grow-0 w-96 h-full bg-white overflow-auto border-r border-superpay-soap"
            >
              <div className="px-4 py-2 text-center sticky top-0 bg-white border-b border-superpay-soap">
                <span className="text-sm font-bold text-superbill-jacarta ">
                  {part}
                </span>
              </div>
              <div className="bg-white overflow-y-auto">
                {subcollections.length > 0 ? (
                  <div className="p-2 flex justify-between items-center">
                    <div className="text-xs font-bold text-superbill-jacarta ">
                      Collections
                    </div>
                    {/* <div onClick={() => {
                        //console.log('currentAccumulatedPath', currentAccumulatedPath);
                        //create a new collection
                    }}>
                      <PlusIcon />
                    </div> */}
                  </div>
                ) : null}
                {subcollections.map((key) => {
                  const renderObj = obj[key];
                  return (
                    <React.Fragment key={key}>
                      {key === "_subcollections" &&
                        renderObj &&
                        Object.keys(renderObj).map((subkey) => (
                          <div key={subkey}>
                              <div className="cursor-pointer flex justify-between items-center text-superbill-slate-grey hover:text-indigo-600 font-normal p-2"
                                onClick={() =>
                                  onClick(
                                    `${accumulatedPath}/${subkey}`,
                                    index + 1,
                                    subkey
                                  )
                                }>
                                <div>{subkey}</div>
                                <ForwardIcon />
                              </div>
                          </div>
                        ))}
                    </React.Fragment>
                  );
                })}
                {subcollections.length > 0 ? (
                  <div className="border-b border-superpay-soap my-2" />
                ) : null}
                {fields.map((key) => {
                  const renderObj = obj[key];
                  return (
                    <React.Fragment key={key}>
                      <div>
                        {index % 2 === 0 ? (
                          <>
                            <div
                              className="cursor-pointer text-center text-superbill-slate-grey hover:text-indigo-600 font-normal p-2"
                              onClick={() => {
                                if (index % 2 === 0) {
                                  onClick(
                                    `${accumulatedPath}/${key}`,
                                    index + 1,
                                    key
                                  );
                                }
                              }}
                            >
                              {key}
                            </div>
                          </>
                        ) : null}
                        {index % 2 !== 0 && key !== "_subcollections" ? (
                          <div
                            className="cursor-pointer text-left text-superbill-slate-grey font-normal p-2"
                            onClick={() => {
                              if (index % 2 === 0) {
                                onClick(
                                  `${accumulatedPath}/${key}`,
                                  index + 1,
                                  key
                                );
                              }
                            }}
                          >
                            <span className="flex items-center">
                              {key}:<ExpandableObject data={renderObj} />
                              {/* {key}: <strong>{JSON.stringify(renderObj)}</strong> */}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FirestoreTable;
