import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useOutletContext } from 'react-router-dom';
import { WebhookDataType } from 'types/types';
import { generateApiKeys } from 'apis/robodialer';
import GenerateSecretModal from 'script-builder/dashboard/modals/generate-secret-modal/generate-secret-modal';
import { ReactComponent as LinkIcon } from 'assets/external-link-white.svg';
import { ReactComponent as CopyIcon } from 'assets/icon_copyToClipboard.svg'
import { ReactComponent as GenerateIcon } from 'assets/icon_generate.svg'
import { ReactComponent as RegenerateIcon } from 'assets/icon_regenerate.svg'

interface FormData {
  apiKey: string;
  developerSandboxKey: string;
  secret: string;
  apiKeys;
}



interface ContextProps {
  webhookData: WebhookDataType
  refreshSecret: () => void
}

const KeysPage: React.FC = () => {
  const [props] = useOutletContext<Array<ContextProps>>();
  // const { apiKey, hasApiSecretSandbox, hasApiSecretProd, developerSandboxKey, apiKeys, /* status */ } = webhookData;
  const apiKey = props.webhookData.apiKey;
  const apiKeys = props.webhookData.apiKeys;
  const developerSandboxKey = props.webhookData.developerSandboxKey;
  const hasApiSecretSandbox = props.webhookData.hasApiSecretSandbox;
  const hasApiSecretProd = props.webhookData.hasApiSecretProd;
  const [isGenerateSecretModalOpen, setIsGenerateSecretModalOpen] = useState<boolean>(false);
  const [hasProdKey, setHasProdKey] = useState<boolean>(false);
  const [hasSandboxKey, setHasSandboxKey] = useState<boolean>(false);
  const [apiType, setApiType] = useState<string>('');
  const [hasApiSecret, setHasApiSecret] = useState<boolean>(false);
  const [statusIsLive, setStatusIsLive] = useState<boolean>(false);
  
  const form = useForm<FormData>({
    defaultValues: {
      apiKey: apiKey ? apiKey : '',
      developerSandboxKey: developerSandboxKey ? developerSandboxKey : '',
      apiKeys: apiKeys
    },
  });

  function capitalizeFirstLetter(input: string): string {
    if (input.length === 0) return input; // Return empty string if input is empty
    return input.charAt(0).toUpperCase() + input.slice(1);
  }


  const onCopy = (text) => {

    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }

  useEffect(() => {
    if (apiKeys && apiKeys.hasOwnProperty('prod')) {
      setHasProdKey(true);
    } else {
      setHasProdKey(false);
    }
    if (apiKeys && apiKeys.hasOwnProperty('sandbox')) {
      setHasSandboxKey(true);
    } else {
      setHasSandboxKey(false);
    }
    if (props.webhookData.status) {
      setStatusIsLive(props.webhookData.status==='live');
    } else {
      setStatusIsLive(true)
    }
  }, [apiKey]);

  const handleGenerateKeys = async (type) => {
    await generateApiKeys(type); // Wait for generateApiKeys to complete
    window.location.reload(); // Reload the page after generateApiKeys finishes
  };


  return (
    <>
        <div className='flex items-center justify-center w-full px-8 bg-superdial-background-pink bg-cover h-fit border-b border-superbill-soap !min-h-[85px] h-[110px] rounded mb-10'>
        <div className='flex justify-between w-full lg:pl-5'>
          <div className='flex flex-row w-full justify-start items-center'>
            <div className='text-xl font-jakarta text-white'>
            Seamlessly place calls with <strong>SuperDial API</strong>
            </div>
          </div>
          <a href="https://superdial.readme.io/" target="_blank" rel="noreferrer">
            <div className="ml-5 flex items-center cursor-pointer">
              <div className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-white font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 flex items-center'>
                <span className=' text-white text-sm font-semibold font-jakarta text-nowrap'>
                  Documentation
                </span>
                <LinkIcon className='ml-2'/>
              </div>
            </div>
          </a>
        </div>
      </div>
        {apiKey ?
        <>
        <div className='py-4 px-6 bg-white border border-superbill-soap block w-full rounded font-sans'>
        <form key={apiKey} className="space-y-2">
          <div className="flex items-center">
            <label htmlFor={apiKey} className="mr-2 text-superbill-jacarta text-base font-bold text-nowrap">
              API Key: 
            </label>
            <div className="w-full rounded bg-superbill-anti-flash-white p-2 focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base">
              {apiKey}
            </div>
            <div className="ml-5 flex items-center cursor-pointer">
              <div onClick={() => { onCopy(apiKey) }} className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'>
                <span className=' text-superbill-jacarta text-sm font-semibold font-jakarta text-nowrap'>
                  Copy to Clipboard
                </span>
              </div>
            </div>
          </div>
        </form>
        <div className='w-full border-b border-superbill-soap my-4'></div>
        <div className="w-full justify-between items-center flex">
          <label htmlFor="secret" className="mr-2 text-superbill-jacarta text-base font-bold w-[120px]">
            Secret
          </label>
          <div className="flex items-center cursor-pointer">
            <button  onClick={() => {setIsGenerateSecretModalOpen(true)}} className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'> <span className=' text-superbill-jacarta text-sm font-semibold font-jakarta'>
              {hasApiSecret ? 'Regenerate Secret' : 'Generate Secret'}
            </span></button>
          </div>
      </div>
        </div>
        </>
        :
        apiKeys ?
          <div className='flex gap-2'>
            {apiKeys && Object.entries(apiKeys).map(([key, value]) => (
              <div className='w-full'>
              <div className='font-bold text-superbill-jacarta mb-2'>{capitalizeFirstLetter(key)}</div>
              <div className='py-4 px-6 bg-white border border-superbill-soap block rounded font-sans'>
              <form key={key} className="space-y-2">
                <div className="">
                  <div className="mr-2 text-superbill-jacarta text-base font-bold text-nowrap mb-2">
                    Key 
                  </div>
                  <div className='flex'>
                  <div className="w-full rounded bg-superbill-anti-flash-white p-2 focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base font-mono">
                    {value}
                  </div>
                  <div className="ml-2 flex items-center cursor-pointer">
                    <div onClick={() => { onCopy(value) }} className='bg-transparent font-jakarta hover:bg-superbill-anti-flash-white text-superbill-jacarta font-semibold p-2 text-sm border border-superbill-soap rounded ease-in-out duration-300'>
                      <span className=' text-superbill-jacarta text-sm font-semibold font-jakarta text-nowrap flex items-center'>
                        <CopyIcon className='mr-1'/>
                        Copy
                      </span>
                    </div>
                  </div>
                  </div>
                </div>
              </form>
              <div className="w-full justify-between items-center flex mt-6">
                <div className="mr-2 text-superbill-jacarta text-base font-bold mb-2">
                  Secret
                </div>
                <div className='flex items-center'>
                {(key ==='sandbox' && hasApiSecretSandbox) || (key ==='prod' && hasApiSecretProd)  ? 
                    <div className='text-xs text-superbill-slate-grey font-sans text-right mr-2'>
                    Regenerating a secret will expire the existing secret. This action cannot be undone.
                  </div>
                    : null
                  }
                </div>
            </div>
            <div className='flex'>
              {
                (key === 'sandbox' && hasApiSecretSandbox) || (key === 'prod' && hasApiSecretProd) ?
              <div className="w-full rounded bg-superbill-anti-flash-white p-2 focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base font-mono mr-2">
                **************
              </div>
              : null
              }
              <div className="flex items-center cursor-pointer">
                  <button  onClick={() => {setHasApiSecret(key === 'sandbox'? hasApiSecretSandbox : key ==='prod'? hasApiSecretProd : false); setIsGenerateSecretModalOpen(true); setApiType(key);}} className='bg-transparent font-jakarta hover:bg-superbill-anti-flash-white text-superbill-jacarta font-semibold p-2 text-sm border border-superbill-soap rounded ease-in-out duration-300'> <span className=' text-superbill-jacarta text-sm font-semibold font-jakarta flex items-center'>
                    {key ==='sandbox' ? 
                    hasApiSecretSandbox ? 
                    <>
                    <RegenerateIcon className='mr-1'/>
                    Regenerate
                    </>
                     : 
                     <>
                     <GenerateIcon className='mr-1' />
                    Generate
                     </>
                    :
                    key === 'prod' ?
                    hasApiSecretProd ? 
                    <>
                    <RegenerateIcon className='mr-1'/>
                    Regenerate
                    </>
                     : <>
                     <GenerateIcon className='mr-1' />
                    Generate
                     </>
                    :
                    <>
                     <GenerateIcon className='mr-1' />
                    Generate
                     </>
                  }
                  </span></button>
              </div>
            </div>
              </div>
              { key=='sandbox' && hasSandboxKey ? 
                <div className='px-2 pb-2 pt-4 bg-superbill-anti-flash-white rounded text-sm text-superbill-slate-grey border border-superbill-soap mx-4 -mt-2 -z-50 relative shadow-[inset_0_10px_5px_rgba(187,192,203,0.15)]'>
                <div className='font-bold'>
                  About the SuperDial Sandbox
                </div>
                SuperDial supports a Sandbox env for testing purposes.  
                Learn how to use your Sandbox API to
                <a href="https://superdial.readme.io/reference/place-a-call" target="_blank" rel="noreferrer"><span className="hover-underline-animation ml-1">place a call</span></a>,<a href="https://superdial.readme.io/reference/retrieve-a-call" target="_blank" rel="noreferrer"><span className="hover-underline-animation ml-1">read the call data</span></a> back, and<a href="https://superdial.readme.io/reference/testing-webhooks" target="_blank" rel="noreferrer"><span className="hover-underline-animation ml-1">test your webhooks</span></a>.
                <div className='p-2'></div>
                These test calls will not appear on the portal. They'll have a "success" status as a default value. They will contain the same format as you'd expect on live calls, but they won't have the answers of the questions.
                <div className='p-2'></div>
                When you're ready, you can use the Live (Prod) API key to place real calls.  
                Live calls will appear on the portal.</div>
              : null}
              </div>
  
          ))}
          { hasProdKey? 
          null
          : 
          <div className='w-full'>
          <div className='font-bold text-superbill-jacarta mb-2'>Prod</div>
            <div className='py-4 px-6 bg-superbill-anti-flash-white border border-superbill-soap block w-full rounded font-sans'>
              <div className='text-center p-4'>
              <div>Ready for production?</div>
              <div className='text-superbill-slate-grey mb-4'>Generate a production API to start placing real calls.</div>
              {statusIsLive?
              <button
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 margin-auto' 
              onClick={() => handleGenerateKeys({apiType:'prod'})}
            >
              Generate a Prod API Key
            </button>
            :
            <>
            <button
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 margin-auto' 
              disabled={true}
            >
              Generate Prod Key 
            </button>
            <div className='text-superbill-jacarta mt-2 w-[400px] m-auto'>
            Production API keys are only available to live customers. Contact support@superdial.ai to enable your production API key
            </div>
            </>
              }
              
              </div>
            </div>
          </div>
          }
          { hasSandboxKey ?
          <>
          </>
          :
          <div className='w-full'>
            <div className='font-bold text-superbill-jacarta mb-2'>Sandbox</div>
            <div className='py-4 px-6 bg-superbill-anti-flash-white border border-superbill-soap block w-full rounded font-sans'>
            <div className='text-center p-4'>
              <div>Need to test the API functionality?</div>
              <div className='text-superbill-slate-grey mb-4'>Use our Sandbox API to test your API configurations without placing real calls. Learn more about the Sandbox API<a href="https://superdial.readme.io/reference/environments" target="_blank" rel="noreferrer"><span className="hover-underline-animation ml-1">here</span></a>.
              </div>
              <button
                  className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 margin-auto' 
                  onClick={() => handleGenerateKeys({apiType:'sandbox'})}
                >
                  Generate a Sandbox API Key
                </button>
            </div>
            </div>
          </div>
          }
          </div>
          :
          <>
          {!apiKey && !apiKeys ? 
          <>
            <div className='py-4 px-6 bg-superbill-anti-flash-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
              <div className='text-center p-4'>
                <div>Seamlessly place calls with our API</div>
                <div className='text-superbill-slate-grey mb-4'>We'll start you off with a Sandbox API key, and you may enable a Production key once you're ready. Learn more about our Sandbox API functionality<a href="https://superdial.readme.io/reference/environments" target="_blank" rel="noreferrer"><span className="hover-underline-animation ml-1">here</span></a>.</div>
                <button
                    className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 margin-auto' 
                    onClick={() => handleGenerateKeys({apiType:'sandbox'})}
                  >
                    Generate a Sandbox API Key
                  </button>
              </div>
            </div>
          </>
          :
          null
        }
          </>
      }
      {/* {apiKey || apiKeys ? 
        <div className='py-4 px-6 bg-white border border-superbill-soap block w-full rounded mt-4'>
          <div className="w-full justify-between items-center flex">
              <label htmlFor="secret" className="mr-2 text-superbill-jacarta text-base font-bold w-[120px]">
                Secret
              </label>
              <div className="flex items-center cursor-pointer">
                <button  onClick={() => {setIsGenerateSecretModalOpen(true)}} className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'> <span className=' text-superbill-jacarta text-sm font-semibold font-jakarta'>
                  {hasApiSecret ? 'Regenerate Secret' : 'Generate Secret'}
                </span></button>
              </div>
          </div>
        </div>
      : null} */}
      {/* <div className='py-4 px-6 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
        <div className="flex justify-start items-center rounded bg-superbill-anti-flash-white my-3 py-2 px-4">
          <div className='flex justify-start items-center '>
            <InfoIcon className='min-w-[22px] child:fill-superbill-jacarta' />
          </div>
          <div className='ml-2 text-superbill-jacarta text-left text-xs sm:flex-[90] '>
            Never share your secret key in plaintext with anyone. If your secret key is compromised, please regenerate it.
          </div>
        </div>
        <div className="flex items-center">
          <label htmlFor="secret" className="mr-2 text-superbill-jacarta text-base font-bold w-[80px]">
            Secret
          </label>
          <Controller
            name="secret"
            control={secretForm.control}
            rules={{ required: 'Requiered' }}
            render={({ field }) => (
              <div className="relative w-full">
                <input
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-0 right-0 h-full flex items-center px-2"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>

              </div>
            )}
          />
          <div className='flex items-center mx-4'>
            <button
              type="button"
              onClick={() => { onCopy(secretForm.watch('secret')) }}
            >
              <CopyIcon className="h-6 w-6 child:fill-superbill-jacarta mr-4" />
            </button>
            <button
              type="button"
              onClick={onRefresh}
            >
              <RefreshIcon className={`h-6 w-6   ${isLoading ? ' animate__animated animate__rotateIn animate__infinite ' : ''}`} />
            </button>
          </div>
        </div>
      </div> */}
      <GenerateSecretModal hasSecret={hasApiSecret} isOpen={isGenerateSecretModalOpen} apiType={apiType} onClose={() => { setIsGenerateSecretModalOpen(false) }} onSuccess={() => {
          props.refreshSecret()
      }}/>
    </>
  );
};

export default KeysPage;