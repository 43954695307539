import React, { useEffect, useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Modal from "react-modal";
import { useForm } from 'react-hook-form';
import ReportIssueForm from "./report-issues-form";
import { logCallIssues } from "../../apis/agent";
// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface FormData {
  robodialerId: string;
  callId: string;
  attempt: string;
  areaText: string;
}
type ReportIssuesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  issueSubmitted: boolean;
  robodialerId: string;
  callId: string;
  attempt: string;
  cStatus: string[];
  userActions: string[];
  fesponses: string[];
  lastStatus: React.ReactNode;
  telnyxEvents: {type:string, ev: JSX.Element | Element | React.ReactNode, msg?: string}[];
  isMicrophoneAllowed: boolean;
  isOnLine: boolean;
  setIssueSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  clientConnected: boolean;
}
export default function ReportIssuesModal({ isOpen,
  onClose,
  issueSubmitted,
  robodialerId,
  callId,
  attempt,
  setIssueSubmitted,
  cStatus,
  userActions,
  fesponses,
  lastStatus,
  telnyxEvents,
  isMicrophoneAllowed,
  isOnLine,
  clientConnected
 }: ReportIssuesModalProps) {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormData>();
  
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit = async (data: FormData) => {
    // Handle form data here when submitted
    setIsLoading(true)
    console.log(data);
    const {robodialerId, callId, attempt, areaText} = data
    console.log(clientConnected, isMicrophoneAllowed, isOnLine);
    let reportData = {
      robodialerId,
      callId,
      attempt,
      description: areaText,
      clientConnected,
      isMicrophoneAllowed,
      isOnLine,

      wasOnline: cStatus,
      apiResponses:fesponses,
      userActions: userActions,
      mainEvents: telnyxEvents.filter((e) => e.type === 'main').map(e => e.msg),
      otherEvents: telnyxEvents.filter((e) => e.type !== 'main').map(e => e.msg),
    }
    console.log(reportData);
    await logCallIssues(reportData)
    setIssueSubmitted(true)
    setIsLoading(false)
    onClose();
  };

  useEffect(() => {
    setValue('robodialerId', robodialerId??"")
    setValue('callId', callId??"")
    setValue('attempt', attempt??"")

    //console.log('robodialerId callId, attempt', robodialerId, callId, attempt);
  }, [isOpen])
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Run Script Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Report Call Issue
          </nav>
          <div className="flex-grow overflow-y-auto px-9 py-4">
            <div className="w-full text-base text-superbill-jacarta my-4">This form allows you to <strong>report any issues</strong> you encountered during a call. Your feedback is important for us to improve our services and ensure an optimal experience for all our users. <strong>Please fill out the form with as much detail as possible.</strong></div>
            <form className="flex flex-col gap-4">
              <div className="grid grid-cols-3 gap-x-2">
                <div className="w-full col-span-1 flex flex-col">
                    <label className="text-xs font-bold text-superbill-jacarta" htmlFor="robodialerId">RobodialerId</label>
                    <input

                      type="text"
                      {...register("robodialerId")}
                      defaultValue={robodialerId}
                      readOnly={true}
                      className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                      placeholder="Robodialer ID"
                    />
                </div>
                <div className="w-full col-span-1 flex flex-col">
                    <label className="text-xs font-bold text-superbill-jacarta" htmlFor="callId">CallId</label>
                    <input

                      type="text"
                      {...register("callId")}
                      defaultValue={callId}
                      readOnly={true}
                      className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                      placeholder="Call ID"
                    />
                </div>
                <div className="w-full col-span-1 flex flex-col">
                    <label className="text-xs font-bold text-superbill-jacarta" htmlFor="attempt">Attempt</label>
                    <input

                      type="text"
                      {...register("attempt")}
                      defaultValue={attempt}
                      readOnly={true}
                      className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                      placeholder="Attempt"
                    />
                </div>
              </div>
              <div className="w-full text-sm text-superbill-jacarta mb-2 mt-6">
                Here you can provide any additional relevant information about the issue you experienced during the call. The more details you provide, the better we can understand and address the problem. Thank you for your cooperation.
              </div>
              <textarea
                {...register("areaText", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9,. ]*$/, // Regular expression to allow only letters, numbers, spaces, dots, and commas
                    message: "This field is required. Only letters, numbers, spaces, dots, and commas are allowed in the text area.",
                  }
                })}
                className="p-4 rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                placeholder="Type here..."
              />
              {errors.areaText && <p className="text-red-500">Only letters, numbers, spaces, dots, and commas are allowed in the text area.</p>}
            </form>
            {/* <ReportIssueForm
              defaultValueRobodialerId={robodialerId}
              defaultValueCallId={callId}
              defaultValueAttempt={attempt}
            /> */}

          </div >
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={onClose}
            >
              Close
            </button>
            <button
              disabled={issueSubmitted || isLoading}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? <Spinner /> : <>Submit</>}
            </button>
          </div>
        </div >

      </Modal>
    </div>
  );
};
