import RunScriptImg1 from "assets/upload-csv.svg";
import RunScriptImg2 from "assets/manual-input.svg";
type RunScriptInfoProps = {
  onClose: () => void;
  onNext: () => void;
}

const RunScriptInfo = ({ onClose, onNext }: RunScriptInfoProps) => {

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Welcome to SuperDial
      </nav>
      <div className="flex-grow overflow-y-auto p-9 font-sans">
        <div className='text-base text-superpay-light-text-gray'><strong>2</strong> OF 4</div>
        <div className='text-lg font-bold text-superbill-jacarta mt-2 font-jakarta'>Running your script</div>
        <div className='text-sm text-superbill-jacarta mt-2'>Click “Run script” to place your calls. You will need to provide requisite inputs depending on your script type so that we can get the results you need. <br/>For insurance verification scripts, calls can be kicked off <strong>automatically</strong> when you link your PMS. 
        <br/> For all other scripts, you may provide inputs <strong>manually</strong> in one of two ways: </div>
        <div className='text-sm font-bold text-superbill-jacarta mt-2'><strong>Upload a CSV file</strong> with input information</div>
        <div className="flex items-cneter justify-center">
          <img
            src={RunScriptImg1}
            alt="setup healthie account"
            className="w-full md:w-3/4 mt-2 max-w-[639px]"
          />
        </div>
        <div className='text-sm font-bold text-superbill-jacarta mt-2'>OR <strong>Provide information directly in our portal</strong></div>
        <div className="flex items-cneter justify-center">
          <img
            src={RunScriptImg2}
            alt="setup healthie account"
            className="w-full md:w-3/4 mt-2 max-w-[639px]"
          />
        </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3">
        <button
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
          onClick={() => {
            if (onNext) {
              onNext()
            } else {
              onClose()
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default RunScriptInfo;
