import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { CallsSettingsType } from 'types/types';
import { getCallsSettingsData } from 'apis/robodialer';



const CallSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const options = ['2BD', '1BD', '4BD hours', '2BD hours']
  const [callSettingsData, setCallSettingsData] = useState<CallsSettingsType | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getCallsSettingsData()
      setCallSettingsData(data)
    }
    fetch()
  }, [])

  useEffect(() => {
    if (callSettingsData) {
      setIsLoading(false)
    }
  }, [callSettingsData])


  return (
    <>
      {isLoading ? (< div className='w-full h-full flex justify-center items-center flex-col' >
        < Loader />
      </div>) : null}
      {!isLoading ? (
        <>
          <div className='text-superbill-jacarta font-jakarta font-semibold text-xl'>
            Call Settings
          </div>
          <div className='text-right text-superbill-jacarta text-sm mt-2 font-sans'>Want to make any changes? <a
            rel='noopener noreferrer' target='_blank' href='https://calendly.com/sam-lj4/superdial-payer-phone-call-automation'
            className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 ml-2'
          >
            Contact Support
          </a></div>
          <div className='p-2 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
            <div className="flex justify-between w-full px-4 py-3">
              <div className="">
                <div className="mr-2 text-superbill-jacarta text-base font-bold ">
                  Turnaround time
                </div>
                <div className='text-superpay-light-text-gray text-sm'>
                  We guarantee that calls will be completed within the selected time period in business days (BD)
                </div>
              </div>
              <div className="">
                <div className='flex justify-between items-center'>
                  {options.map((item) => {
                    return <div key={`selectedTurnAroundTime-${item}`} className={`${callSettingsData?.selectedTurnAroundTime === item ? ' font-bold text-superbill-indigo rounded bg-superbill-lavender-medium ' : ' text-superbill-jacarta '} text-base p-2 mx-2`}>{item}</div>
                  })}
                </div>
              </div>
            </div>

          </div>

          {/* <div className='p-2 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
            <div className="flex justify-between w-full px-4 py-3">
              <div className="">
                <div className="mr-2 text-superbill-jacarta text-base font-bold ">
                  Call Transcripts
                </div>
                <div className='text-superpay-light-text-gray text-sm'>
                  Receive a text file displaying the exchange between SuperDial and the agent/representative for each call
                </div>
              </div>
              <div className="">
                <div className='flex justify-between items-center'>
                  <div className={`${!callSettingsData?.callTranscript ? ' font-bold text-superbill-indigo rounded bg-superbill-lavender-medium rounded ' : ' text-superbill-jacarta '} text-base p-2 mx-2`}>Off</div>
                  <div className={`${callSettingsData?.callTranscript ? ' font-bold text-superbill-indigo rounded bg-superbill-lavender-medium rounded ' : ' text-superbill-jacarta '} text-base p-2 mx-2`}>On</div>
                </div>
              </div>
            </div>

          </div>

          <div className='p-2 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
            <div className="flex justify-between w-full px-4 py-3">
              <div className="">
                <div className="mr-2 text-superbill-jacarta text-base font-bold ">
                  Call Recordings
                </div>
                <div className='text-superpay-light-text-gray text-sm'>
                  Receive an audio file containing the exchange between SuperDial and the agent/representative for each call
                </div>
              </div>
              <div className="">
                <div className='flex justify-between items-center'>
                  <div className={`${!callSettingsData?.callRecording ? ' font-bold text-superbill-indigo rounded bg-superbill-lavender-medium rounded ' : ' text-superbill-jacarta '} text-base p-2 mx-2`}>Off</div>
                  <div className={`${callSettingsData?.callRecording ? ' font-bold text-superbill-indigo rounded bg-superbill-lavender-medium rounded ' : ' text-superbill-jacarta '} text-base p-2 mx-2`}>On</div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : null}





      {/* <div className='py-4 px-6 bg-white border border-superbill-soap block w-full rounded mt-4 font-sans'>
        <div className="flex justify-start items-center rounded bg-superbill-anti-flash-white my-3 py-2 px-4">
          <div className='flex justify-start items-center '>
            <InfoIcon className='min-w-[22px] child:fill-superbill-jacarta' />
          </div>
          <div className='ml-2 text-superbill-jacarta text-left text-xs sm:flex-[90] '>
            Never share your secret key in plaintext with anyone. If your secret key is compromised, please regenerate it.
          </div>
        </div>
        <div className="flex items-center">
          <label htmlFor="secret" className="mr-2 text-superbill-jacarta text-base font-bold w-[80px]">
            Secret
          </label>
          <Controller
            name="secret"
            control={secretForm.control}
            rules={{ required: 'Requiered' }}
            render={({ field }) => (
              <div className="relative w-full">
                <input
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  className="w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-0 right-0 h-full flex items-center px-2"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>

              </div>
            )}
          />
          <div className='flex items-center mx-4'>
            <button
              type="button"
              onClick={() => { onCopy(secretForm.watch('secret')) }}
            >
              <CopyIcon className="h-6 w-6 child:fill-superbill-jacarta mr-4" />
            </button>
            <button
              type="button"
              onClick={onRefresh}
            >
              <RefreshIcon className={`h-6 w-6   ${isLoading ? ' animate__animated animate__rotateIn animate__infinite ' : ''}`} />
            </button>
          </div>
        </div>
      </div> */}

    </>
  );
};

export default CallSettings;