import React, { useRef, useState } from "react";
import { ReactComponent as DragIcon } from "assets/drag.svg";
import Draggable from "react-draggable";

const KeypadComponent = ({ sendDTMF }) => {
  const keypadOptions = [
    { id: "1", name: "1" },
    { id: "2", name: "2", letters: ["A", "B", "C"] },
    { id: "3", name: "3", letters: ["D", "E", "F"] },
    { id: "4", name: "4", letters: ["G", "H", "I"] },
    { id: "5", name: "5", letters: ["J", "K", "L"] },
    { id: "6", name: "6", letters: ["M", "N", "O"] },
    { id: "7", name: "7", letters: ["P", "Q", "R", "S"] },
    { id: "8", name: "8", letters: ["T", "U", "V"] },
    { id: "9", name: "9", letters: ["W", "X", "Y", "Z"] },
    { id: "*", name: "*" },
    { id: "0", name: "0", letters: ["+"] },
    { id: "#", name: "#" },
  ];

  const inputDTMFRef = useRef<HTMLInputElement>(null);
  const [clicked, setClicked] = useState<string>("");

  return (
    <Draggable>
      <div className="absolute r-100 t-0 z-50">
        <div className="mt-2 ml-4 w-fit p-4 bg-superbill-gray-background border border-superbill-soap shadow rounded z-50">
          <DragIcon className="m-auto mb-4 hover:cursor-grab" />
          <div className="bg-superbill-anti-flash-white p-2 w-full rounded border border-superbill-soap mb-2 text-right h-[42px]">
            {clicked ? clicked : ""}
          </div>
          <div className="!-mt-2 mb-1 w-full text-right">
            <span
              onClick={() => {
                setClicked("");
              }}
              className="w-fit text-xs cursor-pointer hover-underline-animation"
            >
              Clear
            </span>
          </div>
          <div className="grid grid-cols-3 gap-3 justify-around items-center mb-2">
            {keypadOptions.map((i) => {
              return (
                <button
                  className="bg-white font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 active:bg-superbill-lavender-dark"
                  onClick={() => {
                    setClicked((prevClicked) => prevClicked + i.name);
                    sendDTMF(i.name);
                  }}
                  key={"pad-" + i.name}
                >
                  <div className="leading-none">{i.name}</div>
                  <div>
                    {i.letters ? (
                      i.letters.map((l) => {
                        return (
                          <span
                            key={`letter-${l}`}
                            className="text-[8pt] text-superbill-slate-grey leading-none"
                          >
                            {l}
                          </span>
                        );
                      })
                    ) : (
                      <>
                        <span className="text-[8pt] text-white leading-none">
                          ''
                        </span>
                      </>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
          <div className="border-b border-superpay-soap my-1" />
          <div className="text-xs text-superbill-jacarta font-bold mt-2 mb-1">
            Send DTMF
          </div>
          <div className="flex items-center gap-x-2 justify-between w-full">
            <input
              className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
              ref={inputDTMFRef}
              type="text"
            />
            <button
              className="bg-white font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 active:bg-superbill-lavender-dark"
              type="button"
              onClick={() => {
                if (inputDTMFRef?.current) {
                  sendDTMF(inputDTMFRef?.current.value);
                }
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
export default KeypadComponent;
