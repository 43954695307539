import CreditsImg from "assets/calls-remaining.svg";

type  CreditsInfoProps = {
  onClose: () => void;
  onNext: () => void;
}

const CreditsInfo = ({onClose, onNext}:CreditsInfoProps) => {

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Welcome to SuperDial
      </nav>
      <div className="flex-grow overflow-y-auto p-9 font-sans">
          <div className='text-base text-superpay-light-text-gray'><strong>4</strong> OF 4</div>
          <div className='text-lg font-jakarta font-bold text-superbill-jacarta mt-2'>We're here to help</div>
          <div className='text-sm text-superbill-jacarta mt-2'>Our <strong>Settings</strong> page contains development tools to help your team achieve its goals with SuperDial. If you need additional support, reach out to support@thesuperbill.com</div>
          <div className="flex items-cneter justify-center mt-2">
          
          </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3 items-center">
        {/* <div className="text-sm text-superpay-light-text-gray mr-5">You’ll be able to view this information again on our Documentation site</div> */}
        <button
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
          onClick={() => {
            if (onNext) {
              onNext()
            }else{
              onClose()
            }
          }}
        >
          Go to Portal
        </button>
      </div>
    </div>
  );
};

export default CreditsInfo;
