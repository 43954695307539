import { getAuth } from 'firebase/auth';
import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
//import { logWarn } from './utils/Utils';
import { AuthProvider } from './routes/authProvider';
import AppRoutes from './routes';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authStatusDetermined: false, // So that we show a loader until we determine who's here
      user: null,
    };
  }

  onAuthStateChanged = async (person) => {
    //await getAuth().signOut();
    if (!person) {
      this.setState({
        user: null,
        authStatusDetermined: true,
      });
      return;
    }
    this.updateUser(person);
  };

  getNewUserData = async () => {
    const currentPerson = getAuth().currentUser;
    await currentPerson.reload();
    const updatedPerson = getAuth().currentUser;
    this.updateUser(updatedPerson);
  };

  updateUser = async (person) => {
    let { email, emailVerified, tenantId, uid, reloadUserInfo } = person;
    // mfaEnabled doesn't exist on person so it will be undefined ^

    let userType = '';
    if (tenantId === 'UsersTenant-08fbg') 
    userType = 'user'
    else if (tenantId === 'ProvidersTenant-jxisg'){
      userType = 'provider'
    }else if (tenantId === 'AgentsTenant-wf9gw'){
      userType = 'agents'
    }else{
      userType = 'robodialers'
    }
    let hasOnboarded = false;
    let planDetermined = false;
    let viewedTutorial = false;
    let superPay = false;
    let superPayPractice = false;
    let superPayPracticeEhrConfigured = false;
    let superPayPracticeEhrPracticeName = '';
    let defaultSuperPayPaymentMethod = null;
    let selfPayInvited = 0;
    let selfPayTotal = 0;
    let metInviteThresholdAt = null;
    let superPayPracticeProvider = false;
    // let mfaEnabled = false;

    let firstName = ''
    let lastName = ''
    let providersWithoutData = {}
    if (userType !== 'robodialers' && userType !== 'agents') {
      await getAuth().signOut();
    }
    //console.log('reloadUserInfo', reloadUserInfo);
    //console.log('bool reloadUserInfo.mfaInfo', !!reloadUserInfo.mfaInfo);
    //console.log('date reloadUserInfo.lastLoginAt', reloadUserInfo.lastLoginAt);
    //fetch robodialer data
    //const userResponse = await getRobodialerUser();
    this.setState({
      user: {
        email,
        emailVerified,
        tenantId,
        uid,
        userType,
        planDetermined,
        firstName,
        lastName,
        superPayPracticeProvider,
        //mfaEnabled: userResponse.httpCode === 200 ? userResponse.data.mfaEnabled : false, //get mfaEnabled from firestore
        mfaEnabled: !!reloadUserInfo.mfaInfo, //get mfaEnabled from firestore
        lastLoginAt: reloadUserInfo.lastLoginAt,
        
      },
      defaultSuperPayPaymentMethod,
      superPay,
      superPayPractice,
      superPayPracticeEhrConfigured,
      superPayPracticeEhrPracticeName,
      hasOnboarded,
      planDetermined,
      viewedTutorial,
      //mfaEnabled,
      providersWithoutData,
      authStatusDetermined: true,
      selfPayInvited,
      selfPayTotal,
      metInviteThresholdAt,
    });
  };

  async componentDidMount() {
    getAuth().onAuthStateChanged(this.onAuthStateChanged);
  }

  render() {
    return (
      <Router>
        {/* <div className='font-sans h-full w-full'>
          <Routes>{getAppRoutes(this.state)}</Routes>
        </div> */}
        <AuthProvider>
          {/* <AppRoutes /> */}
          <AppRoutes state={this.state} />
        </AuthProvider>
      </Router>
    );
  }
}
