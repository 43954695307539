import React, { useEffect, useState } from "react";
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { createScript, getScriptInputs } from "../../apis/robodialer";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";

// custom styles
const customStyles = {
  content: {
    height: "max(30%,250px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  unverified: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  onConfirm: () => void;
  onCancel: () => void;
}

// components
export default function VerifyAllModal({ isOpen, setIsOpen,unverified,  onConfirm, onCancel }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
 
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {setIsOpen(false)}}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Verify {unverified} Patients?
          </nav>
          <div className="flex-grow overflow-y-auto p-9">
            <div  className="w-full bg-white rounded">
              We will verify the selected patients using the Insurance Verification script.
            </div>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={onCancel}
              >
              Close
            </button>
            <button
              onClick={async () => {
                setLoading(true)
                await onConfirm()
                setLoading(false)
                setIsOpen(false)
              }}
              disabled={isLoading}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
              {isLoading ? <Spinner /> : <>Verify</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
