import React from 'react';

const SetupCard = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="w-fit bg-white border-2 border-superbill-soap rounded p-5 mt-4">
        <div className="text-sm text-superbill-jacarta font-bold mb-2">Patient & Policyholder</div>
        <ul className="text-sm text-superbill-jacarta">
          <li>Patient name</li>
          <li>Patient DOB</li>
          <li>Policyholder name</li>
          <li>Policyholder DOB</li>
          <li>Patient relationship to policyholder</li>
        </ul>
        <div className="text-sm text-superbill-jacarta font-bold mt-4 mb-2">Policy & Payer</div>
        <ul className="text-sm text-superbill-jacarta">
          <li>Member ID</li>
          <li>Group number (optional)</li>
          <li>Payer name</li>
          <li>Member address</li>
          <li>Subscriber's address (if different)</li>
        </ul>
      </div>
    </div>
  );
};

export default SetupCard;