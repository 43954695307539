import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { BuilderState } from "../../enums/enums";

import Loader from "../../components/Loader";
import { getRobodialerUser, getScript, getScriptInputs, updateScript } from "../../apis/robodialer";
import { useParams } from "react-router-dom";
import { ReactComponent as SuperDialIcon } from 'assets/superdial-logo-icon-blue.svg';
import DynamicTable from "./DynamicTable";
import { RobodialerUser } from "types/types";
import { ANSWER_TYPES, Question } from "./newQuestionsType";


interface TableRow {
  [key: string]: string;
}

const CreateScriptManually = () => {

  //const location = useLocation();
  //const searchParams = new URLSearchParams(location.search);
  const { id } = useParams();
  
  const [rows, setRows] = useState<TableRow[]>([{}]);
  const [userData, setUserData] = useState<RobodialerUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<object | null>(null);
  const [scriptName, setScriptName] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<BuilderState>(BuilderState.Questions);
  const [questions, setQuestions] = useState<Array<Question>>([{
    question: '',
    answerType: ANSWER_TYPES.TEXT,
  } as Question]);
  const [selectedQuestion, setSelectedQuestions] = useState<Array<Question>>([]);
  const ref = useRef<HTMLFormElement>(null);


  const handleClick = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(ref?.current);
    setIsLoading(true)
    let ret = await ref?.current?.onSubmit();
    setIsLoading(false)
    //setCurrentTab(BuilderState.Communication);
    if(ret === 200) window.location.assign('/super-dial-dashboard/home')
  };
  const handleTabClick = (tab: BuilderState) => {
    setCurrentTab(tab);
  };

  const renderNav = () => {
    return (
      <nav>
          <ul className="flex items-center">
            <li className="mx-5">
              <span
                className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Setup ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                  }`}
                onClick={() => handleTabClick(BuilderState.Setup)}
              >
                Setup
              </span>
            </li>
            <li className="mx-5">
              <span
                className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Questions ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                  }`}
                onClick={() => handleTabClick(BuilderState.Questions)}
              >
                Questions
              </span>
            </li>
            <li className="mx-5">
              <span
                className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Communication ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                  }`}
                onClick={() => handleTabClick(BuilderState.Communication)}
              >
                Communication
              </span>
            </li>
            <li className="mx-5">
              <span
                className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Result ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                  }`}
                onClick={() => handleTabClick(BuilderState.Result)}
              >
                Result
              </span>
            </li>
          </ul>
        </nav>
    )
  }

  
  useEffect(() => {
  
    const fetchScripts = async () => {
      console.log('fetch user');
      setLoading(true)
      const userResponse = await getRobodialerUser();
      setUserData(userResponse.data)
      
      console.log('fetchScript');
      const { data, httpCode} = await getScript(id);
      if(httpCode !== 200){
        console.log(httpCode);
        return;
      }
      const response = await getScriptInputs(data.type)
      console.log(response.data);
      setFields(response.data);
      setLoading(false)
      
      
      setScriptName(data?.name)
      if (data?.questions && 0 < data?.questions?.length) {
        setQuestions(data?.questions! as Array<Question>);
      }
    }
    fetchScripts()
  }, []);


  const renderTabs = () => {
    return (
      <header className="flex items-center justify-between py-4 px-8 bg-white">
        <div className="flex items-center">
          <div onClick={() => {
            window.location.assign('/super-dial-dashboard/home')
          }} className="text-superbill-indigo text-xl font-jakarta cursor-pointer flex items-end">
            <SuperDialIcon className="w-[30px] mr-2"/>
            {/* SUPER<strong>DIAL</strong> */}
          </div>
          <h1 className="ml-4 text-md font-semubold font-jakarta font-bold text-jacarta">{scriptName}</h1>
        </div>
        {/* {renderNav()} */}
        <div>
          <button
            className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4'
            onClick={() => {
              window.location.assign('/super-dial-dashboard/home')
            }}
          >
            Close
          </button>
          <button
            className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
            onClick={handleClick}
            disabled={isLoading}
            >
            {isLoading ? <Spinner /> : <>Place Calls</>}
          </button>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    switch (currentTab) {
      case BuilderState.Questions:
        return <DynamicTable fields={fields} setFields={setFields}  id={id!} ref={ref} MAX_ROWS={userData?.unlimitedCalls ? 500 : userData?.callsRemaining}/>;
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <div>
          {renderTabs()}
        <div className='w-full h-full flex justify-center items-center'>
          {' '}
          <Loader />{' '}
        </div>
        </div>
      ) : null}
      {!loading ? (
        <div>
        {renderTabs()}
        <div className="tab-content">{renderContent()}</div>
      </div>
      ) : null}
    </>
  );
};

export default CreateScriptManually;