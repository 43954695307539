import React, { ChangeEvent } from 'react';
import { Tooltip } from '@material-tailwind/react';
import { RobodialerComponentsResponseType } from 'types/responses';
import { AuditIssuesType } from 'types/audit';
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg';

type AndyIssuesFormProps = {
  components: RobodialerComponentsResponseType;
  issueId: string;
  values: AuditIssuesType;
  handleChange: (issueId: string, field: keyof AuditIssuesType, value: any) => void;
  onRemove: (issueId) => void;
};

const AndyIssuesForm: React.FC<AndyIssuesFormProps> = ({
  components,
  issueId,
  values,
  onRemove,
  handleChange
}) => {
  const selectedComponentId = values.componentId;

  const renderComponentOptions = () => (
    Object.keys(components).map((componentKey, index) => (
      <label key={`${issueId}.component-${componentKey}-${index}`}
      className={`px-4 py-2 border rounded cursor-pointer transition-colors w-40
                      ${values.componentId === componentKey
                        ? 'bg-superbill-wild-blue-yonder text-superbill-jacarta border-superpay-soap' 
                        : 'bg-white text-blue-500 border-blue-500 hover:bg-blue-100'}`}
                        >
        <input
          className='hidden'
          type="radio"
          name={`${issueId}-componentId`}
          value={values.componentId}
          checked={values.componentId === componentKey}
          onChange={(e) => handleChange(issueId, 'componentId', componentKey)}
        />
        
          <Tooltip
            content={
              <span style={{ whiteSpace: 'pre-line' }}>
                {componentKey}
              </span>
            } className="max-w-[200px] bg-black py-1 px-2">
            <span className='block truncate'>{componentKey}</span>
          </Tooltip>
      </label>
    ))
  );

  const renderIssueCategoryOptions = () => (
    selectedComponentId && components[selectedComponentId] &&
    Object.keys(components[selectedComponentId].issueCategories).map((issueCategory, index) => {
      const category = components[selectedComponentId].issueCategories[issueCategory];

      return (
        <label key={`${issueId}.category-${selectedComponentId}-${category.issueCategoryId}-${index}`}
        className={`px-4 py-2 border rounded cursor-pointer transition-colors w-56
                      ${values.issueCategoryId === category.issueCategoryId
                        ? 'bg-superbill-wild-blue-yonder text-superbill-jacarta border-superpay-soap' 
                        : 'bg-white text-blue-500 border-blue-500 hover:bg-blue-100'}`}
                        >
          <input
            className='hidden'
            type="radio"
            name={`${issueId}-issueCategoryId`}
            value={values.issueCategoryId}
            checked={values.issueCategoryId === category.issueCategoryId}
            onChange={(e) => handleChange(issueId, 'issueCategoryId', category.issueCategoryId)}
          />
          
            <Tooltip
            content={
              <span style={{ whiteSpace: 'pre-line' }}>
                {category.issueCategoryName}
              </span>
            } className="max-w-[200px] bg-black py-1 px-2">
            <span className='block truncate'>{category.issueCategoryName}</span>
          </Tooltip>
        </label>
      );
    })
  );

  return (
    <div>
      <div className='bg-superbill-wild-blue-yonder rounded-t w-fit px-3 text-superbill-jacarta text-sm'>Issue {issueId}</div>
      <div className='p-4 border rounder border-superpay-soap bg-white'>
        <div className='flex justify-between items-center'>
          <div><strong className='text-superbill-jacarta text-base'>Select a Main issue</strong></div>
          <div><DeleteIcon onClick={() => {onRemove(issueId)}} /></div>
        </div>
        <div className='bg-superbill-anti-flash-white rounded p-3'>
          <div className="p-2 flex items-center flex-wrap gap-2">
            {renderComponentOptions()}
          </div>
          <div className='bg-superbill-anti-flash-white-darken rounded p-3'>
            <div className='mt-2 mb-1'><strong className='text-superbill-jacarta text-base'>Select a sub-issue</strong></div>
            <div className="p-2 flex items-center gap-x-2 flex-wrap gap-2">
              {renderIssueCategoryOptions()}
            </div>
            <div className='mt-2 mb-1'><strong className='text-superbill-jacarta text-base'>What should've happened instead?</strong></div>
              <textarea
                name={`${issueId}-issueAndCorrectBehaviorDescription`}
                placeholder="Enter Description"
                value={values.issueAndCorrectBehaviorDescription}
                className='w-full p-2 border rounded border-superpay-soap min-h-36'
                onChange={(e) => handleChange(issueId, 'issueAndCorrectBehaviorDescription', e.target.value)}
                />
            </div>
          </div>
      </div>
    </div>
  );
};

export default AndyIssuesForm;