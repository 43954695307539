import { useEffect, useState } from "react";
import Loader from "components/Loader";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import { runScript } from "apis/robodialer";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";

type HeadersPageProps = {
  onClose: () => void;
  onSuccess: () => void;
  onError: (errors) => void;
  selectedScript: string | null;
  csvString: string;
  inputs: any | null;
  csvFile: any;
  setCsvFile: React.Dispatch<React.SetStateAction<any>>;
};

const HeadersPage = ({
  onClose,
  onSuccess,
  csvString,
  inputs,
  csvFile,
  setCsvFile,
  onError,
  selectedScript,
}: HeadersPageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {

    handleSubmit,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
  });

  const OPTIONS = csvString
    .split("\n")[0]
    ?.split(",")
    .map((extraHeader) => ({ label: extraHeader, value: extraHeader }));

  const [missingHeaders, setMissingHeaders] = useState<string[]>([]);
  const [upErrorMsg, setUpErrorMsg] = useState<string | null>(null);
  
  const [selectedExtraHeaders, setSelectedExtraHeaders] = useState<
    Record<string, string>
  >({});
  const [duplicatedValues, setDuplicatedValues] = useState<boolean>(false);

  useEffect(() => {
    const lines = csvString.split("\n");
    const csvHeaders = lines[0].split(",");
    const requiredHeaders = Object.keys(inputs).filter(
      (header) => inputs[header].required === true
    );
    setMissingHeaders(
      requiredHeaders.filter((header) => !csvHeaders.includes(header))
    );
    setSelectedExtraHeaders(
      Object.fromEntries(requiredHeaders.map((header) => [header, ""]))
    );
  }, [csvString, inputs]);

  useEffect(() => {
    checkDuplicates(selectedExtraHeaders);
  }, [selectedExtraHeaders]);

  const handleHeaderSelection = (header: string, selectedHeader) => {
    setValue(header, selectedHeader);
    setSelectedExtraHeaders((prevState) => ({
      ...prevState,
      [header]: selectedHeader?.value,
    }));
  };
  function checkDuplicates(map: Record<string, string>) {
    const listOfValues = Object.values(map).filter(
      (value) => value !== null && value !== ""
    );
    const valueCounts = {};
    listOfValues.forEach((value) => {
      valueCounts[value] = (valueCounts[value] || 0) + 1;
    });
    for (const value in valueCounts) {
      if (valueCounts[value] > 1) {
        setDuplicatedValues(true);
        return;
      } else setDuplicatedValues(false);
    }
  }
  const replaceHeadersInCSV = (headersToReplace) => {
    let updatedCSVString = csvString;

    for (const header in headersToReplace) {
      const value = headersToReplace[header].value;
      const regex = new RegExp(`\\b${value}\\b`, "g");
      updatedCSVString = updatedCSVString.replace(regex, header);
    }

    return updatedCSVString;
  };

  const handleCSVUpdate = (data) => {
    const updatedCSVString = replaceHeadersInCSV(data);
    const updatedCsvBlob = new Blob([updatedCSVString], { type: "text/csv" });
    const updatedCsvFile = new File([updatedCsvBlob], csvFile.name, {
      type: "text/csv",
    });
    setCsvFile(updatedCsvFile);
    submitCsv(updatedCsvFile, csvFile.name);
  };

  const submitCsv = async (updatedCsvFile, file_name) => {
    setIsLoading(true);
    let { httpCode, data } = await runScript(
      updatedCsvFile,
      selectedScript,
      file_name
    );
    setIsLoading(false);
    if (httpCode === 400) {
      // errors page
      onError(data.errors);
    } else if (httpCode !== 200) {
      setUpErrorMsg('Invalid CSV, unable to upload');
    } else {
      onSuccess();
    }
  };

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9 flex justify-center font-sans">
        <div className="w-full">
          <div className="text-sm text-superbill-jacarta ">
            Match your CSV column headers to our required headers so that we can
            properly place your calls. If you are missing any columns, please
            add those columns and information and re-upload your file. A column
            header in your CSV can only be used <strong>once.</strong>
          </div>
          {duplicatedValues ? (
            <>
              <div className="flex justify-start font-sans items-center w-full h-fit bg-superbill-banner-red p-2 rounded mt-1 mb-2">
                <div className="flex justify-start">
                  <InfoIcon className="child:fill-superbill-ultra-red w-[18px]" />
                </div>
                <div className="ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] ">
                  A column header in your CSV can only be mapped{" "}
                  <strong>once.</strong> Please remove the duplicated option
                  before continuing.
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2 mt-3">
            <div className="text-sm font-bold text-superbill-jacarta">
              Required Column Header
            </div>
            <div className="text-sm font-bold text-superbill-jacarta">
              Your CSV Column Header
            </div>
          </div>

            {missingHeaders.map((header, index) => (
              <div key={`header-${header}-${index}`} className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2 mt-3 p-4 border border-superbill-soap rounded content-center">
                <div className="text-sm text-superbill-jacarta mt-2">
                  {header}
                </div>
                <Controller
                  name={`${header}`}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      placeholder={"Select a column header"}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          "&:hover": { borderColor: "#D0D3F2" },
                          boxShadow: state.isFocused
                            ? "0px 0px 0px 3px #E4E8FD"
                            : "none",
                          borderColor: state.isFocused ? "#4a43cd" : "#D0D3F2",
                          zIndex: 11,
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? "#f5f6fb"
                            : "#ffffff",
                          color: state.isFocused ? "#4a43cd" : "#38395B",
                          ":active": {
                            ...baseStyles[":active"],
                            backgroundColor: "#D0D3F2",
                          },
                        }),
                      }}
                      isSearchable={false}
                      className="bg-white border-1  block w-full  focus:ring-superbill-indigo sm:text-sm rounded placeholder:text-sm text-superbill-jacarta"
                      {...field}
                      options={OPTIONS}
                      onChange={(selectedOption) =>
                        handleHeaderSelection(header, selectedOption)
                      }
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            ))}
        </div>
      </div>
      {/* Toolbar */}
      {upErrorMsg ? (
        <div className="flex justify-between items-center w-full h-fit rounded bg-superbill-banner-red p-2 my-3 ">
          <div className="flex justify-start items-center">
            <div className="flex justify-start pl-2 items-center">
              <InfoIcon className="child:fill-superbill-ultra-red-hover min-w-[22px]" />
            </div>
            <div className="ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] ">
              {upErrorMsg ?? "Invalid CSV, unable tu upload"}
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
          onClick={onClose}
        >
          Close
        </button>
        <button
          disabled={isLoading || !selectedScript || duplicatedValues}
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
          onClick={handleSubmit(handleCSVUpdate)}
        >
          {isLoading ? <Loader size={42} /> : <>Place Calls</>}
        </button>
      </div>
    </div>
  );
};

export default HeadersPage;
