import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";


const ForgotPasswordForm = ({ agent = false }: { agent?: boolean })=>{
  const [loading , setLoading] = useState<boolean>(false);
  const [forgotPassword , setForgotPassword] = useState<boolean | null>(null);
  
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({mode:'onChange'});



  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    try {
      setLoading(true);
      setForgotPassword(null); 
      const auth = getAuth();
      auth.tenantId = agent ? "AgentsTenant-wf9gw" : "RobodialersTenant-hvzmy"
      await sendPasswordResetEmail(auth, data.email);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setForgotPassword(false);
      return;
    }
    setForgotPassword(true);

  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-sm mx-auto">
      <div className="my-4 font-jakarta">
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
          Email
        </label>
        <input
          id="email"
          type="email"
          className={`w-full mb-2 rounded font-sans ${errors.email ? "border-red-500" : "border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50"}`}
          {...register('email', {
            required: 'This field is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
        />
        <div className="mb-1 text-red-500 text-xs">
          {errors && errors?.email?.message && typeof errors?.email?.message === 'string' ? <span>{errors?.email?.message}</span> : <span></span> } 
        </div>
      </div>
      
      {forgotPassword !== null && !forgotPassword ? <div className='text-superbill-ultra-red font-jakarta bg-superbill-banner-red p-4 rounded'>Hmmm, we don't recognize that email. Reach out to <strong>support@thesuperbill.com</strong> if you think we're wrong.</div> : <div className='my-3.5'></div>}
      {forgotPassword !== null && forgotPassword ? <div className='text-superbill-jacarta font-jakarta bg-superbill-lavender-medium p-4 rounded'>Great! Click the link in your email to reset your password, then <a href={'/super-dial-sign-in'} className="clickable hover-underline-animation text-bold"> <strong>go back to sign in.</strong></a></div> : <div className='my-3.5'></div>}
      
      {forgotPassword == null ? 
      <div className="flex flex-col text-right justify-center items-center">
      <button disabled={!isValid || loading} className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 w-full" type='submit'>{loading && (
        <svg role="status" className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
      )}
        {loading && <span></span>}
        {!loading && <span>Continue</span>}</button>
    </div>:
    <></>}
     
    </form>
  );
};

export default ForgotPasswordForm;
