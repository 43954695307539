import React, { useState } from 'react';

const ExpandableObject = ({ data, level = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderContent = (obj, level) => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const isExpandable = typeof value === 'object' && value !== null;
      return (
        <div key={key} className={`pl-${level+1 * 4}`}>
          <div className="flex items-start">
            {isExpandable ? (
              <button
                className="mr-2 text-blue-500"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? '-' : '+'}
              </button>
            ) : null}
            {key}:{isExpandable ? '' : <strong>{JSON.stringify(value)}</strong>}
            {isExpanded && isExpandable ? (
              <div className="pl-4">
                <ExpandableObject data={value} level={level + 1} />
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  };

  return (
    <div className='flex justify-start items-start flex-col' >
      {typeof data === 'object' && data !== null
        ? renderContent(data, level)
        : <strong className='truncate'>{JSON.stringify(data)}</strong>}
    </div>
  );
};

export default ExpandableObject;
