import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { getBatches, getCallsOverview } from 'apis/robodialer';

import { RobodialerUser } from 'types/types';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import ResultsTable from './scripts-results-table';
import ScriptsDetailedResultsTable from 'script-builder/scripts-detailed-results-table';
import CallsOVerviewOptions from './calls-overview-options';
import { Tooltip } from '@material-tailwind/react';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as TotalIcon } from 'assets/icon_phone.svg';
import { ReactComponent as SuccessIcon } from 'assets/icon_check-circle.svg';
import { ReactComponent as FailIcon } from 'assets/icon_xmark-circle.svg';



type PathType = {
  name: string;
  href: string;
  current: boolean;
}

type PageProps = {
  userData: RobodialerUser | null,
}
export default function ScriptsPage() {
  const [selectedOption, setSelectedOption] = useState<string>('All time');

  const [props] = useOutletContext<Array<PageProps>>();
  const { userData } = props
  console.log('userData', userData);
  
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingOverview, setLoadingOverview] = useState<boolean>(true);
  const [batches, setBatches] = useState<any>(null);
  const [overview, setOverview] = useState<any>(null);
  const [selectedBatch, setSelectedBatch] = useState<string | null>(null);


  const updateAndSortBatches = (data) => {
    const statusOrderMapping: { [key: string]: number } = {
      processing: 1,
      completed: 2,
      failed: 3
    };

    const sortedKeys = Object.keys(data).sort((key1, key2) => {
      const status1 = data[key1].status;
      const status2 = data[key2].status;

      const order1 = statusOrderMapping[status1] || 4;
      const order2 = statusOrderMapping[status2] || 4;

      if (order1 === order2) {
        const createdAt1 = new Date(data[key1].createdAt ?? data[key1].dateCreated);
        const createdAt2 = new Date(data[key2].createdAt ?? data[key2].dateCreated);
        return createdAt2.getTime() - createdAt1.getTime();
      }

      return order1 - order2;
    });
    const sortedBatches = {};
    sortedKeys.forEach(key => {
      sortedBatches[key] = data[key];
    });

    setBatches(sortedBatches);
  };

  const fetchBatches = async () => {
    setLoading(true)
    const response = await getBatches();
    updateAndSortBatches(response.data)
    setLoading(false)
  }
  useEffect(() => {
    fetchBatches()
    const fetchOverview = async () => {
      setLoadingOverview(true)
      const response = await getCallsOverview(null);
      //console.log(response.data);
      setOverview(response.data)
      setLoadingOverview(false)
    }
    fetchOverview()
  }, []);

  useEffect(() => {
    const fetchOverview = async () => {
      setLoadingOverview(true)
      let filter = selectedOption
      const custom = selectedOption.split('|')
      if (1 < custom.length) {
        filter = `custom&start=${custom[0].trim()}&end=${custom[1].trim()}`
      }
      const response = await getCallsOverview(filter);
      //console.log(response.data);
      setOverview(response.data)
      setLoadingOverview(false)
    }
    if (selectedOption) {
      fetchOverview()
    }
  }, [selectedOption])


  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? 
        (!selectedBatch ?
        (
          <div className='pb-4 bg-superbill-ghost-white'>
            {0 < Object.keys(batches).length ?
              <>
                <div className='flex justify-between items-center'>
                  <div className='text-superbill-jacarta font-bold text-base font-jakarta'>Summary</div>
                  <div>{selectedBatch}</div>
                  <div className='text-superbill-slate-grey text-sm font-sans'><CallsOVerviewOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} /></div>
                </div>
                  {loadingOverview ? (
                    <>
                      <div className='w-full h-full flex justify-center items-center'>
                        {' '}
                        <Loader size={30} />{' '}
                      </div>
                    </>
                  ) : 
                  <div className='flex my-4'>
                      <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 mr-2'>
                          <div className='flex items-center'>
                              <TotalIcon className='w-[12px]'/>
                              <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Calls Completed</div>
                          </div>
                          <div className='text-xl text-superbill-jacarta'>{overview?.successful+overview?.failed ?? 0}</div>
                      </div>
                      <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3'>
                          <div className='flex items-center'>
                              <SuccessIcon className='w-[12px]'/>
                              <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Successful</div>
                          </div>
                          <div className='text-xl text-superbill-jacarta'>{overview?.successful ?? 0}</div>
                      </div>
                      <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 ml-2'>
                          <div className='flex items-center'>
                              <FailIcon className='w-[12px]'/>
                              <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Insufficient / Incorrect Data</div>
                          </div>
                          <div className='text-xl text-superbill-jacarta'>{overview?.failed ?? 0}</div>
                      </div>

                  </div>
                  }
              </>
              : null}
            <ResultsTable refresh={() => {
              fetchBatches()
            }} allowPartialDownload={userData?.allowPartialDownload ?? false} canDownload={userData?.allowDownloadBatch ?? false} selectedTurnAroundTime={userData?.selectedTurnAroundTime!} batches={batches} selectedBatch={selectedBatch} setSelectedBatch={setSelectedBatch}/>
          </div>
        ) 
        :
        <ScriptsDetailedResultsTable showTranscript={userData?.featureFlags?.displayTranscripts ?? false} selectedBatch={selectedBatch} setSelectedBatch={setSelectedBatch} batches={batches}/>
        ):
        null
    }
    </>
  );
}
