// validators.ts

import { RobodialerComponentsResponseType } from "types/responses";

/**
 * Validates the API response data structure.
 * 
 * @param data - The data to validate.
 * @returns A validated response with corrected data types.
 */
export function validateRobodialerComponentsResponse(data: any): RobodialerComponentsResponseType {
  const validatedResponse: RobodialerComponentsResponseType = {};

  for (const key in data) {
    const component = data[key];
    
    // Ensure the component has a valid structure
    validatedResponse[key] = {
      componentId: typeof component.componentId === 'string' ? component.componentId : '',
      componentName: typeof component.componentName === 'string' ? component.componentName : '',
      issueCategories: {},
    };

    for (const issueKey in component.issueCategories) {
      const issueCategory = component.issueCategories[issueKey];
      validatedResponse[key].issueCategories[issueKey] = {
        componentId: typeof issueCategory.componentId === 'string' ? issueCategory.componentId : '',
        issueCategoryDescription: typeof issueCategory.issueCategoryDescription === 'string' ? issueCategory.issueCategoryDescription : '',
        issueCategoryId: typeof issueCategory.issueCategoryId === 'string' ? issueCategory.issueCategoryId : '',
        issueCategoryName: typeof issueCategory.issueCategoryName === 'string' ? issueCategory.issueCategoryName : '',
      };
    }
  }

  return validatedResponse;
}